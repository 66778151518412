import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Notification } from 'react-ui-kit-exante';

import { useLogHandleTime } from '~/hooks';
import { sessionService } from '~/resources';
import { UnauthorizedError } from '~/shared/utils/errors/UnauthorizedError';

import { CenteredLoader } from './CenteredLoader';

interface ISessionRegistrationContainerProps {
  children?: ReactNode;
}

export const SessionRegistrationContainer = ({
  children,
}: ISessionRegistrationContainerProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('register-session');

  const handleOnSuccess = useCallback(() => {
    logHandleTime();
    setIsLoading(false);
  }, [logHandleTime]);

  const handleOnFail = useCallback((error: unknown) => {
    if (error instanceof UnauthorizedError) {
      Notification.error({
        title: error.message,
      });
    } else {
      Notification.error({
        title: `Unknown error occurred while register session${
          `: ${error}` || ''
        } `,
      });
    }
    setIsLoading(false);
  }, []);

  const registerSession = useCallback(() => {
    setStartHandleTime();

    sessionService.registerSession({
      onSuccess: handleOnSuccess,
      onError: handleOnFail,
    });
  }, [handleOnSuccess, handleOnFail, setStartHandleTime]);

  useEffect(() => {
    registerSession();
  }, [registerSession]);

  useEffect(() => {
    window.addEventListener('onUpdateAccessToken', registerSession);

    return () => {
      window.removeEventListener('onUpdateAccessToken', registerSession);
    };
  }, []);

  if (isLoading) {
    return <CenteredLoader />;
  }

  // We should return right type from component
  // And we don't need div wrapper, so use just fragment
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
