import { EMPTY_ARRAY } from '~/constants';
import { check403Error, sendUiKitErrorNotification } from '~/shared/utils';
import { IRequestArguments } from '~/types/api';

import { ExecutionSchemesRepository } from './executionSchemes.repository';
import { IExecutionSchema } from './types';

export class ExecutionSchemesService {
  public async resolveExecutionSchemes(
    args?: IRequestArguments,
  ): Promise<IExecutionSchema[]> {
    try {
      const { data } = await ExecutionSchemesRepository.fetchExecutionSchemes();

      return data;
    } catch (error) {
      const is403Error = check403Error(error);

      if (!is403Error) {
        sendUiKitErrorNotification(error);
      }

      if (args?.onError) {
        args.onError(error as Error);
      }

      return EMPTY_ARRAY;
    }
  }
}
