import { styled, keyframes } from 'react-ui-kit-exante';

import { FormToggleButtonGroupContainer } from '~/containers/form';
import { DeleteButton, FormItem, RestoreButton } from '~/shared/components';
import { Disabled } from '~/styles';

const createFlashingAnimation = (color: string) => {
  const opacity50 = '80';

  return keyframes`
  0% { background-color: initial; }
  50% { background-color: ${color + opacity50}; }
  100% { background-color: initial; }
`;
};

export const StyledFormItem = styled('li')(({ theme }) => {
  const flashing = createFlashingAnimation(theme.palette.primary.main);

  return {
    padding: '24px',
    borderTop: `1px solid ${theme.color.line.primary}`,
    position: 'relative',
    listStyle: 'none',

    '&[data-is-new="true"]': {
      order: -1,
    },
    '&[data-flashing="true"]': {
      animation: `${flashing} 2s`,
    },
  };
});

export const StyledFormItemFields = styled('div')(() => ({
  gap: 24,
  display: 'flex',
  flexDirection: 'column',

  '&[data-is-deleted="true"]': Disabled(),
}));

export const StyledFormRow = styled(FormItem)(() => ({
  '> *': {
    maxWidth: 268,
  },
}));

export const StyledDeleteButton = styled(DeleteButton)(({ theme }) => ({
  position: 'absolute',
  right: 24,
  top: 36,
  color: theme.color.icon.secondary,
  width: 24,
  height: 24,
}));

export const StyledRestoreButton = styled(RestoreButton)(() => ({
  position: 'absolute',
  right: 24,
  top: 36,
}));

export const StyledToggleButton = styled(FormToggleButtonGroupContainer)(
  () => ({
    display: 'block',

    '& button': {
      width: 'auto',
    },
  }),
);
