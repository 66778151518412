import { ChangeEvent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, IColumn, IRowExpand } from 'react-ui-kit-exante';

import {
  updateNodeValue,
  updateInstrumentValue,
  selectFiltersLayer,
} from '~/store/limits';
import { RowType } from '~/types/common';
import { FilterLayers, ILimitTree } from '~/types/limits';

interface OverrideCellProps {
  column: IColumn<ILimitTree>;
  value: boolean | undefined;
  row: IRowExpand<ILimitTree>;
}

export const OverrideCell = ({ column, value, row }: OverrideCellProps) => {
  const dispatch = useDispatch();
  const filtersLayer = useSelector(selectFiltersLayer);

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const actionFn =
        row.original.rowType === RowType.Node
          ? updateNodeValue
          : updateInstrumentValue;

      dispatch(
        actionFn({
          path: row.original.path,
          value: e.target.checked,
          column: column.id,
        }),
      );
    },
    [column, dispatch, row],
  );

  return (
    <Checkbox
      checked={value}
      onChange={onChangeHandler}
      disabled={row.depth === 0 || FilterLayers.Default === filtersLayer}
    />
  );
};
