import { isEqual } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';

import { IconButton } from '../Button/IconButton';
import { Input } from '../Inputs/Input/Input';
import { WithTooltip } from '../Table/TableBody/WithTooltip';

import { BoxStyled } from './Bookmarks.styled';
import {
  BOOKMARK_INPUT_DATA_TEST_ID,
  DELETE_BOOKMARK_BUTTON_DATA_TEST_ID,
  SAVE_AS_NEW_BOOKMARK_BUTTON_DATA_TEST_ID,
  SAVE_BOOKMARK_BUTTON_DATA_TEST_ID,
  SHARE_BOOKMARK_BUTTON_DATA_TEST_ID,
} from './constants';
import { usePrevious } from './helpers';
import { IBookmarksProps, TBookmarks } from './types';

export const Bookmarks = ({
  initialValues,
  onSave,
  onSaveAsNew,
  onShare,
  onDelete,
}: IBookmarksProps) => {
  const [values, setValues] = useState<TBookmarks>(initialValues);
  const [hasChanges, setHasChanges] = useState(false);
  const prevValues = usePrevious(values);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      name: target.value,
    });
  };

  const handleSave = () => {
    setHasChanges(false);
    onSave(values.name);
  };

  const handleSaveAsNew = () => {
    setHasChanges(false);
    onSaveAsNew(values.name);
  };

  useEffect(() => {
    if (isEqual(initialValues, values)) {
      setHasChanges(false);
    } else if (prevValues && !isEqual(prevValues, values)) {
      setHasChanges(true);
    }
  }, [prevValues, values]);

  useEffect(() => {
    if (initialValues.id) {
      setValues(initialValues);
    }
  }, [initialValues]);

  return (
    <Input
      fullWidth
      onChange={handleChange}
      size="small"
      value={values.name}
      inputProps={{
        'data-test-id': BOOKMARK_INPUT_DATA_TEST_ID,
      }}
      iconRight={
        <BoxStyled>
          <WithTooltip value="Save bookmark" tooltip="default">
            <IconButton
              applyStates={false}
              data-test-id={SAVE_BOOKMARK_BUTTON_DATA_TEST_ID}
              data-active={!!hasChanges}
              iconName="SaveIcon"
              iconColor={hasChanges ? 'action' : 'secondary'}
              iconSize={24}
              onClick={handleSave}
            />
          </WithTooltip>
          <WithTooltip value="Save as new bookmark" tooltip="default">
            <IconButton
              applyStates={false}
              data-test-id={SAVE_AS_NEW_BOOKMARK_BUTTON_DATA_TEST_ID}
              iconName="AddIcon"
              iconColor={hasChanges ? 'action' : 'secondary'}
              iconSize={18}
              onClick={handleSaveAsNew}
            />
          </WithTooltip>
          <WithTooltip value="Share bookmark" tooltip="default">
            <IconButton
              applyStates={false}
              data-test-id={SHARE_BOOKMARK_BUTTON_DATA_TEST_ID}
              iconName="ExportIcon"
              iconColor="secondary"
              iconSize={18}
              onClick={onShare}
            />
          </WithTooltip>
          <WithTooltip value="Delete bookmark" tooltip="default">
            <IconButton
              applyStates={false}
              data-test-id={DELETE_BOOKMARK_BUTTON_DATA_TEST_ID}
              iconName="DeleteIcon"
              iconColor="radical"
              iconSize={18}
              onClick={onDelete}
            />
          </WithTooltip>
        </BoxStyled>
      }
    />
  );
};
