import { check403Error, sendUiKitErrorNotification } from '~/shared/utils';
import { TParams } from '~/types/api';
import { ICard, TEditableCard } from '~/types/cardsManagement';

import { cardsMapper } from './cardsManagement.mappers';
import { CardsManagementRepository } from './cardsManagement.repository';

export class CardsManagementService {
  public async fetchCardsByAccountId(
    id: string,
    params?: TParams,
  ): Promise<ICard[]> {
    try {
      const { data } = await CardsManagementRepository.fetchCards(id, params);

      return cardsMapper(data);
    } catch (error) {
      const is403Error = check403Error(error);

      if (!is403Error) {
        sendUiKitErrorNotification(error);
      }

      return [];
    }
  }

  public async createCard(
    accountId: string,
    body: TEditableCard,
  ): Promise<ICard | null> {
    try {
      const { data } = await CardsManagementRepository.createCard(
        accountId,
        body,
      );

      return data;
    } catch (error) {
      sendUiKitErrorNotification(error);

      return null;
    }
  }

  public async editCard(
    accountId: string,
    cardId: number,
    body: TEditableCard,
  ): Promise<ICard | null> {
    try {
      const { data } = await CardsManagementRepository.editCard(
        accountId,
        cardId,
        body,
      );

      return data;
    } catch (error) {
      sendUiKitErrorNotification(error);

      return null;
    }
  }

  public async deleteCard(cardId: number): Promise<null> {
    try {
      const { data } = await CardsManagementRepository.deleteCard(cardId);

      return data;
    } catch (error) {
      sendUiKitErrorNotification(error);

      return null;
    }
  }

  public async createCards(accountId: string, body: TEditableCard[]) {
    return Promise.all(body.map((item) => this.createCard(accountId, item)));
  }

  public async deleteCards(cardIds: number[]) {
    return Promise.all(cardIds.map((id) => this.deleteCard(id)));
  }

  public async editCards(accountId: string, cards: ICard[]) {
    return Promise.all(
      cards.map((card) => this.editCard(accountId, card.id, card)),
    );
  }

  public async cardsCUD(
    accountId: string,
    createdCards: ICard[],
    editedCards: ICard[],
    deletedCards: ICard[],
  ) {
    return Promise.all([
      this.createCards(accountId, createdCards),
      this.editCards(accountId, editedCards),
      this.deleteCards(deletedCards.map((card) => card.id)),
    ]);
  }
}
