import { useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-ui-kit-exante';

import { useGetAccountTypesQuery } from '~/api/types/types.api';
import { useLogHandleTime } from '~/hooks';
import { transformVariantsToSelectOptions } from '~/shared/utils';
import { selectCurrenciesOptionsByWeight } from '~/store/currencies';

import { LegalEntity } from './components';
import { useDelete, useLegalEntityForm } from './hooks';

export const LegalEntityPage = () => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'legal-entity-entry-screen',
  );
  setStartHandleTime();

  const { name } = useParams();
  const { data: accountTypes } = useGetAccountTypesQuery();

  const accountTypesOptions = transformVariantsToSelectOptions(
    accountTypes?.values,
  );
  const currenciesOptions = useSelector(selectCurrenciesOptionsByWeight);

  const options = useMemo(
    () => ({
      accountTypes: accountTypesOptions,
      currencies: currenciesOptions,
    }),
    [accountTypesOptions, currenciesOptions],
  );

  const { modal, closeHandle, deleteHandle } = useDelete(name);

  const {
    formInstance,
    submitHandle,
    isLoading,
    isDirty,
    isSubmitting,
    fetchData,
  } = useLegalEntityForm(name, setStartHandleTime);

  useEffect(() => {
    if (!isLoading && name) {
      logHandleTime();
    }
  }, [isLoading, name, logHandleTime]);

  if (!accountTypesOptions?.length || !currenciesOptions?.length) {
    return null;
  }

  return (
    <FormProvider {...formInstance}>
      <LegalEntity
        disabledName={Boolean(name)}
        disabledSubmitButton={isSubmitting || !isDirty}
        displayLoader={isLoading && !!name}
        onCloseHandler={closeHandle}
        onDeleteHandler={name ? modal.onOpen : undefined}
        onSubmitHandler={submitHandle}
        options={options}
        title={name ? `Edit legal entity ${name}` : 'Add legal entity'}
        onRefresh={fetchData}
        isRefreshLoading={isLoading}
      />

      <Modal
        isOpened={modal.isOpened}
        onClose={modal.onClose}
        title="Are you sure?"
        confirmButton={{
          handleConfirm: deleteHandle,
        }}
      >
        <div>Do you want to delete this legal entity?</div>
      </Modal>
    </FormProvider>
  );
};
