import { isEqual, isFunction } from 'lodash';
import { FC, ReactNode, useEffect, useMemo } from 'react';
import {
  Bookmarks,
  ExportTableParams,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { useLazyGetAuditLogsQuery } from '~/api';
import { EMPTY_ARRAY, EMPTY_AUDIT_LOGS, NO_DATA_HEIGHT } from '~/constants';
import { usePrevious, useLogHandleTime } from '~/hooks';
import { getDefaultBookmarkResponse } from '~/hooks/useBookmark/helpers';
import { IBookmarkResponseProps } from '~/hooks/useBookmark/types';
import { WithBookmarks } from '~/shared/components/WithBookmarks';
import { calculateCountOfPages } from '~/shared/utils';
import { getIsFieldExistsAndEmpty } from '~/shared/utils/table';
import {
  IAuditLog,
  IFetchParams,
  IFetchAuditLogResponse,
} from '~/types/auditLogs';
import { TActivityEntities } from '~/types/common';
import { IRefreshActiveTabQuery } from '~/types/refetch';

import { TGlobalFilters } from '../TransactionsContainer/types';

import { TABLE_ID } from './constants';
import { getDefaultFilters, prepareParams } from './helpers';
import { DEFAULT_SORT_BY } from './sorting';
import { DISPLAYED_COLUMN_KEYS, useColumns } from './useColumns';

interface IAuditLogContainerProps {
  entity?: TActivityEntities;
  globalFilters?: TGlobalFilters;
  tableId?: string;
  updateRefetch?: (state: IRefreshActiveTabQuery) => void;
  selectTable?: ReactNode;
}

export const AuditLog: FC<IAuditLogContainerProps & IBookmarkResponseProps> = ({
  entity = 'audit-log-list',
  globalFilters,
  updateRefetch,
  tableId = TABLE_ID,
  selectedBookmark,
  handleSaveBookmark,
  handleSaveAsNewBookmark,
  handleShareBookmark,
  handleDeleteBookmark,
  selectTable,
}) => {
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(entity);

  const [getAuditLogs] = useLazyGetAuditLogsQuery();

  const tableDataArgs = useMemo(
    () => ({
      data: {
        onFetch: async ({ params }: { params: IFetchParams }) => {
          const isEmptyAccountsList =
            globalFilters &&
            getIsFieldExistsAndEmpty(globalFilters, 'accountId');

          if (isEmptyAccountsList) {
            return EMPTY_AUDIT_LOGS;
          }

          setStartHandleTime();

          const preparedParams = prepareParams(
            {
              ...globalFilters,
              ...params,
            },
            entity,
          );

          const response = await getAuditLogs(preparedParams);

          if (response.error || !response.data) {
            return EMPTY_AUDIT_LOGS;
          }

          return response.data;
        },
      },
      filters: { getDefaultFilters },
      saveViewParamsAfterLeave: true,
      tableId,
      sorting: { getDefaultSorting: () => DEFAULT_SORT_BY },
    }),
    [entity, setStartHandleTime, globalFilters, getAuditLogs, tableId],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    setSorting,
    filters,
    fetchData: refetch,
  } = useTableData<IFetchAuditLogResponse>(tableDataArgs);

  useEffect(() => {
    if (isFunction(updateRefetch)) {
      updateRefetch({
        refetch,
        isLoading,
      });
    }
  }, [refetch, isLoading, updateRefetch]);

  const prevData = usePrevious(data);

  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const columns = useColumns({
    onFilter: setFilter,
    onRemove: removeFilter,
  });

  const filterProps = {
    removeAllFilters: resetFilters,
    filters,
    manualFilters: true,
  };

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  const exportTableParams = useMemo(
    () => ({
      type: 'server',
      onFetch: async (params: IFetchParams) => {
        const preparedParams = prepareParams(
          {
            ...params,
            ...globalFilters,
          },
          entity,
        );

        const response = await getAuditLogs({
          ...preparedParams,
          offset: 0,
        });

        return response?.data?.logs;
      },
    }),
    [entity, getAuditLogs, globalFilters],
  ) as ExportTableParams<IAuditLog> | undefined;

  useEffect(() => {
    if (data && !isEqual(prevData, data)) {
      logHandleTime();
    }
  }, [data, logHandleTime, prevData]);

  const bookmarkComponent = useMemo(() => {
    if (globalFilters) {
      return null;
    }

    return (
      <Bookmarks
        initialValues={selectedBookmark}
        onSave={(name) => handleSaveBookmark(name, filters)}
        onSaveAsNew={(name) => handleSaveAsNewBookmark(name, filters)}
        onShare={handleShareBookmark}
        onDelete={handleDeleteBookmark}
      />
    );
  }, [
    filters,
    globalFilters,
    handleSaveBookmark,
    handleSaveAsNewBookmark,
    handleShareBookmark,
    handleDeleteBookmark,
    selectedBookmark,
  ]);

  const displayedColumnKeys = useMemo(
    () =>
      selectedBookmark.columns.length
        ? selectedBookmark.columns
        : DISPLAYED_COLUMN_KEYS,
    [selectedBookmark.columns],
  );

  return (
    <Table<IAuditLog>
      columns={columns}
      data={data?.logs || EMPTY_ARRAY}
      defaultSortBy={DEFAULT_SORT_BY}
      displayedColumnKeys={displayedColumnKeys}
      exportTableParams={exportTableParams}
      filteringProps={filterProps}
      filtersExpanded
      filtersRightPanelComponent={bookmarkComponent}
      hasFilters
      hasPagination
      isFlexLayout
      isLoading={isLoading}
      isPinnedHeader={tableId === TABLE_ID ? true : undefined}
      manualSortBy
      noDataHeight={NO_DATA_HEIGHT}
      onSort={setSorting}
      saveColumnOrder
      saveViewParamsAfterLeave
      serverPaginationProps={serverPaginationProps}
      showScrollbar
      showTableInfo
      tableId={tableId}
      title={selectTable || 'Audit Logs'}
    />
  );
};

export const AuditLogContainer: FC<IAuditLogContainerProps> = ({
  entity = 'audit-log-list',
  globalFilters,
  updateRefetch,
  tableId = TABLE_ID,
  selectTable,
}) => {
  if (globalFilters) {
    const {
      selectedBookmark,
      handleSaveBookmark,
      handleSaveAsNewBookmark,
      handleShareBookmark,
      handleDeleteBookmark,
    } = getDefaultBookmarkResponse('Audit logs');

    return (
      <AuditLog
        selectTable={selectTable}
        entity={entity}
        globalFilters={globalFilters}
        tableId={tableId}
        updateRefetch={updateRefetch}
        selectedBookmark={selectedBookmark}
        handleSaveBookmark={handleSaveBookmark}
        handleSaveAsNewBookmark={handleSaveAsNewBookmark}
        handleShareBookmark={handleShareBookmark}
        handleDeleteBookmark={handleDeleteBookmark}
      />
    );
  }

  return (
    <WithBookmarks
      component={AuditLog}
      entity={entity}
      globalFilters={globalFilters}
      pageName="Audit logs"
      tableId={tableId}
      updateRefetch={updateRefetch}
    />
  );
};
