import { sendNotification } from '~/shared/utils';
import {
  ILegalEntityDetail,
  ILegalEntityFormData,
} from '~/types/legalEntities';

import { legalEntityFormMapper } from './legalEntities.mapper';
import { LegalEntitiesRepository } from './legalEntities.repository';

export class LegalEntitiesService {
  public async resolveLegalEntityDetail(
    legalEntity: string,
  ): Promise<ILegalEntityFormData | null> {
    try {
      const { data } = await LegalEntitiesRepository.fetchLegalEntityDetail(
        legalEntity,
      );

      return legalEntityFormMapper(data);
    } catch (error) {
      return null;
    }
  }

  public async resolveLegalEntityDetails(): Promise<
    ILegalEntityDetail[] | null
  > {
    try {
      const { data } = await LegalEntitiesRepository.fetchLegalEntityDetails();

      return data.values;
    } catch (error) {
      return null;
    }
  }

  public async postLegalEntity(
    formData: ILegalEntityFormData,
  ): Promise<ILegalEntityDetail | null> {
    try {
      const { data } = await LegalEntitiesRepository.postLegalEntity(formData);

      sendNotification('Legal entity has been submitted', 'success');

      return data;
    } catch (error) {
      sendNotification('Error adding legal entity', 'error');

      return null;
    }
  }

  public async updateLegalEntity(
    legalEntity: string,
    formData: ILegalEntityFormData,
  ): Promise<ILegalEntityDetail | null> {
    try {
      const { data } = await LegalEntitiesRepository.updateLegalEntity(
        legalEntity,
        formData,
      );

      sendNotification('Legal entity has been updated', 'success');

      return data;
    } catch (error) {
      sendNotification('Error updating legal entity', 'error');

      return null;
    }
  }

  public async deleteLegalEntity(legalEntity: string): Promise<boolean> {
    try {
      await LegalEntitiesRepository.deleteLegalEntity(legalEntity);

      sendNotification('Legal entity has been deleted', 'success');

      return true;
    } catch (error) {
      sendNotification('Error deleting legal entity', 'error');

      return false;
    }
  }
}
