import { apiRequest } from '~/shared/utils';

import { FETCH_EXANTE_COUNTERPARTY } from './endpoints';
import { IExanteCounterpartyResponse } from './types';

export class ExanteCounterpartyRepository {
  public static fetchExanteCounterparty() {
    return apiRequest<IExanteCounterpartyResponse>({
      url: FETCH_EXANTE_COUNTERPARTY,
    });
  }
}
