import axios from 'axios';

import { IErrorInfo } from '~/types/errors';

export function transformErrorToInfoObject(error: unknown): IErrorInfo | null {
  if (axios.isAxiosError(error)) {
    const response = error.response;

    if (response) {
      const { data, request } = response;

      return {
        statusCode: data.statusCode,
        message: data.message,
        url: request.responseURL,
      };
    }
  }

  if (error instanceof Error) {
    return { message: `${error.name}: ${error.message}` };
  }

  return null;
}
