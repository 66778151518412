import { MirroringRuleOptions } from '~/pages/MirroringRulePage/constants';
import { mirroringRulesService } from '~/resources';
import { sendUiKitErrorNotification } from '~/shared/utils';
import { EMPTY_SERVICE_RESPONSE } from '~/shared/utils/services';
import { mirroringRuleApi } from '~/store/mirroringRule';
import { TFormHandlerNew } from '~/types/form';
import { IMirroringRule } from '~/types/mirroringRules';
import { TDispatch } from '~/types/store';

import { TFormData } from '../types';

interface ICreateHandlersMapArguments {
  dispatch: TDispatch;
  mirroringRule?: IMirroringRule | null;
  activeOption: MirroringRuleOptions;
}

export const createHandlersMap = ({
  dispatch,
  mirroringRule,
  activeOption,
}: ICreateHandlersMapArguments): TFormHandlerNew<TFormData> | null => {
  if (!mirroringRule) {
    return null;
  }

  return {
    mirroringRule: (data) => {
      if (!data) {
        return Promise.resolve(EMPTY_SERVICE_RESPONSE);
      }

      try {
        const dataClone = { ...data };

        if (activeOption === MirroringRuleOptions.Categories) {
          dataClone.operationTypes = [];
        } else {
          dataClone.categories = [];
        }

        return mirroringRulesService
          .updateMirroringRule(dataClone.id, {
            ...dataClone,
          })
          .then((result) => {
            if (result.error) {
              dispatch(mirroringRuleApi.util.invalidateTags(['MirroringRule']));
            } else {
              dispatch(
                mirroringRuleApi.util.updateQueryData(
                  'getMirroringRule',
                  mirroringRule.id,
                  () => result.data,
                ),
              );
            }

            return result;
          });
      } catch (error) {
        sendUiKitErrorNotification(error);

        return Promise.resolve(EMPTY_SERVICE_RESPONSE);
      }
    },
  };
};
