import { createElement } from 'react';
import { FilterValue, ICellValue, IColumn } from 'react-ui-kit-exante';

import { TooltipWrapper } from '~/shared/components/TooltipWrapper';
import { transformVariantsToSelectOptions } from '~/shared/utils';
import { AutoCompleteSearch } from '~/types/common';
import { ITransaction } from '~/types/transactions';

export const DISPLAYED_COLUMN_KEYS: (keyof ITransaction | 'actions')[] = [
  'id',
  'accountId',
  'timestamp',
  'operationType',
  'sum',
  'asset',
  'symbolId',
  'symbolType',
  'who',
  'comment',
  'clientType',
  'internalCounterparty',
  'valueDate',
  'exchangeCommission',
  'executionCounterparty',
  'category',
  'actions',
];

interface IGetColumns {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
  types: {
    legalEntity: string[];
    categories: string[];
    operations: string[];
  };
  fetchAccounts: AutoCompleteSearch;
  fetchAssets: AutoCompleteSearch;
}

export const getColumns = ({
  onFilter,
  onRemove,
  types: { categories, legalEntity, operations },
}: IGetColumns): IColumn<ITransaction>[] => [
  { Header: 'Id', accessor: 'id', disableFilters: true },
  {
    Header: 'Account id',
    accessor: 'accountId',
    disableSortBy: true,
    onFilter,
    onRemove,
  },
  {
    Header: 'Timestamp',
    accessor: 'timestamp',
    disableFilters: true,
    sortDescFirst: true,
    formatting: 'dateTimeUTC',
  },
  {
    Header: 'Operation type',
    accessor: 'operationType',
    disableSortBy: true,
    type: 'multiSelect',
    filterOptions: transformVariantsToSelectOptions(operations),
    onFilter,
    onRemove,
  },
  {
    Header: 'Sum',
    accessor: 'sum',
    disableSortBy: true,
    disableFilters: true,
    formatting: 'number',
  },
  {
    Header: 'Sum in EUR',
    accessor: 'convertedSum',
    disableSortBy: true,
    disableFilters: true,
    formatting: 'number',
  },
  {
    Header: 'Asset',
    accessor: 'asset',
    disableSortBy: true,
    onFilter,
    onRemove,
  },
  {
    Header: 'Symbol',
    accessor: 'symbolId',
    disableSortBy: true,
    onFilter,
    onRemove,
  },
  {
    Header: 'Symbol type',
    accessor: 'symbolType',
    disableSortBy: true,
    disableFilters: true,
  },
  { Header: 'Who', accessor: 'who', disableSortBy: true, onFilter, onRemove },
  {
    Header: 'Comment',
    accessor: 'comment',
    disableSortBy: true,
    disableFilters: true,
    editParams: {
      editable: true,
    },
  },
  {
    Header: 'Internal comment',
    accessor: 'internalComment',
    disableSortBy: true,
    disableFilters: true,
    editParams: {
      editable: true,
    },
  },
  {
    Header: 'Client type',
    accessor: 'clientType',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Internal counterparty',
    accessor: 'internalCounterparty',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Value date',
    accessor: 'valueDate',
    type: 'dateRange',
    disableSortBy: true,
    disableFilters: true,
    formatting: 'dateUTC',
  },
  {
    Header: 'Order id',
    accessor: 'orderId',
    disableSortBy: true,
    onFilter,
    onRemove,
  },
  {
    Header: 'Order position',
    accessor: 'orderPos',
    disableSortBy: true,
    onFilter,
    onRemove,
  },
  {
    Header: 'Price',
    accessor: 'price',
    disableSortBy: true,
    disableFilters: true,
    formatting: 'number',
  },
  {
    Header: 'Exchange commission',
    accessor: 'exchangeCommission',
    disableSortBy: true,
    disableFilters: true,
    formatting: 'number',
  },
  {
    Header: 'Category',
    accessor: 'category',
    type: 'multiSelect',
    filterOptions: transformVariantsToSelectOptions(categories),
    disableSortBy: true,
    onFilter,
    onRemove,
  },
  {
    Header: 'Settlement currency movement',
    accessor: 'settlementCurrencyMovement',
    disableSortBy: true,
    disableFilters: true,
    formatting: 'number',
  },
  {
    Header: 'Settlement counterparty',
    accessor: 'settlementCounterparty',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Client counterparty',
    accessor: 'clientCounterparty',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Base currency',
    accessor: 'baseCurrency',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Counterparty',
    accessor: 'executionCounterparty',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Transfer ID',
    accessor: 'transferId',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Uuid',
    accessor: 'uuid',
    disableSortBy: true,
    onFilter,
    onRemove,
  },
  {
    Header: 'Legal entity',
    accessor: 'legalEntity',
    disableSortBy: true,
    type: 'multiSelect',
    filterOptions: transformVariantsToSelectOptions(legalEntity),
    onFilter,
    onRemove,
  },
  {
    Header: 'Extra Data',
    accessor: 'extraData',
    disableSortBy: true,
    disableFilters: true,
    tooltip: {
      type: 'custom',
      options: {
        Title(value: unknown) {
          return createElement(TooltipWrapper, { value });
        },
      },
    },
  },
  {
    Header: 'Settlement Currency',
    accessor: 'settlementCurrency',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Locked Quantity',
    accessor: 'blockedQtty',
    disableSortBy: true,
    disableFilters: true,
    Cell: ({ row: { values } }: ICellValue<ITransaction>) => {
      const blockedQtty = values?.blockedQtty;
      return blockedQtty ? Number(blockedQtty).toLocaleString() : '–';
    },
  },
];
