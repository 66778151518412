import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { NameCell } from '~/shared/components';
import { selectFiltersLayer } from '~/store/commissions';

import {
  CheckboxCell,
  CurrencyCell,
  InheritedCell,
  OverrideCell,
  RuleCell,
  TextCell,
  TotalCell,
} from './components';

export function useColumns() {
  const filtersLayer = useSelector(selectFiltersLayer);

  const getProps = useCallback(() => ({ layer: filtersLayer }), [filtersLayer]);

  const columns = [
    {
      Cell: NameCell,
      Header: 'Name',
      accessor: 'displayName',
      disableSortBy: true,
      id: 'name',
      width: 2000,
    },
    {
      Cell: OverrideCell,
      Header: 'Override',
      accessor: 'override',
      disableSortBy: true,
      maxWidth: 120,
      getProps,
    },
    {
      Cell: InheritedCell,
      Header: 'Inherited',
      accessor: 'inherited',
      disableSortBy: true,
      maxWidth: 120,
      getProps,
    },
    {
      Cell: CurrencyCell,
      Header: 'Currency',
      accessor: 'currency',
      disableSortBy: true,
      maxWidth: 140,
      getProps,
    },
    {
      Header: 'Min fee',
      columns: [
        {
          Cell: TextCell,
          Header: 'Value',
          accessor: 'minimumFee',
          disableSortBy: true,
          maxWidth: 100,
          getProps,
        },
        {
          Cell: TextCell,
          Header: 'Odd',
          accessor: 'minFeeOdd',
          disableSortBy: true,
          maxWidth: 100,
          getProps,
        },
        {
          Cell: TextCell,
          Header: 'Rebate rate',
          accessor: 'minFeeRebateRate',
          disableSortBy: true,
          minWidth: 100,
          getProps,
        },
        {
          Cell: CheckboxCell,
          Header: 'Exchange',
          accessor: 'minFeeExchange',
          disableSortBy: true,
          minWidth: 100,
          getProps,
        },
      ],
    },
    {
      Cell: RuleCell,
      Header: 'Exchange',
      accessor: 'exchange',
      disableSortBy: true,
      maxWidth: 200,
      getProps,
    },
    {
      Cell: RuleCell,
      Header: 'Internal',
      accessor: 'internal',
      disableSortBy: true,
      maxWidth: 200,
      getProps,
    },
    {
      Cell: RuleCell,
      Header: 'Rebate',
      accessor: 'rebate',
      disableSortBy: true,
      maxWidth: 200,
      getProps,
    },
    {
      Cell: TotalCell,
      Header: 'Total',
      accessor: 'total',
      disableSortBy: true,
      maxWidth: 200,
      getProps,
    },
  ];

  return columns;
}
