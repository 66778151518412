import { pickBy } from 'lodash';
import qs from 'qs';

import { AUTHORIZATION_HEADER_KEY, SESSION_ID_HEADER_KEY } from '~/constants';

import { TAccessToken } from './types';

export function getDefaultHeaders(sessionId = '', accessToken = '') {
  // temporary "crutch", we are waiting for a fix on the backend
  // so that validation can be turned off in the request body
  const baseHeaders: { 'Content-Type': string } = {
    'Content-Type': 'application/json',
  };

  if (sessionId) {
    return { ...baseHeaders, [SESSION_ID_HEADER_KEY]: sessionId };
  }

  if (accessToken) {
    try {
      const { access_token: token }: TAccessToken = JSON.parse(accessToken);
      return {
        ...baseHeaders,
        [AUTHORIZATION_HEADER_KEY]: `Bearer ${token}`,
      };
    } catch (error) {
      console.error(`Access token error: ${error}`);
    }
  }

  return baseHeaders;
}

export function paramsSerializer(params: Record<string, unknown>) {
  const paramsWithoutEmptyStrings = pickBy(params, (value) => value !== '');

  return qs.stringify(paramsWithoutEmptyStrings, {
    arrayFormat: 'comma',
    skipNulls: true,
  });
}
