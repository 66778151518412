import { PROXY_CORE_PATH } from '~/shared/utils';
import { FilterLayers } from '~/types/limits';

const LIMITS_PATH = '/api/symbols/limits';

export const FETCH_LIMITS = `${LIMITS_PATH}/default`;
export const FETCH_LIMITS_TREE = `${FETCH_LIMITS}/tree`;
export const FETCH_LIMITS_SETS = `${PROXY_CORE_PATH}v2.0/limits_set`;

export const getFetchLimitsInstruments = (
  layer: FilterLayers,
  entity?: number | string,
) => {
  if (layer === FilterLayers.Groups && entity) {
    return `${LIMITS_PATH}/group?groupId=${entity}`;
  }

  if (layer === FilterLayers.Accounts && entity) {
    return `${LIMITS_PATH}/account?accountId=${entity}`;
  }

  return `${LIMITS_PATH}/default`;
};

export const getPostLimitsEndpoint = (
  layer: FilterLayers,
  account?: number | string,
) => {
  if (layer === FilterLayers.Accounts && account) {
    return `${PROXY_CORE_PATH}v2.0/accounts/${account}/limits`;
  }

  return `${PROXY_CORE_PATH}v2.0/limits`;
};
