interface ErrorWithResponse {
  response?: {
    status?: number;
    config: {
      method: string;
    };
  };
}

function isErrorWithResponse(error: unknown): error is ErrorWithResponse {
  return (error as ErrorWithResponse).response !== undefined;
}

export function check403Error(error: unknown) {
  return (
    isErrorWithResponse(error) &&
    error.response &&
    error.response.status === 403 &&
    error.response.config.method === 'get'
  );
}
