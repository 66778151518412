import { createApi } from '@reduxjs/toolkit/query/react';

import { IDefaultProviders } from '~/constants';
import { baseQueryHandler } from '~/shared/utils';
import {
  IAccountPurposesResponse,
  IAssetResponse,
  ITypesResponse,
  ITypesWithDefaultResponse,
} from '~/types/types';

import { getTypeEndpoint } from './types.api.endpoints';
import { mapperCounterParties } from './types.api.helpers';

type RequestParams = { onError?: (error: Error) => void };

export const typesApi = createApi({
  reducerPath: 'typesApi',
  baseQuery: baseQueryHandler,
  endpoints: (builder) => ({
    getTradeTypes: builder.query<string[], RequestParams | void>({
      query: ({ onError } = {}) => ({ url: getTypeEndpoint('trade'), onError }),
      transformResponse: (response) => response.values,
    }),
    getCounterPartyTypes: builder.query<
      IDefaultProviders,
      RequestParams | void
    >({
      query: ({ onError } = {}) => ({
        url: getTypeEndpoint('counter_parties'),
        onError,
      }),
      transformResponse: (response) => mapperCounterParties(response.data),
    }),
    getLegalEntityTypes: builder.query<
      ITypesWithDefaultResponse,
      RequestParams | void
    >({
      query: ({ onError } = {}) => ({
        url: getTypeEndpoint('legalEntity'),
        onError,
      }),
    }),
    getClientTypes: builder.query<
      ITypesWithDefaultResponse,
      RequestParams | void
    >({
      query: ({ onError } = {}) => ({
        url: getTypeEndpoint('client'),
        onError,
      }),
    }),
    getFeePolicyTypes: builder.query<ITypesResponse, RequestParams | void>({
      query: ({ onError } = {}) => ({
        url: getTypeEndpoint('feePolicy'),
        onError,
      }),
    }),
    getCategoriesTypes: builder.query<ITypesResponse, RequestParams | void>({
      query: ({ onError } = {}) => ({
        url: getTypeEndpoint('categories'),
        onError,
      }),
    }),
    getOwnershipType: builder.query<
      ITypesWithDefaultResponse,
      RequestParams | void
    >({
      query: ({ onError } = {}) => ({
        url: getTypeEndpoint('accountOwnership'),
        onError,
      }),
    }),
    getAccountStatusTypes: builder.query<
      ITypesWithDefaultResponse,
      RequestParams | void
    >({
      query: () => ({
        url: getTypeEndpoint('accountStatus'),
      }),
    }),
    getIncorporationTypes: builder.query<
      ITypesWithDefaultResponse,
      RequestParams | void
    >({
      query: ({ onError } = {}) => ({
        url: getTypeEndpoint('incorporation'),
        onError,
      }),
    }),
    getExanteTypes: builder.query<
      ITypesWithDefaultResponse,
      RequestParams | void
    >({
      query: ({ onError } = {}) => ({
        url: getTypeEndpoint('exante'),
        onError,
      }),
    }),
    getUserClientsPermissionsTypes: builder.query<
      ITypesResponse,
      RequestParams | void
    >({
      query: ({ onError } = {}) => ({
        url: getTypeEndpoint('userClientPermissions'),
        onError,
      }),
    }),
    getOperationTypes: builder.query<
      ITypesResponse,
      | (RequestParams & {
          isAutoConversionAllowed?: boolean;
          withSystemOnly?: boolean;
        })
      | void
    >({
      query: ({
        onError,
        isAutoConversionAllowed,
        withSystemOnly = true,
      } = {}) => ({
        url: getTypeEndpoint('operation'),
        onError,
        params: { withSystemOnly, isAutoConversionAllowed },
      }),
    }),
    getAccountTypes: builder.query<ITypesResponse, RequestParams | void>({
      query: ({ onError } = {}) => ({
        url: getTypeEndpoint('account'),
        onError,
      }),
    }),
    getSuperUserTypes: builder.query<
      ITypesWithDefaultResponse,
      RequestParams | void
    >({
      query: ({ onError } = {}) => ({
        url: getTypeEndpoint('superUser'),

        onError,
      }),
    }),
    getInterCashCardStatusTypes: builder.query<
      ITypesWithDefaultResponse,
      RequestParams | void
    >({
      query: ({ onError } = {}) => ({
        url: getTypeEndpoint('interCashCardStatus'),
        onError,
      }),
    }),
    getAccountPurposeTypes: builder.query<
      IAccountPurposesResponse,
      RequestParams | void
    >({
      query: ({ onError } = {}) => ({
        url: getTypeEndpoint('accountPurposes'),
        onError,
      }),
    }),
    getAssetTypes: builder.query<IAssetResponse, RequestParams | void>({
      query: ({ onError } = {}) => ({
        url: getTypeEndpoint('asset'),
        onError,
      }),
    }),
    getSettlementCounterParty: builder.query<{ values: string[] }, void>({
      query: () => ({
        url: getTypeEndpoint('settlementCounterparty'),
      }),
    }),
    getExecutionCounterParty: builder.query<{ values: string[] }, void>({
      query: () => ({
        url: getTypeEndpoint('executionCounterparty'),
      }),
    }),
  }),
});

export const {
  useGetAccountPurposeTypesQuery,
  useGetAccountStatusTypesQuery,
  useGetAccountTypesQuery,
  useGetAssetTypesQuery,
  useGetCategoriesTypesQuery,
  useGetClientTypesQuery,
  useGetCounterPartyTypesQuery,
  useGetExanteTypesQuery,
  useGetIncorporationTypesQuery,
  useGetInterCashCardStatusTypesQuery,
  useGetLegalEntityTypesQuery,
  useGetOperationTypesQuery,
  useGetOwnershipTypeQuery,
  useGetTradeTypesQuery,
  useGetUserClientsPermissionsTypesQuery,
} = typesApi;
