import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  calculateCountOfPages,
  IconButton,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { useLazyGetAccountsQuery } from '~/api';
import { EMPTY_ARRAY, NO_DATA_HEIGHT } from '~/constants';
import { useLogHandleTime, usePrevious } from '~/hooks';
import { IClientTabComponentProps } from '~/pages/ClientPage/TabManagement/types';
import { getAccountPageRoute, ACCOUNT_ADD_PATH } from '~/routes';
import { getTableId, replaceRouteVariable } from '~/shared/utils';
import { getFooterPropsWithTotal } from '~/shared/utils/table';
import { IAccount, IAccountsState } from '~/types/accounts';
import { TParams } from '~/types/api';

import { defaultAccountState } from '../UserActivityContainer/useAccountsByUser';

import { DISPLAYED_COLUMN_KEYS, getColumns } from './columns';

const CLIENT_ACCOUNTS_TABLE_ID = getTableId('ClientAccounts');

export const ClientAccountsTabContainer = ({
  clientId,
  setRefetch,
}: IClientTabComponentProps) => {
  const navigate = useNavigate();

  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'client-accounts-entry-screen',
  );
  const [fetchAccounts] = useLazyGetAccountsQuery();

  const getAccounts = useCallback(
    async ({ params }: { params: TParams }) => {
      setStartHandleTime();

      const response = await fetchAccounts({ ...params, clientId });

      if ('error' in response || !response.data) {
        return defaultAccountState;
      }
      return response.data;
    },
    [clientId, fetchAccounts, setStartHandleTime],
  );
  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getAccounts },
      ...(CLIENT_ACCOUNTS_TABLE_ID
        ? { saveViewParamsAfterLeave: true, CLIENT_ACCOUNTS_TABLE_ID }
        : {}),
    }),
    [getAccounts],
  );
  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    resetFilters,
    setSorting,
    filters,
    params,
    setFilter,
    removeFilter,
    fetchData,
  } = useTableData<IAccountsState>(tableDataArgs);

  const total = data?.pagination.total || 0;
  const pageCount = calculateCountOfPages(total, limit);

  const prevAccounts = usePrevious(data?.accounts);

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
    }),
    [resetFilters, filters],
  );

  const columns = useMemo(() => {
    return getColumns({ onFilter: setFilter, onRemove: removeFilter });
  }, [removeFilter, setFilter]);

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  const handleRowClick = useCallback(
    ({ id }: IAccount, index: number) => {
      navigate(getAccountPageRoute(id), {
        state: {
          previousPath: window.location.href,
          requestParams: params,
          cursor: index,
        },
      });
    },
    [navigate, params],
  );

  const handleClickNewAccount = useCallback(() => {
    navigate(replaceRouteVariable(ACCOUNT_ADD_PATH, clientId), {
      state: {
        previousPath: window.location.href,
      },
    });
  }, [clientId, navigate]);

  const additionalActions = useMemo(
    () => [
      {
        key: 'addAccount',
        component: (
          <IconButton
            iconName="AddIcon"
            iconColor="action"
            label="Add account"
            iconSize={24}
            onClick={handleClickNewAccount}
          />
        ),
      },
    ],
    [handleClickNewAccount],
  );

  useEffect(() => {
    setRefetch('accounts', {
      refetch: fetchData,
      isLoading,
    });
  }, [fetchData, isLoading]);

  useEffect(() => {
    if (data?.accounts && !isEqual(prevAccounts, data?.accounts)) {
      logHandleTime();
    }
  }, [data, prevAccounts, logHandleTime]);

  return (
    <Table
      additionalActions={additionalActions}
      columns={columns}
      data={data?.accounts || EMPTY_ARRAY}
      displayedColumnKeys={DISPLAYED_COLUMN_KEYS}
      filteringProps={filteringProps}
      filtersExpanded
      getFooterProps={getFooterPropsWithTotal}
      handleRowClick={handleRowClick}
      hasFilters
      hasPagination
      isFlexLayout
      isLoading={isLoading}
      manualSortBy
      noDataHeight={NO_DATA_HEIGHT}
      onSort={setSorting}
      serverPaginationProps={serverPaginationProps}
      showTableInfo
      tableId={CLIENT_ACCOUNTS_TABLE_ID}
      title="Accounts"
    />
  );
};
