import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IColumn } from 'react-ui-kit-exante';

import { NON_TRADING_COMMISSIONS_ENTRY_SCREEN } from '~/routes';
import { replaceRouteVariable } from '~/shared/utils';
import { selectGroupByName } from '~/store/nonTradingCommissions/slice';
import type { TApplicationState } from '~/types/store';

import { getCommissionValue } from '../../helpers/getCommissionValue';
import { getMinMax } from '../../helpers/getMinMax';
import { removeCurrencyFromHeader } from '../../helpers/removeCurrencyFromHeader';
import { TTableRow, TTableData } from '../../types';

import { LinkStyled, MinMaxStyled } from './ValueCell.styled';

interface IValueCell {
  value: TTableRow;
  column: IColumn<TTableData>;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const ValueCell = ({ value, column, row }: IValueCell) => {
  const group = useSelector((state: TApplicationState) =>
    selectGroupByName(state, removeCurrencyFromHeader(column.Header)),
  );

  const { percent, min, max, currency, fixed, type, intervalType } =
    value || {};

  const formattedValue = useMemo(
    () => getCommissionValue({ fixed, percent, type, intervalType }),
    [fixed, percent, type, intervalType],
  );

  const formattedMinMax = useMemo(
    () => getMinMax(currency, min, max),
    [currency, min, max],
  );

  const link = useMemo(
    () => replaceRouteVariable(NON_TRADING_COMMISSIONS_ENTRY_SCREEN, group.id),
    [group.id],
  );

  const state = useMemo(
    () => ({ commissionName: row.original.commissionName }),
    [row.original.commissionName],
  );

  if (!value) {
    return (
      <LinkStyled to={link} state={state}>
        –
      </LinkStyled>
    );
  }

  return (
    <LinkStyled to={link} state={state}>
      {formattedValue}

      {formattedMinMax ? <MinMaxStyled>{formattedMinMax}</MinMaxStyled> : null}
    </LinkStyled>
  );
};
