import { useCallback, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Autocomplete } from 'react-ui-kit-exante';

import { EMPTY_ARRAY } from '~/constants';
import { IOption } from '~/types/form';

export interface IFormMultiSelectContainerProps {
  disableCloseOnSelect?: boolean;
  disabled?: boolean;
  freeSolo?: boolean;
  fullWidth?: boolean;
  isMultiple?: boolean;
  label?: string;
  name: string;
  options: Array<IOption>;
}

export const FormMultiSelectContainer = ({
  disableCloseOnSelect = true,
  disabled = false,
  freeSolo = false,
  fullWidth = true,
  isMultiple = true,
  label = '',
  name,
  options,
}: IFormMultiSelectContainerProps) => {
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { isDirty, error },
  } = useController({
    control,
    name,
  });

  const multiSelectChangeHandler = useCallback(
    (_: unknown, newValues: Array<number | string | IOption> | IOption) => {
      onChange(
        Array.isArray(newValues)
          ? newValues.map((newValue) => {
              if (
                typeof newValue === 'string' ||
                typeof newValue === 'number'
              ) {
                return newValue;
              }
              return newValue.value;
            })
          : newValues.value,
      );
    },
    [onChange],
  );

  const controlProps = useMemo(
    () => ({
      error: Boolean(error),
      warning: isDirty,
      message: error?.message,
    }),
    [error, isDirty],
  );

  // TODO move isOptionEqualToValue to utils
  return (
    <Autocomplete
      controlProps={controlProps}
      disableCloseOnSelect={disableCloseOnSelect}
      disabled={disabled}
      freeSolo={freeSolo}
      fullWidth={fullWidth}
      isMultiple={isMultiple}
      isOptionEqualToValue={(option, selectedValue) =>
        option.value === selectedValue
      }
      onChange={multiSelectChangeHandler}
      options={options}
      placeholder={label}
      value={value || EMPTY_ARRAY}
    />
  );
};
