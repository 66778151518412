export enum SpecialNodes {
  CURRENCY = 'CURRENCY',
  OPTION = 'OPTION',
}

export enum LimitMessages {
  ADD_GROUP_LIMITS_FAILED = 'Limits group add failed',
  ADD_GROUP_LIMITS_SUCCESS = 'Limits group add success',
  DELETE_GROUP_LIMITS_FAILED = 'Limits group delete failed',
  DELETE_GROUP_LIMITS_SUCCESS = 'Limits group delete success',
  GROUP_FAILED = 'Limits group for account updated failed',
  GROUP_SUCCESS = 'Limits group for account successfully updated',
  LIMITS_FAILED = 'Limits updated failed',
  LIMITS_SUCCESS = 'Limits updated successfully',
  NON_OVERRIDE_LIMIT = 'You can not change non-override limit',
  ROOT_NODE = 'You can not change root node',
}

export const DEFAULT_GROUP_ID = 1;
