import { PROXY_CORE_PATH } from '~/shared/utils';

import { IAccountSummaryParameters } from './types';

export const getAccountLocksEndpoint = (accountId: string) =>
  `${PROXY_CORE_PATH}v2.1/locked_assets?accountId=${accountId}`;

export const getCrossRatesEndpoint = (currency: string) =>
  `${PROXY_CORE_PATH}v2.1/quotecache/crossrates?from=${currency}`;

export const getAccountSummaryByCurrencyAPI = ({
  id,
  currency,
  date,
}: IAccountSummaryParameters) =>
  date
    ? `${PROXY_CORE_PATH}v2.0/accounts/${id}/summary/${date}/${currency}`
    : `${PROXY_CORE_PATH}v2.0/accounts/${id}/summary/${currency}`;

export const CREATE_ACCOUNT = `${PROXY_CORE_PATH}v2.1/accounts`;
export const FETCH_ACCOUNTS = '/api/accounts/';
export const FETCH_ACCOUNTS_STATISTIC = `/api/accountsStats`;

export const getAccountByIdEndpoint = (accountId: string) =>
  `${PROXY_CORE_PATH}v2.1/accounts/${accountId}`;

export const getAccountCommissionSettingsEndpoint = (id: string) =>
  `${PROXY_CORE_PATH}v2.1/accounts/${id}/commission_settings`;

export const getAccountExtReportsSettingsAPI = (id: string) =>
  `${PROXY_CORE_PATH}v2.1/accounts/${id}/ext_reports_settings`;

export const getAccountInterestsRatesEndpoint = (id: string) =>
  `${PROXY_CORE_PATH}v2.0/accounts/${id}/rates/interests`;

export const getAccountInternalSettingsAPI = (id: string) =>
  `${PROXY_CORE_PATH}v2.1/accounts/${id}/internal_settings`;

export const getAccountLimitSettingsEndpoint = (accountId: string) =>
  `${PROXY_CORE_PATH}v2.1/accounts/${accountId}/limits_settings`;

export const getAccountMetricsAPI = (accountId: string) =>
  `${PROXY_CORE_PATH}v2.0/accounts/${accountId}/metrics`;

export const getAccountMetricsByCurrencyDateAPI = (
  accountId: string,
  currency: string,
  date: string,
) => `${PROXY_CORE_PATH}v2.0/accounts/${accountId}/metrics/${date}/${currency}`;

export const getAccountOvernightsEndpoint = (id: string) =>
  `${PROXY_CORE_PATH}v2.1/accounts/${id}/rates/overnights`;

export const getAccountPermissionSettingsEndpoint = (id: string) =>
  `${PROXY_CORE_PATH}v2.1/accounts/${id}/symbol_permissions_settings`;

export const getAccountRegularPaymentsSettingsEndpoint = (id: string) =>
  `${PROXY_CORE_PATH}v2.1/accounts/${id}/regular_payments_settings`;

export const getCashConversionSettingsAPI = (id: string) =>
  `${PROXY_CORE_PATH}v2.1/accounts/${id}/cash_conversion_settings`;

export const getDeleteRebateAccountsAPI = (
  accountId: string,
  rebateAccountId: string,
) =>
  `${PROXY_CORE_PATH}v2.0/accounts/${accountId}/rebate_accounts/${rebateAccountId}`;

export const getMarginSettingsAPI = (id: string) =>
  `${PROXY_CORE_PATH}v2.1/accounts/${id}/margin_settings`;

export const getRebateAccountsAPI = (id: string) =>
  `${PROXY_CORE_PATH}v2.0/accounts/${id}/rebate_accounts`;

export const getReferralsAPI = (id: string) =>
  `${PROXY_CORE_PATH}v2.0/accounts/${id}/rebate_for`;

export const getRiskSettingsAPI = (id: string) =>
  `${PROXY_CORE_PATH}v2.0/accounts/${id}/rates/leverages`;

export const getRoutingSettingsAPI = (id: string) =>
  `${PROXY_CORE_PATH}v2.1/accounts/${id}/routing_settings`;

export const getCommissionsEndpoint = (id: string) =>
  `${PROXY_CORE_PATH}v2.1/accounts/${id}/commissions`;

export const getLimitsEndpoint = (id: string) =>
  `${PROXY_CORE_PATH}v2.0/accounts/${id}/limits`;

export const getPermissionsEndpoint = (id: string) =>
  `${PROXY_CORE_PATH}v2.1/accounts/${id}/permissions`;
export const getAccountSummaryData = ({
  id,
  currency,
}: IAccountSummaryParameters) =>
  `${PROXY_CORE_PATH}v2.0/accounts/${id}/summary/${currency}?calcDailyPnl=true`;
