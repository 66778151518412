import { isEqual } from 'lodash';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Bookmarks, Table, useTableData } from 'react-ui-kit-exante';

import { useLazyGetAccountsQuery } from '~/api';
import {
  useGetAccountPurposeTypesQuery,
  useGetAccountTypesQuery,
  useGetLegalEntityTypesQuery,
  useGetOwnershipTypeQuery,
} from '~/api/types/types.api';
import { DEFAULT_PAGINATION_RESPONSE } from '~/constants';
import { EXCLUDED_PARAMS } from '~/containers/AccountsContainer/params';
import {
  useBrandingList,
  useLogHandleTime,
  usePrevious,
  useSyncTableViewParams,
  useTotalNav,
} from '~/hooks';
import { useAccountStatuses } from '~/hooks/useAccountStatuses';
import { IBookmarkResponseProps } from '~/hooks/useBookmark/types';
import { getAccountPageRoute } from '~/routes';
import { WithBookmarks } from '~/shared/components/WithBookmarks';
import {
  calculateCountOfPages,
  getTableId,
  transformVariantsToSelectOptions,
} from '~/shared/utils';
import { selectCurrenciesOptions } from '~/store/currencies';
import { IAccount, IAccountsState } from '~/types/accounts';
import { TParams } from '~/types/api';

import { DISPLAYED_COLUMNS_KEYS, getColumns } from './columns';
import { getDefaultFilters } from './filters';
import { DEFAULT_SORTING, getDefaultSorting } from './sorting';

export const Accounts: FC<IBookmarkResponseProps> = ({
  selectedBookmark,
  handleSaveBookmark,
  handleSaveAsNewBookmark,
  handleShareBookmark,
  handleDeleteBookmark,
}) => {
  const { setStartHandleTime, logHandleTime } =
    useLogHandleTime('accounts-list');
  const currenciesOptions = useSelector(selectCurrenciesOptions);
  const navigate = useNavigate();
  const tableId = getTableId('Accounts');
  const { data: ownershipTypes } = useGetOwnershipTypeQuery();
  const { data: legalEntityTypes } = useGetLegalEntityTypesQuery();
  const legalEntitiesOptions = transformVariantsToSelectOptions(
    legalEntityTypes?.values,
    { capitalized: true, shouldSortAlphabetically: true },
  );
  const { brandingListWithoutAll } = useBrandingList();
  const [fetchAccounts] = useLazyGetAccountsQuery();

  const getAccounts = useCallback(
    async ({ params }: { params: TParams }) => {
      setStartHandleTime();
      const defaultState = {
        accounts: [],
        pagination: DEFAULT_PAGINATION_RESPONSE,
      };

      const response = await fetchAccounts(params);
      if (!response.data || 'error' in response) {
        return defaultState;
      }

      return response.data;
    },
    [fetchAccounts, setStartHandleTime],
  );

  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getAccounts },
      sorting: { getDefaultSorting },
      filters: { getDefaultFilters },
      tableId,
      saveViewParamsAfterLeave: true,
      excludedParams: EXCLUDED_PARAMS,
    }),
    [getAccounts, tableId],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    setSorting,
    filters,
    params,
  } = useTableData<IAccountsState>(tableDataArgs);

  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );
  const { accountStatusTypes } = useAccountStatuses();

  const totalNav = useTotalNav({
    label: "Selected accounts' Net Asset Value",
    nav: data?.summary?.nav,
  });

  const previousDataAccounts = usePrevious(data?.accounts);
  const { data: accountTypes } = useGetAccountTypesQuery();

  const { data: accountPurposeTypes } = useGetAccountPurposeTypesQuery();

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        types: {
          accountPurposes: accountPurposeTypes?.values || [],
          accountStatusTypes: accountStatusTypes || [],
          accountTypes: accountTypes?.values || [],
          legalEntityTypes: legalEntityTypes?.values || [],
          ownershipTypes: ownershipTypes?.values || [],
        },
        currencies: currenciesOptions,
        legalEntitiesOptions,
        brandingList: brandingListWithoutAll,
      }),
    [
      accountPurposeTypes,
      accountStatusTypes,
      accountTypes,
      brandingListWithoutAll,
      currenciesOptions,
      legalEntitiesOptions,
      legalEntityTypes?.values,
      ownershipTypes?.values,
      removeFilter,
      setFilter,
    ],
  );

  const filterProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
      manualFilters: true,
    }),
    [resetFilters, filters],
  );
  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  useSyncTableViewParams({ pageCount, setPage, tableId });

  const handleRowClick = useCallback(
    ({ id }: IAccount, index: number) => {
      navigate(getAccountPageRoute(id), {
        state: {
          previousPath: window.location.href,
          requestParams: params,
          cursor: index,
        },
      });
    },
    [navigate, params],
  );

  useEffect(() => {
    if (data?.accounts && !isEqual(previousDataAccounts, data?.accounts)) {
      logHandleTime();
    }
  }, [logHandleTime, data, previousDataAccounts]);

  const bookmarkComponent = useMemo(
    () => (
      <Bookmarks
        initialValues={selectedBookmark}
        onSave={(name) => handleSaveBookmark(name, filters)}
        onSaveAsNew={(name) => handleSaveAsNewBookmark(name, filters)}
        onShare={handleShareBookmark}
        onDelete={handleDeleteBookmark}
      />
    ),
    [
      filters,
      handleSaveBookmark,
      handleSaveAsNewBookmark,
      handleShareBookmark,
      handleDeleteBookmark,
      selectedBookmark,
    ],
  );

  const displayedColumnKeys = useMemo(
    () =>
      selectedBookmark.columns.length
        ? selectedBookmark.columns
        : DISPLAYED_COLUMNS_KEYS,
    [selectedBookmark.columns],
  );

  return (
    <Table
      columns={columns}
      commonValue={totalNav}
      data={data?.accounts || []}
      defaultSortBy={DEFAULT_SORTING}
      displayedColumnKeys={displayedColumnKeys}
      filteringProps={filterProps}
      filtersExpanded
      filtersRightPanelComponent={bookmarkComponent}
      handleRowClick={handleRowClick}
      hasFilters
      hasPagination
      isFlexLayout
      isLoading={isLoading}
      isPinnedHeader
      manualSortBy
      onSort={setSorting}
      saveColumnOrder
      saveViewParamsAfterLeave
      serverPaginationProps={serverPaginationProps}
      showScrollbar
      showTableInfo
      tableId={tableId}
      title="Accounts"
    />
  );
};

export const AccountsContainer = () => {
  const tableId = getTableId('Accounts');

  return (
    <WithBookmarks component={Accounts} pageName="Accounts" tableId={tableId} />
  );
};
