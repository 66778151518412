import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { omitBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Panel, Loader } from 'react-ui-kit-exante';

import { useAddTransferMutation, useLazyGetAccountDataQuery } from '~/api';
import {
  FormInputContainer,
  FormSelectAsyncContainer,
  FormSelectContainer,
  FormToggleButtonGroupContainer,
} from '~/containers/form';
import {
  useAutocomplete,
  useBack,
  useCurrency,
  useCurrentUserHasAllBrandingPermission,
  usePickUserPermissions,
} from '~/hooks';
import { TRANSFER_PATH } from '~/routes';
import { PanelHeaderControls } from '~/shared/components';
import { formatNumberByLocale } from '~/shared/utils';
import { selectCurrenciesOptionsByWeight } from '~/store/currencies';
import { IPosition } from '~/types/accounts';
import { IOption } from '~/types/form';

import TransferAddPageStyles from './TransferAddPage.module.css';
import {
  TYPE_OPTIONS,
  DEFAULT_VALUES,
  getTransferAddFormValidationSchema,
} from './helpers';

interface AssetOptions {
  price: string;
  quantity: string;
  value: string;
  label: string;
  currency: string;
}

export const TransferAddPage = () => {
  const [from, setFrom] = useState<IOption>();
  const [asset, setAsset] = useState('');
  const [type, setType] = useState('asset');
  const [currentValues, setCurrentValues] = useState<AssetOptions[]>([]);
  const [assetOptions, setAssetOptions] = useState<AssetOptions[]>([]);

  const userPermissions = usePickUserPermissions([
    'Transfer without validation',
  ]);

  const currency = useCurrency();
  const location = useLocation();
  const accountId = location?.state?.accountId;
  const redirectToTransactionsPage = useBack({
    defaultPath: TRANSFER_PATH,
    state: accountId,
  });
  const currenciesOptions = useSelector(selectCurrenciesOptionsByWeight);

  const [addTransfer, { isLoading }] = useAddTransferMutation();
  const { isLoadingUser, currentUser, currentUserHasAllBrandingPermission } =
    useCurrentUserHasAllBrandingPermission();
  const [getAccountData] = useLazyGetAccountDataQuery();
  const getAccountsAutocompleteFn = useAutocomplete('accounts');
  const getUsersAutocompleteFn = useAutocomplete('users');

  const username = currentUser?.username;
  const quantityValue = currentValues[0]?.quantity;

  const getPrice = () =>
    `Price: ${formatNumberByLocale(Number(currentValues[0]?.price)) || 0} ${
      currentValues[0]?.currency || ''
    }`;

  const useFormMethods = useForm({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(getTransferAddFormValidationSchema(type)),
  });

  const { setValue, handleSubmit, reset } = useFormMethods;

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoadingUser && !currentUserHasAllBrandingPermission) {
      navigate('/', {
        state: { previousPath: window.location.href },
      });
    }
  }, [isLoadingUser, navigate, currentUserHasAllBrandingPermission]);

  const fetchAccountData = async () => {
    const { data } = await getAccountData({
      currency,
      id: from?.value || accountId,
    });

    const prepareData = data?.positions.reduce(
      (acc: AssetOptions[], item: IPosition) => {
        const {
          symbolId,
          averagePrice,
          quantity,
          currency: currencyItem,
        } = item;

        if (symbolId && averagePrice && quantity) {
          return acc.concat({
            price: averagePrice,
            quantity,
            value: symbolId,
            label: symbolId,
            currency: currencyItem,
          });
        }
        return acc;
      },
      [],
    );

    setAssetOptions(prepareData);
  };

  const fetchData = useMemo(
    () => ({
      accountId: getAccountsAutocompleteFn(),
      user: getUsersAutocompleteFn(),
    }),
    [getAccountsAutocompleteFn, getUsersAutocompleteFn],
  );

  const onSubmitHandler = useCallback(
    async (
      params: Record<string, string | { value: string; label: string }>,
    ) => {
      const preparedParams = omitBy(params, (val, key) => {
        return (
          ['comment', 'internalComment'].includes(key) &&
          typeof val === 'string' &&
          !val.trim()
        );
      });

      const result = await addTransfer({
        ...preparedParams,
        useValidation: !userPermissions['Transfer without validation'].write,
      });

      if ('data' in result) {
        setAsset('');
        setType('asset');
        setCurrentValues([]);

        reset({
          ...params,
          asset: '',
          currency: '',
          comment: '',
          internalComment: '',
          amount: '',
          type: 'asset',
        });
      }
    },
    [userPermissions, addTransfer, reset],
  );

  useEffect(() => {
    if (accountId) {
      setFrom({ value: accountId, label: accountId });
    }
  }, []);

  useEffect(() => {
    setCurrentValues(
      assetOptions.filter((item: IOption) => item?.value === asset),
    );
  }, [asset, setType]);

  useEffect(() => {
    if (accountId || from) {
      fetchAccountData();
    }
  }, [accountId, from?.value, currency]);

  useEffect(() => {
    if (accountId && username) {
      setValue('from', {
        value: accountId,
        label: accountId,
      });
      setValue('username', { value: username, label: username });
      return;
    }
    if (username) {
      setValue('username', { value: username, label: username });
    }
  }, [username]);

  useEffect(() => {
    if (quantityValue) {
      setValue('amount', quantityValue);
    }
  }, [quantityValue]);

  useEffect(() => {
    setCurrentValues([]);
    setValue('asset', '');
    setValue('amount', '');
    setValue('currency', '');
  }, [type]);

  if (isLoadingUser) {
    return <Loader />;
  }

  return (
    <FormProvider {...useFormMethods}>
      {isLoading ? (
        <Loader size="l" isCentered />
      ) : (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <Panel
            action={
              <PanelHeaderControls onClose={redirectToTransactionsPage} />
            }
            disableBodyPaddings
            title="Add Transfer"
          />
          <Panel>
            <div className={TransferAddPageStyles.TwoInputsBlock}>
              <span className={TransferAddPageStyles.FirstInput}>
                <FormSelectAsyncContainer
                  label="From"
                  name="from"
                  getValue={setFrom}
                  fetchData={fetchData.accountId}
                />
              </span>
              <span className={TransferAddPageStyles.SecondInput}>
                <FormSelectAsyncContainer
                  fetchData={fetchData.accountId}
                  label="To"
                  name="to"
                />
              </span>
            </div>
            <div className={TransferAddPageStyles.OneInputBlock}>
              <FormSelectAsyncContainer
                label="User"
                name="username"
                fetchData={fetchData.user}
              />
            </div>
            <div className={TransferAddPageStyles.TwoInputsBlock}>
              <span
                className={cn(
                  TransferAddPageStyles.FirstInput,
                  TransferAddPageStyles.OverlapStyle,
                )}
              >
                <FormToggleButtonGroupContainer
                  exclusive
                  alwaysSelected
                  name="type"
                  fullWidth
                  getValue={setType}
                  options={TYPE_OPTIONS}
                />
              </span>
              {/* two ternary operators are used specifically for react hook forms */}
              <span className={TransferAddPageStyles.SecondInput}>
                {type === 'currency' ? (
                  <FormSelectContainer
                    label="Currency"
                    name="currency"
                    options={currenciesOptions}
                  />
                ) : null}
                {type === 'currency' ? null : (
                  <FormSelectContainer
                    label="Asset"
                    name="asset"
                    getValue={setAsset}
                    options={assetOptions}
                  />
                )}
              </span>
            </div>
            <div className={TransferAddPageStyles.AmountBlock}>
              <span
                className={cn(
                  TransferAddPageStyles.FirstInput,
                  TransferAddPageStyles.OverlapStyle,
                )}
              >
                <FormInputContainer label="Amount" name="amount" />
              </span>
              <div className={TransferAddPageStyles.StylePrice}>
                {getPrice()}
              </div>
            </div>
            <div className={TransferAddPageStyles.TwoInputsBlock}>
              <span className={TransferAddPageStyles.FirstTextArea}>
                <FormInputContainer multiline label="Comment" name="comment" />
              </span>
              <span className={TransferAddPageStyles.SecondTextArea}>
                <FormInputContainer
                  multiline
                  label="Internal comment"
                  name="internalComment"
                />
              </span>
            </div>
          </Panel>
        </form>
      )}
    </FormProvider>
  );
};
