import { ITransactionForm } from '~/types/transactions';

export const DEFAULT_VALUES: ITransactionForm = {
  accountId: { value: '', label: '' },
  asset: '',
  symbolId: '',
  transactionType: 'deposit',
  amount: '',
  price: '',
  extraData: {
    '4EyesCheck': false,
    marketPrice: '',
  },
  operationType: '',
  transferId: '',
  clientCounterparty: '',
  exanteCounterparty: '',
  valueDate: '',
  settlementCounterparty: '',
  counterpartyAccountId: '',
  executionCounterparty: '',
  useAutoCashConversion: false,
  comment: '',
  internalComment: '',
  takeCommission: false,
};

export const TRANSACTION_TYPE_OPTIONS = [
  { value: 'deposit', label: 'Deposit' },
  { value: 'withdraw', label: 'Withdraw' },
];

export const REQUIRED_VALUE_FOR_COUNTERPARTY_AND_TRANSFER =
  'FUNDING/WITHDRAWAL';

export const REQUIRED_VALUE_FOR_EXECUTION_COUNTERPARTY = 'TRADE';

export const MENU_PROPS = {
  PaperProps: {
    style: {
      maxHeight: 400,
    },
  },
};
