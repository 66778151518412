import { cloneDeep, get, has, set } from 'lodash';

import { isAxiosError } from '~/shared/utils';

const setIfNotExists = (
  target: Parameters<typeof set>[0],
  path: string,
  value: unknown,
) => {
  if (!has(target, path)) {
    set(target, path, value);
  }
};

export const transformAuditLogErrorToValidError = (error: unknown) => {
  if (isAxiosError(error)) {
    const messageObject = cloneDeep(get(error, 'response.data'));
    const status = get(error, 'response.status');

    setIfNotExists(error, 'response.data.message', messageObject);
    setIfNotExists(error, 'response.data.statusCode', status);
  }

  return error;
};
