import { useCallback, useEffect, useState } from 'react';

const MIN_EXPANDED_TABLE_HEIGHT = 350;
const DEFAULT_PADDING_PANEL = 24;

export interface IVirtualized {
  height: number;
  width: number;
  itemSize: number;
}

const ROW_HEIGHT = 32;

export const useTableVirtualized = (
  element: HTMLElement | null,
): {
  virtualized: IVirtualized;
  updateTableSizes: () => void;
} => {
  const [tableHeight, setTableHeight] = useState(MIN_EXPANDED_TABLE_HEIGHT);
  const [tableWidth, setTableWidth] = useState(0);

  const updateTableSizes = useCallback(() => {
    if (!element) {
      return;
    }

    const headerRow = element.querySelector("[data-test-id='table__content']");

    if (!headerRow) {
      return;
    }

    const top = element.getBoundingClientRect().top;

    const possibleTableHeight =
      window.innerHeight -
      (top + window.scrollY) -
      headerRow.clientHeight -
      DEFAULT_PADDING_PANEL;

    setTableWidth(headerRow.clientWidth);
    setTableHeight(
      possibleTableHeight > MIN_EXPANDED_TABLE_HEIGHT
        ? possibleTableHeight
        : MIN_EXPANDED_TABLE_HEIGHT,
    );
  }, [element]);

  const virtualized = {
    height: tableHeight,
    width: tableWidth,
    itemSize: ROW_HEIGHT,
  };

  useEffect(() => {
    updateTableSizes();

    window.addEventListener('resize', updateTableSizes);
    window.addEventListener('load', updateTableSizes);

    return () => {
      window.removeEventListener('resize', updateTableSizes);
      window.removeEventListener('load', updateTableSizes);
    };
  }, [updateTableSizes]);

  return { virtualized, updateTableSizes };
};
