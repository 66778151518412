import { get, set } from 'lodash';

import { DEFAULT_OPTION_NULLABLE, REGEX_DOUBLE_COLON } from '~/constants';
import { transformDataToSelectOptions } from '~/shared/utils';
import { RowType } from '~/types/common';
import { IOption } from '~/types/form';
import {
  ILimitsResponseTree,
  ILimitTree,
  ILimitsSetResponse,
} from '~/types/limits';

import { ILimitsOptionsReduce } from './types';

export interface IConvertLimitsStructureFromFlatToTreeReturn {
  tree: ILimitTree[];
  positionByIdInTree: Record<string, string>;
}

export function convertLimitsStructureFromFlatToTree(
  items: ILimitsResponseTree[],
) {
  const tree: ILimitTree[] = [];
  const positionByIdInTree: Record<string, string> = {};

  const LIMITS = {
    mode: null,
    negativeLim: null,
    positiveLim: null,
    override: null,
  };

  items.forEach((item) => {
    const [parent, current] = item.path.split(REGEX_DOUBLE_COLON);

    // decided to hide the forex node on the front
    if (parent !== 'FOREX') {
      if (!current) {
        tree.push({
          ...item,
          ...LIMITS,
          rowType: RowType.Node,
          subRows: [],
        });
        positionByIdInTree[item.path] = String(tree.length - 1);
      } else {
        const subRows: ILimitTree[] = get(
          tree,
          `${positionByIdInTree[parent]}.subRows`,
          [],
        );
        subRows.push({
          ...item,
          ...LIMITS,
          rowType: RowType.Node,
          subRows: [],
        });
        positionByIdInTree[item.path] = `${
          positionByIdInTree[parent]
        }.subRows.${subRows.length - 1}`;
        set(tree, `${positionByIdInTree[parent]}.subRows`, subRows);
      }
    }
  });

  return { tree, positionByIdInTree };
}

export const prepareMode = (mode: string | boolean | null) => {
  if (typeof mode === 'boolean') {
    return mode ? 'upper_cap' : 'strict';
  }
  if (typeof mode === 'string') {
    return mode;
  }
  return 'strict';
};

export const transformLimitGroupsToOptions = ({
  data,
  isLoading,
  isFetching,
}: {
  data?: ILimitsSetResponse[];
  isLoading: boolean;
  isFetching: boolean;
}) => {
  if (!data) {
    return {
      data: [],
      isLoading,
      isFetching,
    };
  }

  const processedData = data.reduce<ILimitsOptionsReduce>(
    (acc, { isDefault, id, name }) => {
      if (isDefault) {
        acc.defaultOption = {
          value: String(id),
          label: DEFAULT_OPTION_NULLABLE.label,
        };
      } else {
        acc.transformData.push({ name, id });
      }
      return acc;
    },
    {
      defaultOption: {
        value: '',
        label: '',
      },
      transformData: [],
    },
  );

  return {
    data: [
      processedData.defaultOption,
      ...transformDataToSelectOptions(processedData?.transformData, {
        valueKey: 'id',
        labelKey: 'name',
        sort: true,
      }),
    ] as IOption[],
    isLoading,
    isFetching,
  };
};
