import * as Yup from 'yup';

import { FormMode } from './types';

export const getValidationSchema = (mode: FormMode) => {
  return Yup.object({
    applyExecutionScheme: Yup.boolean().nullable(),
    autoconversionByDefault: Yup.boolean().required(
      'Autoconversion by default is required',
    ),
    availableCurrencies: Yup.array().of(Yup.string()).nullable(),
    calcInterestsNegativeFlag: Yup.boolean().nullable(),
    calcInterestsPositiveFlag: Yup.boolean().nullable(),
    calcOvernightsFlag: Yup.boolean().nullable(),
    company: Yup.string().required('Company is required'),
    convertCashInto: Yup.string().nullable(),
    defaultAccountType: Yup.string().nullable(),
    forceAutoconversionCurrencies: Yup.array().of(Yup.string()).nullable(),
    forceConvertCashInto: Yup.string().nullable(),
    isDefault: Yup.boolean().required('Is default is required'),
    lei: Yup.string().nullable(),
    name:
      mode === FormMode.Add
        ? Yup.string().typeError('Name is required')
        : Yup.string(),
    order: Yup.number()
      .required('Order is required')
      .typeError('Should be a number'),
    underEMIR: Yup.boolean().required('Under EMIR is required'),
    useExcessMarginFee: Yup.boolean().nullable(),
    useFreemoneyPremium: Yup.boolean().nullable(),
  });
};
