import { useCallback } from 'react';
import { Select, Checkbox } from 'react-ui-kit-exante';

import { sendUiKitErrorNotification } from '~/shared/utils';
import { FilterLayers } from '~/types/symbolPermissions';

import { ICellContainerProps } from '../../types';

const OPTIONS = [
  { label: 'true', value: 'true' },
  { label: 'false', value: 'false' },
  { label: 'null', value: 'null' },
];

const SX_STYLES = {
  width: 150,
};

export const ShortCell = ({
  column,
  row,
  value,
  tableState,
}: ICellContainerProps) => {
  const { layer } = column.getProps();
  const disabled = [FilterLayers.Effective, FilterLayers.Users].includes(layer);

  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { handleCellClick } = column.getProps();

      try {
        handleCellClick({
          column,
          row,
          tableState,
          value:
            event.target.value === null ? null : JSON.parse(event.target.value),
          onChangeSource: true,
        });
      } catch (error) {
        sendUiKitErrorNotification((error as Error).toString());
      }
    },
    [column, row, tableState],
  );

  if ([FilterLayers.Groups, FilterLayers.Accounts].includes(layer)) {
    return (
      <div>
        <Select
          onChange={onChangeHandler}
          options={OPTIONS}
          size="small"
          sx={SX_STYLES}
          value={`${value}`}
        />
      </div>
    );
  }

  return <Checkbox checked={Boolean(value)} disabled={disabled} />;
};
