import { createAsyncThunk } from '@reduxjs/toolkit';

import { currenciesService } from '~/resources';
import { throwResponseError } from '~/shared/utils';
import { ICurrency } from '~/types/currencies';

export const fetchCurrencies = createAsyncThunk<
  ICurrency[],
  undefined,
  { rejectValue: string }
>('currencies/fetch', async (_, { rejectWithValue }) => {
  try {
    return await currenciesService.resolveCurrencies({
      onError: throwResponseError,
    });
  } catch (error) {
    return rejectWithValue((error as Error).message);
  }
});
