import { Dispatch } from '@reduxjs/toolkit';
import { IRowExpand } from 'react-ui-kit-exante';

import { expandedRowsUpdate } from '~/store/limits';
import { ILimitTree } from '~/types/limits';

export function closeRowAnsSubRows(
  row: IRowExpand<ILimitTree>,
  dispatch: Dispatch,
) {
  const { subRows } = row;

  row.toggleRowExpanded();

  dispatch(expandedRowsUpdate([row.id, false]));

  if (subRows.length) {
    subRows.forEach((subRow) => {
      if (subRow.isExpanded) {
        closeRowAnsSubRows(subRow, dispatch);
      }
    });
  }
}
