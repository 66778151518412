import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import {
  useCreateNonTradingCommissionGroupMutation,
  nonTradingCommissionsApi,
} from '~/api';
import { prepareCurrency } from '~/containers/CurrencySelectorContainer/helpers';
import { FormInputContainer } from '~/containers/form';
import { NON_TRADING_COMMISSIONS_ENTRY_SCREEN } from '~/routes';
import {
  transformVariantsToSelectOptions,
  replaceRouteVariable,
} from '~/shared/utils';
import { selectCurrencies } from '~/store/currencies';
import { selectGroupsByName } from '~/store/nonTradingCommissions/slice';

import { StyledButton, StyledFormSelect } from './AddGroup.styled';
import { getValidationSchema } from './validationSchema';

interface IAddGroupProps {
  closeTooltip: () => void;
}

interface IDefaultValues {
  name: string;
  currency: string;
}

export const AddGroup = ({ closeTooltip }: IAddGroupProps) => {
  const [createNonTradingCommissionGroup] =
    useCreateNonTradingCommissionGroupMutation();

  const groupsByName = useSelector(selectGroupsByName);
  const { data: currencies } = useSelector(selectCurrencies);

  const preparedCurrencies = useMemo(() => {
    return prepareCurrency(currencies);
  }, [currencies]);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const currenciesSelectOptions = useMemo(
    () =>
      transformVariantsToSelectOptions(
        preparedCurrencies.map((item) => item.currency),
      ),
    [preparedCurrencies],
  );

  const formInstance = useForm({
    defaultValues: {
      name: '',
      currency: '',
    },
    resolver: yupResolver(
      getValidationSchema(
        new Set(Object.keys(groupsByName).map((name) => name.toLowerCase())),
      ),
    ),
  });

  const onSubmitHandler = useCallback(
    async ({ name, currency }: IDefaultValues) => {
      try {
        const response = await createNonTradingCommissionGroup({
          name,
          currency,
        });

        if (!('error' in response)) {
          dispatch(
            nonTradingCommissionsApi.util.invalidateTags([
              'NonTradingCommissionByGroup',
              'NonTradingCommissionGroups',
              'NonTradingCommissions',
            ]),
          );

          Notification.success({
            title: 'Group has been added',
          });

          navigate(
            replaceRouteVariable(
              NON_TRADING_COMMISSIONS_ENTRY_SCREEN,
              response.data.id,
            ),
          );
        } else {
          Notification.error({
            title: 'Error adding group',
          });
        }
      } catch (error) {
        Notification.error({
          title: 'Error adding group',
        });
      }
    },
    [dispatch, navigate, createNonTradingCommissionGroup],
  );

  const submitHandle = formInstance.handleSubmit(onSubmitHandler);

  return (
    <FormProvider {...formInstance}>
      <form onSubmit={submitHandle}>
        <FormInputContainer name="name" label="Name" />

        <StyledFormSelect
          name="currency"
          label="Currency"
          options={currenciesSelectOptions}
        />

        <StyledButton type="submit">Add</StyledButton>
        <StyledButton type="button" onClick={closeTooltip}>
          Cancel
        </StyledButton>
      </form>
    </FormProvider>
  );
};
