import { createSlice } from '@reduxjs/toolkit';
import { clone, cloneDeep, get, groupBy, mapValues, set, unset } from 'lodash';

import { limitsApi } from '~/api';
import { REGEX_LAST_SEGMENT_IN_PATH } from '~/constants';
import { deepSet, getAllPathsSet } from '~/shared/utils';
import {
  DEFAULT_CHANGED_LIMITS,
  INITIAL_STATE,
} from '~/store/limits/constants';
import { getQueryParams } from '~/store/limits/helpers.ts';
import { formatPercentage } from '~/store/overnights/helpers';
import { RowType } from '~/types/common';
import { ILimitTree } from '~/types/limits';
import type { TApplicationState } from '~/types/store';

interface IUpdateNodeValuePayload {
  payload: {
    path: string;
    value: number | boolean;
    column: string;
  };
}

export const limitsSlice = createSlice({
  name: 'limits',
  initialState: INITIAL_STATE,
  reducers: {
    expandedRowsSet: (state, { payload }) => {
      const [expanded, id, value] = payload;

      state.table.expandedRows = {
        ...expanded,
        [id]: value,
      };
    },
    expandedRowsUpdate: (state, { payload }) => {
      const [id, value] = payload;

      const expandedRows = clone(state.table.expandedRows);
      const newExpandedRows =
        typeof expandedRows === 'boolean'
          ? expandedRows
          : {
              ...expandedRows,
              [id]: value,
            };

      state.table.expandedRows = newExpandedRows;
    },
    filtersSetGroup: (state, { payload }) => {
      const { value } = payload;

      const select = {
        ...state.filters.select,
        group: value,
      };
      const queryParams = getQueryParams({
        ...select,
        relatedGroup: state.filters.relatedGroup,
      });

      state.filters.select = select;
      state.filters.queryParams = queryParams;
    },
    filtersSetLayer: (state, { payload }) => {
      const select = {
        layer: payload,
        account: null,
        group: undefined,
      };
      const queryParams = getQueryParams({ ...select, relatedGroup: null });

      state.filters.select = select;
      state.filters.queryParams = queryParams;
      state.filters.relatedGroup = null;
    },
    filtersSetAccount: (state, { payload }) => {
      const select = {
        ...state.filters.select,
        account: payload,
      };
      const queryParams = getQueryParams({
        ...select,
        relatedGroup: null,
      });

      state.filters.relatedGroup = null;
      state.filters.select = select;
      state.filters.queryParams = queryParams;
    },
    filtersSetRelatedGroup: (state, { payload }) => {
      const fixedPayload = payload === 'null' ? null : payload;

      const select = {
        ...state.filters.select,
        group: fixedPayload,
      };
      const queryParams = getQueryParams({
        ...select,
        relatedGroup: fixedPayload,
      });

      state.filters.relatedGroup = fixedPayload;
      state.filters.select = select;
      state.filters.queryParams = queryParams;
    },
    resetTable: (state) => {
      state.table.expandedRows = {};
      state.table.tree = cloneDeep(state.table.defaultTree);
      state.table.positionByIdInTree = cloneDeep(
        state.table.defaultPositionByIdInTree,
      );
      state.search.isActive = false;
      state.search.tree = [];
      state.changedLimits = DEFAULT_CHANGED_LIMITS;
    },
    updateInstrumentValue: (state, { payload }) => {
      const { path, value, column } = payload;

      const row = get(
        state[state.search.isActive ? 'search' : 'table'].tree,
        state.table.positionByIdInTree[path],
      );

      row[column] = value;

      state.changedLimits.instruments[path] = row;
    },
    updateNodeValue: (state, { payload }: IUpdateNodeValuePayload) => {
      const { path, value, column } = payload;

      const row: ILimitTree = get(
        state.table.tree,
        state.table.positionByIdInTree[path],
      );

      row[column] = value;

      row.subRows?.forEach((subRow) => {
        subRow[column] = value;

        if (subRow.rowType === RowType.Instrument) {
          state.changedLimits.instruments[subRow.path] = subRow;
        }
      });
    },
    resetChangedLimits: (state) => {
      state.changedLimits = DEFAULT_CHANGED_LIMITS;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      limitsApi.endpoints.getLimitsTree.matchFulfilled,
      (state, { payload }) => {
        state.table.defaultTree = cloneDeep(payload.tree);
        state.table.tree = cloneDeep(payload.tree);
        state.table.positionByIdInTree = cloneDeep(payload.positionByIdInTree);
        state.table.defaultPositionByIdInTree = cloneDeep(
          payload.positionByIdInTree,
        );
        state.table.expandedRows = {};
      },
    );
    builder.addMatcher(
      limitsApi.endpoints.getLimitsInstruments.matchFulfilled,
      (state, { payload }) => {
        const { path, data: instruments } = payload;

        const oldInstruments = get(
          state.table.tree,
          `${state.table.positionByIdInTree[path]}.subRows`,
          [],
        );

        const newInstruments = [
          ...oldInstruments,
          ...instruments.map(({ limit, ...instrument }) => ({
            ...instrument,
            ...limit,
            rowType: 'instrument',
          })),
        ];

        deepSet(
          state.table.tree,
          `${state.table.positionByIdInTree[path]}.subRows`,
          newInstruments,
        );

        state.table.positionByIdInTree = {
          ...state.table.positionByIdInTree,
          ...newInstruments.reduce<Record<string, string>>(
            (acc, item, index) => {
              acc[
                item.path
              ] = `${state.table.positionByIdInTree[path]}.subRows.${index}`;
              return acc;
            },
            {},
          ),
        };
      },
    );
    builder.addMatcher(
      limitsApi.endpoints.searchLimitsInstruments.matchFulfilled,
      (state, { payload }) => {
        state.search.isActive = true;

        if (payload.isFinish) {
          const groupedInstrumentsByPath = groupBy(
            payload.instruments,
            (item) => item.path.replace(REGEX_LAST_SEGMENT_IN_PATH, ''),
          );

          const pathsWithInstruments: string[] = [];

          const tree = cloneDeep(state.table.defaultTree);

          const positionByIdInTreeForCalculate = clone(
            state.table.defaultPositionByIdInTree,
          );

          const positionByIdInTreeForState = clone(
            state.table.defaultPositionByIdInTree,
          );

          Object.entries(groupedInstrumentsByPath).forEach(
            ([path, instruments]) => {
              const position = positionByIdInTreeForCalculate[path];

              set(
                tree,
                `${position}.subRows`,
                instruments.map((instrument) =>
                  mapValues(instrument, (value, key) => {
                    if (key.startsWith('markup')) {
                      return formatPercentage(Number(value) || 0);
                    }

                    return value || 0;
                  }),
                ),
              );
              pathsWithInstruments.push(path);
              delete positionByIdInTreeForCalculate[path];

              instruments.forEach((instrument, index) => {
                positionByIdInTreeForState[
                  instrument.path
                ] = `${position}.subRows.${index}`;
              });
            },
          );

          const allPathsSet = getAllPathsSet(
            pathsWithInstruments,
            REGEX_LAST_SEGMENT_IN_PATH,
          );

          Object.entries(positionByIdInTreeForCalculate).forEach(
            ([path, position]) => {
              if (!allPathsSet.has(path)) {
                unset(tree, position);
              }
            },
          );

          state.search.tree = tree;
          state.table.expandedRows = true;
          state.table.positionByIdInTree = positionByIdInTreeForState;
        }
      },
    );
  },
});

export const selectLimitsTree = (state: TApplicationState) => {
  if (state.limits.search.isActive) {
    return state.limits.search.tree;
  }

  return state.limits.table.tree;
};

export const selectExpandedRows = (state: TApplicationState) =>
  state.limits.table.expandedRows;

export const selectFiltersGroup = (state: TApplicationState) =>
  state.limits.filters.select.group;

export const selectFiltersLayer = (state: TApplicationState) =>
  state.limits.filters.select.layer;

export const selectFiltersQueryParams = (state: TApplicationState) =>
  state.limits.filters.queryParams;

export const selectFiltersAccount = (state: TApplicationState) =>
  state.limits.filters.select.account;

export const selectSearchIsActive = (state: TApplicationState) =>
  state.limits.search.isActive;

export const selectChangedLimits = (state: TApplicationState) =>
  state.limits.changedLimits;

export const selectFiltersRelatedGroup = (state: TApplicationState) =>
  state.limits.filters.relatedGroup;

export const {
  expandedRowsSet,
  expandedRowsUpdate,
  filtersSetGroup,
  filtersSetLayer,
  resetTable,
  filtersSetAccount,
  filtersSetRelatedGroup,
  updateInstrumentValue,
  updateNodeValue,
  resetChangedLimits,
} = limitsSlice.actions;
