import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, Loader, Notification } from 'react-ui-kit-exante';

import { useImportTransactionMutation } from '~/api';
import {
  useAttachCSV,
  useBack,
  useCurrentUserHasAllBrandingPermission,
  useDeepCompareEffect,
} from '~/hooks';
import { TRANSACTIONS_PATH } from '~/routes';
import {
  getInputFileText,
  getRowPropsHelperTransactions,
} from '~/shared/utils/bulkImport';
import {
  TTransactionCSV,
  TTransactionImportColumn,
} from '~/types/transactions';

import { BulkTransactionCheckbox, TransactionImport } from './components';
import {
  BULK_MANUAL_TRANSACTION,
  COLUMNS_CONFIG,
  defaultValues,
} from './constants';
import { getValidationSchema } from './validationSchema';

export const TransactionImportPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [errorIndexes, setErrorIndexes] = useState<Record<number, boolean>>({});
  const [uploadedSuccessfully, setUploadedSuccessfully] = useState(false);
  const { tableData, columns, handleInputFile, duplicateIndexes } =
    useAttachCSV<TTransactionImportColumn, TTransactionCSV>(COLUMNS_CONFIG);
  const displayTable = Boolean(columns.length);
  const inputFileText = useMemo(
    () => getInputFileText(displayTable, uploadedSuccessfully, hasErrors),
    [displayTable, hasErrors, uploadedSuccessfully],
  );
  const [importTransaction] = useImportTransactionMutation();
  const { isLoadingUser, currentUserHasAllBrandingPermission } =
    useCurrentUserHasAllBrandingPermission();
  const navigate = useNavigate();

  const validationSchema = useMemo(() => getValidationSchema(), []);
  const formInstance = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { reset, getValues } = formInstance;

  const handleUpload = useCallback(async () => {
    if (isLoading) {
      return;
    }

    const { bulkManualTransaction } = getValues();

    setIsLoading(true);

    const preparedParams = {
      transactions: tableData,
      bulkManualTransaction,
    };

    const response = await importTransaction(preparedParams);

    if ('error' in response) {
      setIsLoading(false);
      setHasErrors(true);
      setUploadedSuccessfully(false);

      return;
    }

    reset();
    setIsLoading(false);

    setHasErrors(false);
    setUploadedSuccessfully(true);
  }, [isLoading, getValues, importTransaction, reset, tableData]);

  const additionalActions = useMemo(
    () =>
      uploadedSuccessfully || hasErrors
        ? undefined
        : [
            {
              key: BULK_MANUAL_TRANSACTION,
              component: <BulkTransactionCheckbox />,
            },
            {
              key: 'upload',
              children: (
                <Button disabled={isLoading} size="small">
                  Upload transactions
                </Button>
              ),
              onClick: handleUpload,
            },
          ],
    [isLoading, handleUpload, hasErrors, uploadedSuccessfully],
  );

  const getRowProps = useCallback(
    ({ index }: { index: number }) =>
      getRowPropsHelperTransactions(
        errorIndexes,
        hasErrors,
        index,
        uploadedSuccessfully,
      ),
    [errorIndexes, hasErrors, uploadedSuccessfully],
  );

  const wrappedHandleInputFile = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setHasErrors(false);
    setErrorIndexes({});
    setUploadedSuccessfully(false);
    handleInputFile(event);
  };

  const handleCloseImport = useBack({ defaultPath: TRANSACTIONS_PATH });

  useEffect(() => {
    if (!isLoadingUser && !currentUserHasAllBrandingPermission) {
      navigate('/', {
        state: { previousPath: window.location.href },
      });
    }
  }, [isLoadingUser, navigate, currentUserHasAllBrandingPermission]);

  useDeepCompareEffect(() => {
    if (duplicateIndexes.length) {
      const startWarningString =
        duplicateIndexes.length === 1
          ? `Row ${duplicateIndexes[0]} has duplicate`
          : `Rows ${duplicateIndexes.join(', ')} has duplicates`;

      Notification.warning({
        title: `${startWarningString} that have been removed`,
      });
    }
  }, [duplicateIndexes]);

  if (isLoadingUser) {
    return <Loader />;
  }

  return (
    <FormProvider {...formInstance}>
      <TransactionImport
        additionalActions={additionalActions}
        columns={columns}
        displayTable={displayTable}
        getRowProps={getRowProps}
        inputFileText={inputFileText}
        isLoading={isLoading}
        onClose={handleCloseImport}
        onInputFile={wrappedHandleInputFile}
        tableData={tableData}
      />
    </FormProvider>
  );
};
