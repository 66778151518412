import { useCallback, useEffect } from 'react';
import { useData } from 'react-ui-kit-exante';

import { legalEntitiesService } from '~/resources';

export function useLegalEntities(preCall: () => void) {
  const getLegalEntityDetails = useCallback(async () => {
    preCall();

    const result = await legalEntitiesService.resolveLegalEntityDetails();

    return result;
  }, [preCall]);

  const { data, fetchData, isLoading } = useData({
    onFetch: getLegalEntityDetails,
  });

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { tableData: data, isLoading };
}
