import { useCallback, useEffect } from 'react';
import { useData } from 'react-ui-kit-exante';

import { EMPTY_ARRAY } from '~/constants';
import { exanteCounterpartyService } from '~/resources';
import { IOption } from '~/types/form';

interface IUseExternalCounterpartyOptions {
  externalCounterpartyOptions: IOption[];
  isExternalCounterpartyLoading: boolean;
}

export function useExternalCounterpartyOptions(): IUseExternalCounterpartyOptions {
  const getExternalCounterpartyOptions = useCallback(async () => {
    const result =
      await exanteCounterpartyService.resolveExanteCounterPartyOptions();

    return result;
  }, []);

  const {
    fetchData: fetchExternalCounterparty,
    isLoading: isExternalCounterpartyLoading,
    data: externalCounterpartyOptions,
  } = useData({
    onFetch: getExternalCounterpartyOptions,
  });

  useEffect(() => {
    fetchExternalCounterparty();
  }, [fetchExternalCounterparty]);

  return {
    externalCounterpartyOptions: externalCounterpartyOptions || EMPTY_ARRAY,
    isExternalCounterpartyLoading,
  };
}
