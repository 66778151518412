import { FilterValue, ICellValue, IColumn } from 'react-ui-kit-exante';

import { toFixed, transformVariantsToSelectOptions } from '~/shared/utils';
import { IAccount } from '~/types/accounts';
import { IOption } from '~/types/form';

export const DISPLAYED_COLUMNS_KEYS: (keyof IAccount)[] = [
  'id',
  'accountOwner',
  'type',
  'created',
  'internalComment',
];

interface IGetColumns {
  onFilter(col: string, value: FilterValue): void;
  onRemove(col: string): void;
  types: {
    legalEntityTypes: string[];
    ownershipTypes: string[];
    accountStatusTypes: string[];
    accountTypes: string[];
    accountPurposes: string[];
  };
  currencies: string[];
  legalEntitiesOptions: IOption[];
  brandingList: IOption[];
}

export function getColumns({
  onFilter,
  onRemove,
  types: {
    accountTypes,
    ownershipTypes,
    accountPurposes,
    legalEntityTypes,
    accountStatusTypes,
  },
  currencies,
  brandingList,
}: IGetColumns): IColumn<IAccount>[] {
  return [
    {
      Header: 'Id',
      accessor: 'id',
      onFilter,
      onRemove,
      required: true,
      onToday: onFilter,
    },
    {
      Header: 'Account owner',
      accessor: 'accountOwner',
      onFilter,
      onRemove,
      disableSortBy: true,
    },
    {
      Header: 'Created',
      accessor: 'created',
      type: 'dateRange',
      onFilter,
      onToday: onFilter,
      onYesterday: onFilter,
      onLastWeek: onFilter,
      onRemove,
      sortDescFirst: true,
      formatting: 'dateUTC',
    },
    {
      Header: 'Internal Comment',
      accessor: 'internalComment',
      onFilter,
      onRemove,
      disableSortBy: true,
    },
    {
      Header: 'Account Purpose',
      accessor: 'accountPurpose',
      type: 'multiSelect',
      filterOptions: transformVariantsToSelectOptions(accountPurposes),
      onFilter,
      onRemove,
    },
    {
      Header: 'Archived',
      accessor: 'archived',
      type: 'checkbox',
      onFilter,
      onRemove,
    },
    {
      Header: 'Auto liquidation allowed',
      accessor: 'autoLiquidationAllowed',
      type: 'checkbox',
      onFilter,
      onRemove,
    },
    {
      Header: 'Available currencies',
      accessor: 'availableCurrencies',
      type: 'multiSelect',
      filterOptions: transformVariantsToSelectOptions(currencies),
      onFilter,
      onRemove,
    },
    {
      Header: 'Branding',
      accessor: 'branding',
      disableSortBy: true,
      type: 'select',
      filterOptions: brandingList,
      onFilter,
      onRemove,
    },
    {
      Header: 'Calc interests negative flag',
      accessor: 'calcInterestsNegativeFlag',
      type: 'checkbox',
      onFilter,
      onRemove,
    },
    {
      Header: 'Calc interests positive flag',
      accessor: 'calcInterestsPositiveFlag',
      type: 'checkbox',
      onFilter,
      onRemove,
    },
    {
      Header: 'Calc overnights flag',
      accessor: 'calcOvernightsFlag',
      type: 'checkbox',
      onFilter,
      onRemove,
    },
    {
      Header: 'Client id',
      accessor: 'clientId',
      onFilter,
      onRemove,
    },
    {
      Header: 'Legal Entity',
      accessor: 'clientLegalEntity',
      onFilter,
      onRemove,
      disableSortBy: true,
      type: 'multiSelect',
      filterOptions: transformVariantsToSelectOptions(legalEntityTypes),
    },
    {
      Header: 'Client name',
      accessor: 'clientName',
      onFilter,
      onRemove,
    },
    {
      Header: 'Client code',
      accessor: 'clientCode',
      onFilter,
      onRemove,
    },
    {
      Header: 'Override Account Owner',
      accessor: 'overrideAccountOwner',
      onFilter,
      onRemove,
    },
    {
      Header: 'Override Client Name',
      accessor: 'overrideClientName',
      onFilter,
      onRemove,
    },
    { Header: 'Comment', accessor: 'comment', onFilter, onRemove },
    {
      Header: 'Commission group id',
      accessor: 'commissionGroupId',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Mirroring level',
      accessor: 'mirroringLevel',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Overnights set id',
      accessor: 'overnightsSetId',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Ownership type',
      accessor: 'ownershipType',
      type: 'select',
      filterOptions: transformVariantsToSelectOptions(ownershipTypes),
      onFilter,
      onRemove,
    },
    {
      Header: 'Permission group id',
      accessor: 'permissionGroupId',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      type: 'select',
      filterOptions: transformVariantsToSelectOptions(accountStatusTypes),
      onFilter,
      onRemove,
    },
    {
      Header: 'Sub owner description',
      accessor: 'subOwnerDescription',
      onFilter,
      onRemove,
    },
    {
      Header: 'Type',
      accessor: 'type',
      type: 'select',
      filterOptions: transformVariantsToSelectOptions(accountTypes),
      onFilter,
      onRemove,
    },
    {
      Header: 'Excess margin fee',
      accessor: 'useExcessMarginFee',
      type: 'checkbox',
      onFilter,
      onRemove,
    },
    {
      Header: 'Free money premium',
      accessor: 'useFreemoneyPremium',
      type: 'checkbox',
      onFilter,
      onRemove,
    },
    {
      Header: 'Total order margin, EUR',
      accessor: 'totalOrderMargin',
      type: 'numberRange',
      formatting: 'number',
      onFilter,
      onRemove,
    },
    {
      Header: 'Total free money, EUR',
      accessor: 'totalFreeMoney',
      type: 'numberRange',
      formatting: 'number',
      onFilter,
      onRemove,
    },
    {
      Header: 'Total cash, EUR',
      accessor: 'totalCash',
      type: 'numberRange',
      formatting: 'number',
      onFilter,
      onRemove,
    },
    {
      Header: 'Total blocked margin, EUR',
      accessor: 'totalBlockedMargin',
      type: 'numberRange',
      formatting: 'number',
      onFilter,
      onRemove,
    },
    {
      Header: 'Share price, EUR',
      accessor: 'sharePrice',
      type: 'numberRange',
      formatting: 'number',
      onFilter,
      onRemove,
    },
    {
      Header: 'Required margin, EUR',
      accessor: 'requiredMargin',
      type: 'numberRange',
      formatting: 'number',
      onFilter,
      onRemove,
    },
    {
      Header: 'Net asset value, EUR',
      accessor: 'netAssetValue',
      type: 'numberRange',
      formatting: 'number',
      onFilter,
      onRemove,
    },
    {
      Header: 'Margin utilization, %',
      accessor: 'marginUtilization',
      type: 'numberRange',
      onFilter,
      onRemove,
      Cell: ({ row: { values } }: ICellValue<IAccount>) =>
        toFixed(values.marginUtilization),
    },
  ];
}
