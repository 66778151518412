import { ChangeEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';
import { AutocompleteAsync, Select } from 'react-ui-kit-exante';

import {
  useLazyGetGroupSettingsQuery,
  useGetLimitGroupsQuery,
  useLazySearchByAccountsQuery,
} from '~/api';
import { transformLimitGroupsToOptions } from '~/api/limits/helpers';
import { useAutocomplete } from '~/hooks';
import { FiltersWrapper } from '~/shared/components';
import {
  filtersSetAccount,
  filtersSetGroup,
  filtersSetRelatedGroup,
  filtersSetLayer,
  resetTable,
  selectFiltersAccount,
  selectFiltersGroup,
  selectFiltersLayer,
} from '~/store/limits';
import { FilterLayers } from '~/types/limits';

interface IFiltersProps {
  disabled?: boolean;
}

export const Filters = ({ disabled }: IFiltersProps) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const filtersLayer = useSelector(selectFiltersLayer);
  const filtersAccount = useSelector(selectFiltersAccount);
  const filtersGroup = useSelector(selectFiltersGroup);
  const getAccountsAutocompleteFn = useAutocomplete('accounts');
  const location = useLocation();
  const [fetchGroupSettings] = useLazyGetGroupSettingsQuery();
  const [getAccounts] = useLazySearchByAccountsQuery();

  const predefinedAccount = location?.state?.accountId;

  const initAccount = useRef(false);

  const onChangeAccountHandler = useCallback(
    (_: unknown, data: { value: string }) => {
      const value = data?.value;
      if (value) {
        dispatch(resetTable());
        dispatch(filtersSetAccount(value));
      }
    },
    [dispatch],
  );

  const {
    data: groups,
    isLoading: loadingGroups,
    isFetching: fetchingGroups,
  } = useGetLimitGroupsQuery(undefined, {
    selectFromResult: transformLimitGroupsToOptions,
  });

  const groupOptions = useMemo(
    () => (filtersLayer === FilterLayers.Groups ? groups.slice(1) : groups),
    [filtersLayer, groups],
  );

  const onChangeGroupHandler = useCallback(
    async ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      dispatch(resetTable());
      dispatch(
        filtersSetGroup({
          value,
        }),
      );
    },
    [dispatch],
  );

  const getAccountsLimitsGroup = useCallback(
    async (account: string) => {
      const response = await fetchGroupSettings({
        accountId: account,
      });

      if (!('error' in response)) {
        dispatch(filtersSetRelatedGroup(response.data?.limitsSetId));
      }
    },
    [dispatch, fetchGroupSettings],
  );

  const setDefaultGroup = useCallback(() => {
    if (!filtersGroup && groupOptions.length) {
      if (filtersLayer === FilterLayers.Groups) {
        dispatch(
          filtersSetGroup({
            value: groupOptions[0].value,
            shouldResetDownloadedPaths: false,
          }),
        );
      } else if (filtersLayer === FilterLayers.Accounts) {
        const [defaultGroup] = groupOptions;

        dispatch(
          filtersSetRelatedGroup({
            value: defaultGroup.value,
            shouldResetDownloadedPaths: false,
          }),
        );
      }
    }
  }, [dispatch, filtersGroup, filtersLayer, groupOptions]);

  const setDefaultAccount = useCallback(async () => {
    const response = await getAccounts({
      search: '',
      skip: 0,
      limit: 1,
      archived: false,
    });

    const defaultId = response?.data?.options[0]?.value;

    if (defaultId) {
      onChangeAccountHandler(null, { value: predefinedAccount || defaultId });
    }
  }, [getAccounts, onChangeAccountHandler, predefinedAccount]);

  const onChangeLayerHandler = useCallback(
    (_e: unknown, value: string) => {
      dispatch(resetTable());
      dispatch(filtersSetLayer(value));
    },
    [dispatch],
  );

  useEffect(() => {
    if (predefinedAccount) {
      setDefaultAccount();
      onChangeLayerHandler(null, FilterLayers.Accounts);
    }
  }, []);

  useEffect(() => {
    if (filtersLayer === FilterLayers.Groups) {
      setDefaultGroup();
    }
  }, [filtersAccount, filtersLayer, setDefaultGroup]);

  useEffect(() => {
    if (filtersLayer === FilterLayers.Accounts && filtersAccount) {
      getAccountsLimitsGroup(filtersAccount);
    }
  }, [filtersAccount, filtersLayer, getAccountsLimitsGroup]);

  useEffect(() => {
    if (filtersLayer !== FilterLayers.Accounts) {
      initAccount.current = false;
    }
  }, [filtersLayer]);

  useEffect(() => {
    const initialAccount = searchParams.get('account');

    if (initialAccount) {
      onChangeAccountHandler(null, { value: initialAccount });
    } else if (
      !initialAccount &&
      [FilterLayers.Accounts].includes(filtersLayer)
    ) {
      setDefaultAccount();
    }
  }, [filtersLayer]);

  return (
    <FiltersWrapper>
      {filtersLayer === FilterLayers.Accounts && (
        <AutocompleteAsync
          fetchData={getAccountsAutocompleteFn()}
          onChange={onChangeAccountHandler}
          options={[]}
          placeholder="Account"
          value={filtersAccount}
          disabled={disabled}
        />
      )}

      {(filtersLayer === FilterLayers.Accounts ||
        filtersLayer === FilterLayers.Groups) && (
        <Select
          disabled={loadingGroups || fetchingGroups || disabled}
          fullWidth
          label="Group"
          onChange={onChangeGroupHandler}
          options={groupOptions}
          placeholder="Group"
          value={`${filtersGroup}`}
        />
      )}
    </FiltersWrapper>
  );
};
