import { useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Skeleton } from 'react-ui-kit-exante';

import {
  useAddGroupLimitsMutation,
  useDeleteGroupLimitsMutation,
  useGetLimitGroupsQuery,
  useLazySearchLimitsInstrumentsQuery,
} from '~/api';
import { useTableVirtualized } from '~/hooks';
import { LayersLayout } from '~/shared/components/LayersLayout';
import { Switch } from '~/shared/components/Switch';
import { WrapperLoader } from '~/shared/components/WrapperLoader';
import {
  filtersSetLayer,
  resetTable,
  selectFiltersLayer,
} from '~/store/limits';
import { FilterLayers } from '~/types/limits';

import { TableComponent } from './components';
import { Filters } from './components/Filters';
import {
  useGroupActions,
  useLimitsTree,
  useSaveData,
  useSearch,
} from './hooks';

export const Limits = () => {
  const tableRef = useRef<HTMLDivElement | null>(null);
  const LAYERS_OPTIONS = Object.values(FilterLayers);

  const dispatch = useDispatch();
  const [, { isLoading: loadingAddGroup }] = useAddGroupLimitsMutation();
  const [, { isLoading: loadingDeleteGroup }] = useDeleteGroupLimitsMutation();
  const [, searchState] = useLazySearchLimitsInstrumentsQuery();
  const { isLoading: loadingGroups, isFetching: fetchingGroups } =
    useGetLimitGroupsQuery();

  const filtersLayer = useSelector(selectFiltersLayer);

  const { fetchLimitsTree, isLoading: isLoadingTree } = useLimitsTree();

  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamsProp = useSearch();

  const handleSaveData = useSaveData();

  const { handleAddGroup, handleDeleteGroup } = useGroupActions();

  const isLoadingTable =
    isLoadingTree || searchState.isLoading || searchState.isFetching;

  const onChangeLayerHandler = useCallback(
    (value: string) => {
      dispatch(resetTable());
      dispatch(filtersSetLayer(value));

      setSearchParams((params) => {
        if (!value) {
          params.delete('layer');
        } else {
          params.set('layer', value);
        }

        return params;
      });
    },
    [setSearchParams, dispatch],
  );

  const handleOnRefresh = () => {
    fetchLimitsTree();
    dispatch(resetTable());
  };

  const { virtualized } = useTableVirtualized(tableRef.current);

  const groupsPending = loadingGroups || fetchingGroups;

  useEffect(() => {
    const initialLayer = searchParams.get('layer');
    if (initialLayer) {
      onChangeLayerHandler(initialLayer);
    }
  }, []);

  useEffect(() => {
    fetchLimitsTree();
  }, []);

  return (
    <LayersLayout
      initialTab={searchParams.get('layer') || null}
      loading={isLoadingTable}
      onAddGroup={handleAddGroup}
      onChangeLayer={onChangeLayerHandler}
      onDeleteGroup={handleDeleteGroup}
      onRefresh={handleOnRefresh}
      onSave={handleSaveData}
      pageControls={
        filtersLayer !== 'Default' ? (
          <Switch condition={[groupsPending, !groupsPending]}>
            <Skeleton width={280} height={76} />
            <Filters disabled={loadingAddGroup || loadingDeleteGroup} />
          </Switch>
        ) : null
      }
      searchParams={searchParamsProp}
      tabs={LAYERS_OPTIONS}
      table={
        <WrapperLoader isLoading={isLoadingTable} ref={tableRef}>
          <TableComponent virtualized={virtualized} />
        </WrapperLoader>
      }
      title="Limits"
    />
  );
};
