import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'react-ui-kit-exante';

import { legalEntitiesService } from '~/resources';
import { LEGAL_ENTITIES_PATH } from '~/routes';

export function useDelete(name: string | undefined) {
  const navigate = useNavigate();
  const modal = useModal();

  const closeHandle = useCallback(() => {
    navigate(LEGAL_ENTITIES_PATH, {
      state: {
        previousPath: window.location.href,
      },
    });
  }, [navigate]);

  const deleteHandle = useCallback(async () => {
    if (name) {
      const result = await legalEntitiesService.deleteLegalEntity(name);

      if (result) {
        closeHandle();
      }
    }
  }, [closeHandle, name]);

  return {
    modal,
    closeHandle,
    deleteHandle,
  };
}
