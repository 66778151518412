import { FETCH_PROXY_AUTH_DB, PROXY_CORE_PATH } from '~/shared/utils';

export const getUserTokensEndpoint = (userId: number, tokenId?: number) => {
  if (tokenId) {
    return `${FETCH_PROXY_AUTH_DB}2.1/auth/user/${userId}/tokens/${tokenId}`;
  }

  return `${FETCH_PROXY_AUTH_DB}2.1/auth/user/${userId}/tokens`;
};

export const CREATE_USER = `${FETCH_PROXY_AUTH_DB}1.0/auth/user`;

export const FETCH_MASTER_USERS = `${FETCH_PROXY_AUTH_DB}1.0/auth/user_bindings`;
export const FETCH_SUB_USERS = `${FETCH_PROXY_AUTH_DB}1.0/auth/user_bindings`;
export const FETCH_USERS = '/api/users/bo';
export const FETCH_USER_CLIENTS = `${PROXY_CORE_PATH}v2.0/user_client`;
export const FETCH_USER_CURRENT_LEGAL_ENTITIES = `${FETCH_USERS}/current/legal-entities`;
export const FETCH_USER_BRANDINGS = `${FETCH_USERS}/current/brandings`;

export const getUserByIdAPI = (id: number | string) =>
  `${FETCH_PROXY_AUTH_DB}2.1/auth/user/${id}`;

export const getUserPermissionsSetsEndpoint = (id?: number) => {
  const basePath = `${PROXY_CORE_PATH}v2.0/user_permissions_sets`;
  return id ? `${basePath}/${id}` : basePath;
};

export const getUserPermissionsEndpoint = (id: string) =>
  `${PROXY_CORE_PATH}v2.0/users/${id}/permissions`;

export const getUserAuthflowEndpoint = (userId: number) =>
  `${FETCH_PROXY_AUTH_DB}2.1/auth/user/${userId}/authflow`;
