import { SerializedError } from '@reduxjs/toolkit';
import { MutableRefObject } from 'react';

import { sendUiKitErrorNotification } from '~/shared/utils';
import { EMPTY_SERVICE_RESPONSE } from '~/shared/utils/services';
import { IServiceResponse } from '~/types/api';
import { TFormHandlerNew } from '~/types/form';
import { TDispatch } from '~/types/store';
import {
  ICoreFormUser,
  IFormMasterUser,
  IFormSubUser,
  IFormUserClient,
  TPossibleAccessRights,
  ListAccessTypes,
} from '~/types/users';

import { TFormData } from '../types';

interface ICreateHandlersMapArguments {
  dispatch: TDispatch;
  user?: ICoreFormUser | null;
  previousUserClients: MutableRefObject<IFormUserClient[] | undefined>;
  previousSubUsers: MutableRefObject<IFormSubUser[] | undefined>;
  previousMasterUsers: MutableRefObject<IFormMasterUser[] | undefined>;
  userPermissions: Record<ListAccessTypes, TPossibleAccessRights>;
  updateUser: (params: {
    id: number;
    data: ICoreFormUser;
  }) => Promise<
    | { data: IServiceResponse<ICoreFormUser> }
    | { error: string | SerializedError }
  >;
  updateUserClients: (params: {
    userClients: IFormUserClient[];
    previousUserClients: MutableRefObject<IFormUserClient[]>;
  }) => Promise<
    | { data: IServiceResponse<IFormUserClient[]> }
    | { error: string | SerializedError }
  >;
  updateSubUsers: (params: {
    userName: string;
    subUsers: IFormSubUser[];
    previousSubUsers: MutableRefObject<IFormSubUser[]>;
  }) => Promise<
    | { data: IServiceResponse<IFormSubUser[]> }
    | { error: string | SerializedError }
  >;
  updateMasterUsers: (params: {
    userName: string;
    masterUsers: IFormMasterUser[];
    previousMasterUsers: MutableRefObject<IFormMasterUser[]>;
  }) => Promise<
    | { data: IServiceResponse<IFormMasterUser[]> }
    | { error: string | SerializedError }
  >;
  updateUserGroupSettings: (params: {
    userName: string;
    permissionsSetId: number | null;
  }) => Promise<
    | {
        data: IServiceResponse<{ permissionsSetId: number }>;
      }
    | { error: string | SerializedError }
  >;
}

export const createHandlersMap = ({
  user,
  previousUserClients,
  previousSubUsers,
  previousMasterUsers,
  userPermissions,
  updateUser,
  updateUserClients,
  updateSubUsers,
  updateMasterUsers,
  updateUserGroupSettings,
}: ICreateHandlersMapArguments): TFormHandlerNew<TFormData> | null => {
  if (!user) {
    return null;
  }

  return {
    user: (data) => {
      if (!data) {
        return Promise.resolve(EMPTY_SERVICE_RESPONSE);
      }

      try {
        const { notifications, originatorIds, ...restDataInfo } = data.info;
        return updateUser({
          id: data.id,
          data: {
            ...data,
            info: {
              ...restDataInfo,
              notifications: JSON.parse(notifications),
              ...(userPermissions['User info: originator ids'].write && {
                originatorIds,
              }),
            },
          },
        });
      } catch (error) {
        sendUiKitErrorNotification(error);

        return Promise.resolve(EMPTY_SERVICE_RESPONSE);
      }
    },
    userClients: async (data) => {
      if (!data || !isIFormUserClient(previousUserClients)) {
        return EMPTY_SERVICE_RESPONSE;
      }

      const response = await updateUserClients({
        userClients: data,
        previousUserClients,
      });

      if ('error' in response) {
        return EMPTY_SERVICE_RESPONSE;
      }

      return response.data;
    },
    subUsers: async (data, allTabsData) => {
      if (
        !data ||
        !isISubUser(previousSubUsers) ||
        !allTabsData.user?.username
      ) {
        return EMPTY_SERVICE_RESPONSE;
      }

      const response = await updateSubUsers({
        userName: allTabsData.user.username,
        subUsers: data,
        previousSubUsers,
      });

      if ('error' in response) {
        return EMPTY_SERVICE_RESPONSE;
      }

      return response.data;
    },
    masterUsers: async (data, allTabsData) => {
      if (
        !data ||
        !isIMasterUser(previousMasterUsers) ||
        !allTabsData.user?.username
      ) {
        return EMPTY_SERVICE_RESPONSE;
      }

      const response = await updateMasterUsers({
        userName: allTabsData.user.username,
        masterUsers: data,
        previousMasterUsers,
      });

      if ('error' in response) {
        return EMPTY_SERVICE_RESPONSE;
      }

      return response.data;
    },
    groupSettings: async (data, allTabsData) => {
      if (data && allTabsData.user?.username) {
        const response = await updateUserGroupSettings({
          userName: allTabsData.user.username,
          permissionsSetId: data.permissionsSetId,
        });

        if ('error' in response) {
          return EMPTY_SERVICE_RESPONSE;
        }

        return response.data;
      }

      return EMPTY_SERVICE_RESPONSE;
    },
  };
};

function isIFormUserClient(
  value: ICreateHandlersMapArguments['previousUserClients'],
): value is MutableRefObject<IFormUserClient[]> {
  return Boolean(value.current);
}

function isISubUser(
  value: ICreateHandlersMapArguments['previousSubUsers'],
): value is MutableRefObject<IFormSubUser[]> {
  return Boolean(value.current);
}

function isIMasterUser(
  value: ICreateHandlersMapArguments['previousMasterUsers'],
): value is MutableRefObject<IFormMasterUser[]> {
  return Boolean(value.current);
}
