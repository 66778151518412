import { PROXY_CORE_PATH, getApiDomain } from '~/shared/utils';

export const FETCH_TRADES = '/api/trades';

export function getPostTradeEndpoint(
  accountId: string,
  shouldSendThroughWorkflowApi?: boolean,
) {
  if (shouldSendThroughWorkflowApi) {
    return `https://${getApiDomain('WFE')}/api/v2/run-workflow/manual_trade`;
  }

  return `${PROXY_CORE_PATH}v2.1/accounts/${accountId}/trade`;
}

export function getFetchCommissionEndpoint(accountId: string) {
  return `${PROXY_CORE_PATH}v2.0/accounts/${accountId}/commissions/calculate`;
}

export function getUpdateTradeEndpoint(orderId: string, orderPos: string) {
  return `${PROXY_CORE_PATH}v2.0/trades/${orderId}/${orderPos}`;
}

export const getRollbackTradeURL = (order_id: string, order_pos: number) =>
  `${PROXY_CORE_PATH}v2.1/trades/rollback/${order_id}/${order_pos}`;
