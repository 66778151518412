import { SESSION_ID_RUN_UI_AUDITLOG_KEY, EMPTY_AUDIT_LOGS } from '~/constants';
import {
  sendUiKitErrorNotification,
  getAccessTokenFromCookies,
  getSessionIdFromCookie,
} from '~/shared/utils';
import { getDefaultHeaders } from '~/shared/utils/apiRequest/helpers';
import {
  IFetchAuditLogResponse,
  TFetchAuditLogsParams,
} from '~/types/auditLogs';

import { FETCH_AUDIT_LOGS } from '../endpoints';
import { transformAuditLogErrorToValidError } from '../helpers';

import { auditLogApi } from './auditLogApi';

export const { useLazyGetAuditLogsQuery } = auditLogApi.injectEndpoints({
  endpoints: (builder) => ({
    getAuditLogs: builder.query<IFetchAuditLogResponse, TFetchAuditLogsParams>({
      queryFn: async (params, _, __, fetchWithBaseQuery) => {
        const { data, error } = await fetchWithBaseQuery({
          url: FETCH_AUDIT_LOGS,
          params,
          headers: getDefaultHeaders(
            window.runUIhistoryContainer
              ? getSessionIdFromCookie(SESSION_ID_RUN_UI_AUDITLOG_KEY)
              : process.env.REACT_APP_AUDITLOG_TOKEN,
            getAccessTokenFromCookies(),
          ),
        });

        if (error || !data) {
          sendUiKitErrorNotification(transformAuditLogErrorToValidError(error));

          return { data: EMPTY_AUDIT_LOGS };
        }

        return {
          data: {
            logs: data.logs,
            pagination: {
              total: data.total,
            },
          },
        };
      },
      providesTags: ['AuditLogs'],
    }),
  }),
});
