import { useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IColumn, IconButton } from 'react-ui-kit-exante';

import { EXPORT_TRANSACTIONS_PARAMS_TRANSFORMER } from '~/api/transactions/transactions.api.constants';
import { prepareFiltersToRequestTransactions } from '~/api/transactions/transactions.api.helpers';
import { prepareInitialSelectedColumns } from '~/containers/PositionsContainer/hooks/useSelectedColumns/helpers';
import {
  useCurrentUserHasAllBrandingPermission,
  useLocalStoragePolling,
  usePickUserPermissions,
} from '~/hooks';
import {
  TRANSACTION_ADD_PATH,
  TRANSACTION_IMPORT_PATH,
  TRANSFER_ADD_PATH,
} from '~/routes';
import { DownloadButton } from '~/shared/components';
import { paramsTransformer, updateAccountIdInParams } from '~/shared/utils';
import { createLinkToDownloadCSV } from '~/shared/utils/table';
import { ITransaction } from '~/types/transactions';

import { DISPLAYED_COLUMN_KEYS } from '../filters';
import { TGlobalFilters } from '../types';

export function useAdditionalActions(
  withAddButton: boolean,
  columns: IColumn<ITransaction>[],
  globalFilters: TGlobalFilters | undefined,
  filters: Record<string, unknown>,
) {
  const navigate = useNavigate();
  const { currentUserHasAllBrandingPermission } =
    useCurrentUserHasAllBrandingPermission();

  const goToAddTransactionPage = useCallback(() => {
    navigate(TRANSACTION_ADD_PATH, {
      state: { previousPath: window.location.href },
    });
  }, [navigate]);

  const goToAddTransferPage = useCallback(() => {
    navigate(TRANSFER_ADD_PATH, {
      state: { previousPath: window.location.href },
    });
  }, [navigate]);

  const goToBulkImport = useCallback(() => {
    navigate(TRANSACTION_IMPORT_PATH, {
      state: { previousPath: window.location.href },
    });
  }, [navigate]);

  const userPermissions = usePickUserPermissions([
    'Add/withdraw asset',
    'Transfer',
  ]);

  const selectedColumns = useLocalStoragePolling(
    'web-bo-transactions-table-columns',
  );

  return useMemo(() => {
    const actions = [];

    if (userPermissions.Transfer.write) {
      actions.push({
        component: (
          <IconButton
            iconName="AddIcon"
            iconColor="action"
            label="Add transfer"
            iconSize={24}
            onClick={goToAddTransferPage}
          />
        ),
      });
    }

    if (
      withAddButton &&
      userPermissions['Add/withdraw asset'].write &&
      currentUserHasAllBrandingPermission
    ) {
      actions.push({
        component: (
          <IconButton
            iconName="DownloadIcon"
            iconColor="action"
            label="Bulk import"
            iconSize={24}
            onClick={goToBulkImport}
          />
        ),
      });
    }

    if (
      userPermissions['Add/withdraw asset'].write &&
      currentUserHasAllBrandingPermission
    ) {
      actions.push({
        component: (
          <IconButton
            iconName="AddIcon"
            iconColor="action"
            label="Add transaction"
            iconSize={24}
            onClick={goToAddTransactionPage}
          />
        ),
      });
    }

    // TODO: to much steps to prepare params for request WBU-1062
    const preparedParamsStep1 = updateAccountIdInParams(
      {
        ...globalFilters,
        ...filters,
        limit: Infinity,
        fields: selectedColumns
          ? JSON.parse(selectedColumns).filter(
              (item: string) => item !== 'actions',
            )
          : Object.keys(
              prepareInitialSelectedColumns(
                columns,
                ['transactionsTable-id-columns'],
                DISPLAYED_COLUMN_KEYS,
              ),
            ).filter((column) => column),
      },
      'accountId',
    );

    const preparedStep2 =
      prepareFiltersToRequestTransactions(preparedParamsStep1);

    const resultParams = paramsTransformer({
      params: preparedStep2,
      mapper: EXPORT_TRANSACTIONS_PARAMS_TRANSFORMER,
    });

    actions.push({
      component: (
        <DownloadButton
          link={createLinkToDownloadCSV('/api/transactions/csv', resultParams)}
          fileName="transactions.csv"
        />
      ),
    });

    return actions;
  }, [
    columns,
    currentUserHasAllBrandingPermission,
    filters,
    globalFilters,
    goToAddTransactionPage,
    goToAddTransferPage,
    goToBulkImport,
    selectedColumns,
    userPermissions,
    withAddButton,
  ]);
}
