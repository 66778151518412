import { Dispatch } from '@reduxjs/toolkit';
import { IRowExpand } from 'react-ui-kit-exante';

import { MAX_REQUEST_LIMIT } from '~/constants';
import { expandedRowsUpdate } from '~/store/limits';
import { ILimitTree, TLimitsQueryParams } from '~/types/limits';

interface IGetAndInsertInstrumentsToNodeParams<T> {
  dispatch: Dispatch;
  getInstruments: T;
  path: string;
  row: IRowExpand<ILimitTree>;
  skip: number;
  queryParams: TLimitsQueryParams | false;
}

export async function getAndInsertInstrumentsToNode<T>({
  dispatch,
  getInstruments,
  path,
  row,
  skip,
  queryParams,
}: IGetAndInsertInstrumentsToNodeParams<T>) {
  if (typeof getInstruments !== 'function') {
    return;
  }

  const shouldExpandRowAfterFirstInstruments = skip === 0;
  const { toggleRowExpanded, id } = row;

  await getInstruments({
    path,
    limit: MAX_REQUEST_LIMIT,
    skip,
    queryParams,
  });

  if (shouldExpandRowAfterFirstInstruments && toggleRowExpanded) {
    toggleRowExpanded();
    dispatch(expandedRowsUpdate([id, true]));
  }
}
