import { pick } from 'lodash';
import { useCallback } from 'react';

import { PATH_DELIMITER } from '~/constants/common';
import { convertCommissionsRulesToObject } from '~/shared/utils/commissions';
import { FilterLayers, ICommissionTree } from '~/types/commissions';
import { RowType } from '~/types/common';

export function usePrepareDataForSend() {
  return useCallback((data: ICommissionTree, layer: FilterLayers) => {
    const {
      currency,
      inherited,
      minFeeExchange = false,
      minFeeOdd,
      minFeeRebateRate,
      minimumFee,
      override,
      path,
      rowType,
      typeExchangeTicker,
      ...rest
    } = data;

    const splitedPath = (
      rowType === RowType.Node ? path : typeExchangeTicker
    ).split(PATH_DELIMITER);

    const [type, exchange] = splitedPath;
    let ticker;

    if (rowType === RowType.Instrument) {
      ticker = splitedPath.pop();
    }

    return {
      active: layer === FilterLayers.Default ? !inherited : override,
      currency: currency === 'null' ? null : currency,
      exchange,
      inherited,
      minFeeExchange,
      minFeeOdd,
      minFeeRebateRate,
      minimumFee,
      type,
      rules: convertCommissionsRulesToObject(
        pick(
          rest,
          layer === FilterLayers.Default
            ? ['internal', 'rebate', 'exchange']
            : ['internal', 'rebate'],
        ),
      ),
      ...(ticker && { ticker }),
    };
  }, []);
}
