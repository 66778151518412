import { isUndefined } from 'lodash';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import {
  Tab,
  TabPanel,
  Tabs,
  IconButton,
  Checkbox,
  Modal,
} from 'react-ui-kit-exante';

import {
  PanelHeaderControls,
  IPanelHeaderControlsProps,
} from '../PanelHeaderControls';
import { WrapperLoader } from '../WrapperLoader';

import {
  StyledCheckboxContainer,
  StyledContainer,
  StyledContentContainer,
  StyledControlsContainer,
  StyledHeader,
  StyledPageControlsContainer,
  StyledTableControls,
} from './LayersLayout.styled';
import {
  NewGroupModal,
  Search,
  INewGroupModalProps,
  ISearchProps,
} from './components';
import { text } from './constants';

type TLayersLayoutProps<Tab = string> = {
  initialTab?: Tab | null;
  isSaveDisabled?: boolean;
  loaderParams?: Partial<Parameters<typeof WrapperLoader>[0]>;
  loading: boolean;
  onAddGroup: (groupName: string) => Promise<unknown>;
  onChangeLayer?: (tabName: string, tabIndex: number) => unknown;
  onDeleteGroup: () => unknown;
  onRefresh?: IPanelHeaderControlsProps['onRefresh'];
  onSave?: IPanelHeaderControlsProps['onSave'] | null;
  pageControls?: ReactNode;
  searchParams: Omit<ISearchProps, 'searchValue' | 'setSearchValue'> & {
    initialSearchValue?: string;
    hidden?: boolean;
  };
  setShowExpired?: (value: boolean) => unknown;
  showExpired?: boolean;
  table: ReactNode | ((activeTab: number) => ReactNode);
  tabs: (Tab | { tabName: Tab; render: ReactNode })[];
  title: string;
};

export const LayersLayout = <Tab extends string = string>({
  initialTab,
  isSaveDisabled,
  loaderParams,
  loading,
  onAddGroup,
  onChangeLayer,
  onDeleteGroup,
  onRefresh,
  onSave,
  pageControls,
  searchParams,
  setShowExpired,
  showExpired,
  table,
  tabs,
  title,
}: TLayersLayoutProps<Tab>) => {
  const [activeTab, setActiveTab] = useState(() => {
    if (initialTab !== null) {
      return tabs.findIndex(
        (t) => (typeof t === 'object' ? t.tabName : t) === initialTab,
      );
    }

    return 0;
  });

  const [isNewGroupModalOpen, setNewGroupModalOpen] = useState(false);
  const activeTabItem = tabs[activeTab];
  const isGroupActiveTab =
    typeof activeTabItem === 'object'
      ? activeTabItem.tabName === 'Groups'
      : activeTabItem === 'Groups';
  const [searchValue, setSearchValue] = useState(
    searchParams.initialSearchValue ?? '',
  );
  const [deleteGroupModalIsOpen, setDeleteGroupModalIsOpen] = useState(false);

  useEffect(() => {
    if (!isUndefined(searchParams.initialSearchValue)) {
      setSearchValue(searchParams.initialSearchValue);
    }
  }, [searchParams.initialSearchValue]);

  const handleChangeTab = useCallback(
    (_: unknown, value: number) => {
      setActiveTab(value);
      if (onChangeLayer) {
        const newTab = tabs[value];
        onChangeLayer(
          typeof newTab === 'object' ? newTab.tabName : newTab,
          value,
        );
        setSearchValue('');
      }
    },
    [tabs, onChangeLayer],
  );

  const openModal = () => setNewGroupModalOpen(true);
  const closeModal = () => setNewGroupModalOpen(false);

  const handleSubmit: INewGroupModalProps['onSubmit'] = async (value) => {
    onAddGroup(value);
  };

  return (
    <StyledContainer>
      <StyledHeader>{title}</StyledHeader>

      <StyledContentContainer>
        <Tabs value={activeTab} onChange={handleChangeTab}>
          {tabs.map((tab) => {
            const tabKey = typeof tab === 'object' ? tab.tabName : tab;
            const tabLabel = typeof tab === 'object' ? tab.render : tab;

            return <Tab key={tabKey} label={tabLabel} />;
          })}
        </Tabs>

        {pageControls ? (
          <StyledPageControlsContainer>
            {pageControls || isGroupActiveTab ? (
              <StyledControlsContainer>
                {pageControls}
                {isGroupActiveTab ? (
                  <IconButton
                    iconColor="action"
                    iconName="DeleteIcon"
                    onClick={() => setDeleteGroupModalIsOpen(true)}
                  />
                ) : null}
              </StyledControlsContainer>
            ) : null}

            {isGroupActiveTab ? (
              <IconButton
                iconColor="action"
                iconName="AddIcon"
                label={text.ADD_GROUP_TEXT}
                onClick={openModal}
              />
            ) : null}
          </StyledPageControlsContainer>
        ) : null}

        {!searchParams.hidden || setShowExpired || onSave || onRefresh ? (
          <StyledTableControls>
            {!searchParams.hidden ? (
              <Search
                {...searchParams}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            ) : null}
            {setShowExpired ? (
              <StyledCheckboxContainer>
                <Checkbox
                  label="Show expired"
                  checked={showExpired}
                  onChange={() => setShowExpired(!showExpired)}
                />
              </StyledCheckboxContainer>
            ) : null}
            <PanelHeaderControls
              disableSaveButton={loading || isSaveDisabled}
              onSave={onSave}
              onRefresh={onRefresh}
              hideSaveButton={!onSave}
            />
          </StyledTableControls>
        ) : null}

        <TabPanel value={0} index={0}>
          <WrapperLoader isLoading={loading} {...loaderParams}>
            {typeof table === 'function' ? table(activeTab) : table}
          </WrapperLoader>
        </TabPanel>
      </StyledContentContainer>
      <NewGroupModal
        isOpened={isNewGroupModalOpen}
        onClose={closeModal}
        onSubmit={handleSubmit}
      />
      <Modal
        onClose={() => {
          setDeleteGroupModalIsOpen(false);
        }}
        isOpened={deleteGroupModalIsOpen}
        title={text.DELETE_GROUP_TITLE}
        confirmButton={{
          handleConfirm: () => {
            onDeleteGroup();
            setDeleteGroupModalIsOpen(false);
          },
          confirmButtonName: text.CONFIRM_DELETE_GROUP,
        }}
        cancelButton={{
          cancelButtonName: text.REJECT_DELETE_GROUP,
        }}
        keepMounted={false}
      >
        {text.DELETE_GROUP_CONFIRMATION_TEXT}
      </Modal>
    </StyledContainer>
  );
};
