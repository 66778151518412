import { FC } from 'react';

import { IOption } from '~/types/form';

import {
  FormCheckboxContainer,
  FormInputContainer,
  FormSelectContainer,
} from '../../form';

import InfoStyles from './Info.module.css';

interface IInfoProps {
  currentUserHasAllBrandingPermission?: boolean;
  options: Record<string, Array<IOption>>;
}

export const Info: FC<IInfoProps> = ({
  currentUserHasAllBrandingPermission = false,
  options,
}) => {
  const {
    clientAccounts,
    clientProviders,
    clientTypes,
    incorporationTypes,
    legalEntities,
    brandingListOptions,
  } = options;

  return (
    <div className={InfoStyles.Wrapper}>
      <FormInputContainer label="Client name" name="client.name" />
      <FormInputContainer
        readonly
        label="Last modification"
        name="client.lastModificationDate"
      />
      <FormCheckboxContainer label="Archived" name="client.archived" />
      <FormCheckboxContainer
        disabled={!currentUserHasAllBrandingPermission}
        label="Withdrawal restriction"
        name="client.withdrawalRestriction"
      />
      <FormCheckboxContainer
        disabled={!currentUserHasAllBrandingPermission}
        label="Documents pending"
        name="client.documentsPending"
      />
      <FormInputContainer label="Email" name="client.clientInfo.email" />
      <FormInputContainer
        label="Primary phone"
        name="client.clientInfo.primaryPhone"
      />
      <FormInputContainer
        label="Secondary phone"
        name="client.clientInfo.secondaryPhone"
      />
      <FormInputContainer
        label="Internal info"
        name="client.clientInfo.internalInfo"
      />
      <FormSelectContainer
        label="Trade commission charge account id"
        name="client.tradeCommissionChargeAccountId"
        options={clientAccounts}
      />
      <FormSelectContainer
        disabled={!currentUserHasAllBrandingPermission}
        label="Counterparty name"
        name="client.counterpartyId"
        options={clientProviders}
      />
      Settings
      <FormSelectContainer
        disabled={!currentUserHasAllBrandingPermission}
        label="Client type"
        name="client.exanteType"
        options={clientTypes}
      />
      <FormSelectContainer
        disabled={!currentUserHasAllBrandingPermission}
        label="Incorporation type"
        name="client.incorporationType"
        options={incorporationTypes}
      />
      <FormSelectContainer
        disabled
        label="Legal entity"
        name="client.legalEntity"
        options={legalEntities}
      />
      <FormSelectContainer
        label="Branding"
        name="client.branding"
        options={brandingListOptions}
      />
    </div>
  );
};
