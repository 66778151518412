import { debounce, get, isBoolean, isEqual, keys, values } from 'lodash';
import {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  MouseEvent,
} from 'react';
import { Notification, TPropRowGetter } from 'react-ui-kit-exante';

import {
  useUpdateGroupSettingsMutation,
  useUpdateUserGroupSettingsMutation,
} from '~/api';
import { DEFAULT_DEBOUNCE_TIMEOUT, EMPTY_ARRAY } from '~/constants';
import { useTableVirtualized, useLogHandleTime, usePrevious } from '~/hooks';
import { ISymbolTreeStructure } from '~/types/symbolPermissions';

import { SymbolPermissions } from './components/SymbolPermissions';
import { ActionTypes, SymbolPermissionsContext } from './constants';
import { saveData, canExpandDefinition } from './helpers';
import {
  useHandleCellClick,
  useMatchIdAndPositionInTable,
  useSearchInstruments,
  useSearchIsActive,
  useSymbolsTree,
} from './hooks';
import { getAndInsertInstrumentsToNode } from './hooks/useHandleCellClick/helpers';
import { TExpanded } from './types';

const isValidExpandedObj = (obj: unknown): obj is TExpanded => {
  return values(obj).every((t) => isBoolean(t));
};

export const SymbolPermissionsContainer = () => {
  const {
    setStartHandleTime: setStartHandleTimeList,
    logHandleTime: logHandleTimeList,
  } = useLogHandleTime('symbol-permissions-list');

  const {
    setStartHandleTime: setStartHandleTimeSearch,
    logHandleTime: logHandleTimeSearch,
  } = useLogHandleTime('symbol-permissions-search');

  const [tableData, setTableData] = useState<ISymbolTreeStructure[] | null>([]);
  const prevTableData = usePrevious(tableData);
  const [state, dispatch] = useContext(SymbolPermissionsContext);
  const {
    expandedRows,
    filters,
    permissions: { changed: changedPermissions },
    permissionsByPath,
    needRefreshPage,
  } = state;
  const { withExpired } = filters;
  const prevWithExpired = usePrevious(withExpired);

  const [searchValue, setSearchValue] = useState('');
  const [blockTableMessage, setBlockTableMessage] = useState('');
  const tableRef = useRef<HTMLDivElement>(null);
  const refreshQueue = useRef(new Set<string>());

  const {
    updateMatchIdAndPositionInSearchTable,
    matchIdAndPositionInNonSearchTable,
    matchIdAndPositionInSearchTable,
    updateMatchIdAndPositionInNonSearchTable,
  } = useMatchIdAndPositionInTable();
  const { searchIsActive, updateSearchIsActive } = useSearchIsActive();

  const [isRefetching, setRefetching] = useState(false);
  const { virtualized, updateTableSizes } = useTableVirtualized(
    tableRef.current,
  );

  const {
    isLoading,
    symbolsTree,
    refetch: refetchTree,
  } = useSymbolsTree(
    updateMatchIdAndPositionInSearchTable,
    setTableData,
    updateMatchIdAndPositionInNonSearchTable,
    setStartHandleTimeList,
  );

  const { handleCellClick } = useHandleCellClick({
    matchIdAndPositionInNonSearchTable,
    matchIdAndPositionInSearchTable,
    refreshQueue: refreshQueue.current,
    searchIsActive,
    setTableData,
    updateMatchIdAndPositionInNonSearchTable,
    updateTableSizes,
  });

  const { debounceSearchInstruments, searchIsLoading, resetTable } =
    useSearchInstruments({
      matchIdAndPositionInNonSearchTable,
      preCall: setStartHandleTimeSearch,
      setTableData,
      symbolsTree,
      updateMatchIdAndPositionInSearchTable,
      updateSearchIsActive,
      updateTableSizes,
    });

  const isPermissionGroupChanged =
    filters.relatedGroup !== filters.select.group;

  const resetDataOnFilterChange = useCallback(() => {
    setSearchValue('');
    resetTable();
    dispatch({ type: ActionTypes.PERMISSIONS_RESET, payload: null });
    dispatch({ type: ActionTypes.DOWNLOADED_PATHS_RESET, payload: null });
    dispatch({ type: ActionTypes.CHANGED_DEFAULT_NODES_RESET, payload: null });
    dispatch({ type: ActionTypes.PERMISSIONS_BY_PATHS_RESET, payload: null });
    refreshQueue.current.clear();
  }, [dispatch, resetTable]);

  const debounceHandleChangeInput = useMemo(
    () => debounce(debounceSearchInstruments, DEFAULT_DEBOUNCE_TIMEOUT),
    [debounceSearchInstruments],
  );

  const handleChangeInput = useCallback(
    (value: string) => {
      setSearchValue(value);
      debounceHandleChangeInput(value);
    },
    [debounceHandleChangeInput],
  );

  const handleTableClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (blockTableMessage) {
        event.stopPropagation();

        Notification.warning(blockTableMessage);
      }
    },
    [blockTableMessage],
  );

  const [updateGroupSettings] = useUpdateGroupSettingsMutation();
  const [updateUserGroupSettings] = useUpdateUserGroupSettingsMutation();

  const handleSaveData = useCallback(() => {
    saveData(state, dispatch, updateGroupSettings, updateUserGroupSettings);
  }, [state, dispatch, updateGroupSettings, updateUserGroupSettings]);

  useEffect(() => {
    if (tableData && !isEqual(prevTableData, tableData)) {
      logHandleTimeList();
      logHandleTimeSearch();
    }
  }, [tableData, logHandleTimeList, logHandleTimeSearch, prevTableData]);

  const getInstrumentsListToRefetch = useCallback(() => {
    const rows = keys(expandedRows).reduce<ISymbolTreeStructure[]>(
      (acc, id) => {
        const ids = id.split('.').join('.subRows.');
        const row: ISymbolTreeStructure = get(tableData, `${ids}`);

        acc.push(row);

        return acc;
      },
      [],
    );

    const list = rows.filter(({ id }) => refreshQueue.current.has(id));

    return list;
  }, [expandedRows, tableData]);

  const refetchExpandedNodes = useCallback(async () => {
    const instrumentsListToRefetch = getInstrumentsListToRefetch();

    const refetchInstrumentsQueries = instrumentsListToRefetch.map(({ id }) => {
      return getAndInsertInstrumentsToNode({
        changedPermissions,
        dispatch,
        expanded: isValidExpandedObj(expandedRows) ? expandedRows : {},
        filters,
        matchIdAndPositionInNonSearchTable,
        permissionsByPath: {},
        refreshQueue: refreshQueue.current,
        row: { original: { id }, id },
        setTableData,
        skip: 0,
        updateMatchIdAndPositionInNonSearchTable,
        withExpired,
      });
    });

    await Promise.all(refetchInstrumentsQueries);
  }, [
    changedPermissions,
    dispatch,
    expandedRows,
    filters,
    getInstrumentsListToRefetch,
    matchIdAndPositionInNonSearchTable,
    permissionsByPath,
    updateMatchIdAndPositionInNonSearchTable,
    withExpired,
  ]);

  const refetchCurrentSearchResult = useCallback(() => {
    const currentSearchValue = searchValue;
    dispatch({ type: ActionTypes.PERMISSIONS_RESET, payload: null });
    dispatch({ type: ActionTypes.DOWNLOADED_PATHS_RESET, payload: null });
    debounceHandleChangeInput(currentSearchValue);
  }, [dispatch, debounceHandleChangeInput, searchValue]);

  const handleRefresh = useCallback(async () => {
    dispatch({
      type: ActionTypes.NEED_REFRESH_PAGE_SET,
      payload: true,
    });
  }, [dispatch]);

  const fullReloadTable = useCallback(async () => {
    if (isRefetching) {
      return;
    }

    setRefetching(true);

    await refetchTree();
    await refetchExpandedNodes();

    if (searchIsActive.current) {
      refetchCurrentSearchResult();
    }

    setRefetching(false);

    if (needRefreshPage) {
      dispatch({
        type: ActionTypes.NEED_REFRESH_PAGE_SET,
        payload: false,
      });
    }
  }, [
    dispatch,
    isRefetching,
    needRefreshPage,
    refetchCurrentSearchResult,
    refetchExpandedNodes,
    refetchTree,
    searchIsActive,
  ]);

  useEffect(() => {
    if (needRefreshPage) {
      fullReloadTable();
    }
  }, [needRefreshPage, fullReloadTable]);

  const reloadDataOnFilterChange = useCallback(() => {
    setSearchValue('');
    fullReloadTable();
  }, [fullReloadTable]);

  const getRowProps: TPropRowGetter<ISymbolTreeStructure> = useCallback(
    (row) => {
      return { style: { opacity: row?.original?.isExpired ? 0.7 : 1 } };
    },
    [],
  );

  useEffect(() => {
    if (withExpired !== prevWithExpired) {
      fullReloadTable();
    }
  }, [fullReloadTable, withExpired, prevWithExpired]);

  return (
    <SymbolPermissions
      searchValue={searchValue}
      expanded={{
        canExpandDefinition,
        customExpandControl: true,
        listOfInitialExpandedRowKeys: searchIsActive.current || expandedRows,
      }}
      getRowProps={getRowProps}
      handleCellClick={handleCellClick}
      handleSaveData={handleSaveData}
      handleTableClick={handleTableClick}
      isLoading={isLoading || isRefetching}
      isPermissionGroupChanged={isPermissionGroupChanged}
      onChangeHandler={handleChangeInput}
      onRefresh={handleRefresh}
      reloadDataOnFilterChange={reloadDataOnFilterChange}
      resetDataOnFilterChange={resetDataOnFilterChange}
      searchIsLoading={searchIsLoading}
      setBlockTableMessage={setBlockTableMessage}
      tableData={tableData || EMPTY_ARRAY}
      tableRef={tableRef}
      virtualized={virtualized}
      resetTable={resetTable}
    />
  );
};
