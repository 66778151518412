import { FilterLayers, IStateLimits } from '~/types/limits';

export function getQueryParams({
  layer,
  account,
  group,
  relatedGroup,
}: IStateLimits['filters']['select'] & {
  relatedGroup: IStateLimits['filters']['relatedGroup'];
}) {
  if (layer === FilterLayers.Default) {
    return { layer };
  }

  if (layer === FilterLayers.Groups && group) {
    return { layer, entity: group };
  }

  if (layer === FilterLayers.Accounts && account) {
    const groupFilterHasNotBeenChanged = relatedGroup === group;

    if (groupFilterHasNotBeenChanged) {
      return {
        layer,
        entity: account,
      };
    }

    return {
      layer: FilterLayers.Groups,
      entity: group,
    };
  }

  return false;
}
