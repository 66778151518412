import { SyntheticEvent, useState } from 'react';

import { FilterLayers } from '~/types/limits';

export const useTabs = () => {
  const [activeTab, setActiveTab] = useState(FilterLayers.Default);

  const handleTabChange = (event: SyntheticEvent, tab: FilterLayers) => {
    setActiveTab(tab);
  };

  return {
    activeTab,
    handleTabChange,
  };
};
