import { RowType } from '~/types/common';

export interface ILimit {
  mode: null | string;
  negativeLim: null | string;
  positiveLim: null | string;
  override: null | boolean;
}

export interface ILimitsResponseTree {
  displayName: string;
  icon?: string;
  id: string;
  path: string;
}

export interface ILimitInstrumentResponse extends ILimitsResponseTree {
  limit: ILimit;
}

export interface ILimitTree
  extends Record<string, unknown>,
    ILimitsResponseTree,
    ILimit {
  rowType: RowType.Node | RowType.Instrument;
  subRows?: ILimitTree[];
  nodeIsCalculated?: boolean;
}

export enum FilterLayers {
  Default = 'Default',
  Groups = 'Groups',
  Accounts = 'Accounts',
}

export type TLimitsQueryParams = {
  layer: FilterLayers;
  entity?: number | string;
};

export interface IChangedLimits {
  nodes: Record<string, ILimitTree>;
  instruments: Record<string, ILimitTree>;
}

export interface IStateLimits {
  changedLimits: IChangedLimits;
  table: {
    defaultTree: ILimitTree[];
    defaultPositionByIdInTree: Record<string, string>;
    expandedRows: Record<string, boolean> | boolean;
    positionByIdInTree: Record<string, string>;
    tree: ILimitTree[];
  };
  search: {
    isActive: boolean;
    tree: ILimitTree[];
  };
  filters: {
    select: {
      layer: FilterLayers;
      account: null;
      group: undefined;
    };
    queryParams: TLimitsQueryParams | false;
    relatedGroup: null;
  };
}

export interface IChangedLimits {
  nodes: Record<string, ILimitTree>;
  instruments: Record<string, ILimitTree>;
}

export interface IChangedLimitsParams {
  changedLimits: IChangedLimits;
  filterGroup?: number;
}

export interface IChangedLimitsAccountParams {
  changedLimits: IChangedLimits;
  filterAccount: number;
}
