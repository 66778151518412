import { Notification } from 'react-ui-kit-exante';

import { sendUiKitErrorNotification } from '~/shared/utils';
import { TParams } from '~/types/api';
import { TBookmarks } from '~/types/bookmarks';

import { bookmarkMapper, bookmarksMapper } from './bookmarks.mappers';
import { BookmarksRepository } from './bookmarks.repository';
import { transformFilters } from './helpers';
import { TCreateBookmarkPayload, TUpdateBookmarkPayload } from './types';

export class BookmarksService {
  public async fetchBookmarks(params: TParams): Promise<TBookmarks> {
    const { data } = await BookmarksRepository.fetchBookmarks({
      ...params,
    });

    return bookmarksMapper(data);
  }

  public async fetchBookmark(id: number) {
    const { data } = await BookmarksRepository.fetchBookmark(id);

    return data;
  }

  public async createBookmark(
    createPayload: TCreateBookmarkPayload,
  ): Promise<TBookmarks | null> {
    try {
      const { data } = await BookmarksRepository.createBookmark({
        ...createPayload,
        filters: transformFilters(createPayload.filters),
      });

      Notification.success({
        title: 'Bookmark successfully created',
      });

      return bookmarkMapper(data);
    } catch (error) {
      sendUiKitErrorNotification(error);

      return null;
    }
  }

  public async updateBookmark(
    id: number,
    updatePayload: TUpdateBookmarkPayload,
  ): Promise<TBookmarks | null> {
    try {
      const { data } = await BookmarksRepository.updateBookmark(id, {
        ...updatePayload,
        filters: transformFilters(updatePayload.filters || {}),
      });

      Notification.success({
        title: 'Bookmark successfully updated',
      });

      return bookmarkMapper(data);
    } catch (error) {
      sendUiKitErrorNotification(error);

      return null;
    }
  }

  public async deleteBookmark(id: number): Promise<boolean> {
    try {
      await BookmarksRepository.deleteBookmark(id);

      Notification.success({
        title: 'Bookmark successfully deleted',
      });
      return true;
    } catch (error) {
      sendUiKitErrorNotification(error);
      return false;
    }
  }
}
