import { yupResolver } from '@hookform/resolvers/yup';
import { get, keyBy } from 'lodash';
import { FC, useContext, useEffect, useMemo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  accessRightsApi,
  useGetCurrentUserAccessRightsQuery,
  usersApi,
} from '~/api';
import {
  useGetAccountTypesQuery,
  useGetLegalEntityTypesQuery,
} from '~/api/types/types.api';
import { EMPTY_FUNCTION } from '~/constants';
import {
  useBrandingList,
  useCurrentUserHasAllBrandingPermission,
} from '~/hooks';
import { userPageContext } from '~/pages/UserPage/context/userPageContext';
import { UserServices } from '~/resources/services/types';
import { TabContentHeader, FixedLoader, SaveButton } from '~/shared/components';
import { createFormSubmitHandler } from '~/shared/utils';
import {
  IAccessRights,
  ICoreFormUser,
  TAccountTypes,
  TBrandingPermission,
  TLegalEntities,
} from '~/types/users';

import { BackofficePermissionsContainer } from '../BackofficePermissionsContainer';
import { CommonPermissions } from '../CommonPermissions';
import { ServicesPermissionsContainer } from '../ServicesPermissionsContainer';
import { IFormData, TServiceStateMap } from '../types';
import { getValidationSchema } from '../validationSchema';

import { UserAccessRights } from './UserAccessRights';
import { getFormValues } from './helpers';
import { useCreateHandlersMap } from './hooks';

interface IAccessRightsFormContainerProps {
  accessRights: IAccessRights;
  currentAccountTypes: TAccountTypes;
  legalEntities: TLegalEntities;
  brandingPermission: TBrandingPermission;
  services: TServiceStateMap;
  user: ICoreFormUser;
  accountPermissionsQueryRefetch?: () => void;
  userServices: UserServices;
  userServicesQuery: {
    refetch: () => Promise<unknown>;
    isFetching: boolean;
  };
}

// A lot of code I have just moved from old realisation
// I think it should be refactored because data structure is not convenient and clear
export const AccessRightsFormContainer: FC<IAccessRightsFormContainerProps> = ({
  currentAccountTypes,
  accessRights,
  legalEntities,
  brandingPermission,
  services,
  user,
  accountPermissionsQueryRefetch,
  userServices,
  userServicesQuery,
}) => {
  const dispatch = useDispatch();
  const { data: accountTypes } = useGetAccountTypesQuery();

  const { data: legalEntityTypes } = useGetLegalEntityTypesQuery();

  const { brandingList } = useBrandingList();
  const createHandlersMap = useCreateHandlersMap(user, userServices);

  const { setDirtyTabs } = useContext(userPageContext);
  const userAccessRightsForm = useMemo(
    () =>
      getFormValues(
        accessRights.userAccessRightsResponse ?? {},
        accessRights.allAccessRightsResponse ?? {},
      ),
    [accessRights],
  );

  const {
    data: { userId, ...currentUserPermissions } = {
      read: {},
      write: {},
    },
  } = useGetCurrentUserAccessRightsQuery();

  const { currentUserHasAllBrandingPermission } =
    useCurrentUserHasAllBrandingPermission();

  const hasServicesAccess =
    currentUserPermissions?.read['User permissions on services'] &&
    currentUserHasAllBrandingPermission;

  const hasBoPermissionsAccess =
    currentUserPermissions?.read['User BO permissions'];

  const validationSchema = useMemo(() => getValidationSchema(), []);
  const formInstance = useForm<IFormData>({
    defaultValues: {
      accessRights: keyBy(userAccessRightsForm, 'operation'),
      generalPermissions: {
        accountTypes: currentAccountTypes,
        legalEntities,
        brandingPermission,
      },
      services,
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    reset,
    getValues,
    formState: { dirtyFields, isDirty, isSubmitting },
  } = formInstance;

  const handlersMap = useMemo(() => createHandlersMap(), [createHandlersMap]);

  const callback = useCallback(() => {
    dispatch(
      usersApi.util.invalidateTags([{ type: 'User', id: Number(userId) }]),
    );
    dispatch(accessRightsApi.util.invalidateTags(['AccessRightsUser']));
  }, [dispatch, userId]);

  const handleFormSubmit = useMemo(
    () =>
      createFormSubmitHandler({
        accountPermissionsQueryRefetch,
        callback,
        dirtyFields,
        getValues,
        handlersMap,
        reset,
      }),
    [
      accountPermissionsQueryRefetch,
      callback,
      dirtyFields,
      getValues,
      handlersMap,
      reset,
    ],
  );

  useEffect(() => {
    setDirtyTabs('accessRights', isDirty);
  }, [dirtyFields, isDirty, setDirtyTabs]);

  return (
    <FormProvider {...formInstance}>
      <form
        onSubmit={handleSubmit(async (data) => {
          // TODO Move to the RTK; update via tags, without manual refetch
          const refetchMap = {
            services: userServicesQuery.refetch,
          };
          const submitResult = await handleFormSubmit(data);
          submitResult.refetchList.forEach((sectionName) => {
            if (sectionName in refetchMap) {
              const refetchFn = get(refetchMap, sectionName, EMPTY_FUNCTION);
              refetchFn();
            }
          });

          return submitResult;
        })}
      >
        {isSubmitting && <FixedLoader />}

        <UserAccessRights
          header={
            <TabContentHeader
              title="Access rights"
              actions={
                <SaveButton type="submit" disabled={isSubmitting || !isDirty} />
              }
            />
          }
          commonPermissions={
            hasBoPermissionsAccess ? (
              <CommonPermissions
                accountTypesOptions={accountTypes?.values || []}
                legalEntities={legalEntityTypes?.values || []}
                brandingList={brandingList.map((item) => item.value)}
              />
            ) : null
          }
          servicesPermissions={
            hasServicesAccess ? <ServicesPermissionsContainer /> : null
          }
          backofficePermissions={
            hasBoPermissionsAccess ? (
              <BackofficePermissionsContainer
                userAccessRightsForm={userAccessRightsForm}
              />
            ) : null
          }
        />
      </form>
    </FormProvider>
  );
};
