import { useCallback } from 'react';

import { useLazyGetLimitsTreeQuery } from '~/api';

export const useLimitsTree = () => {
  const [getLimitsTree, state] = useLazyGetLimitsTreeQuery();
  const { isLoading, isFetching } = state;

  const fetchLimitsTree = useCallback(async () => {
    getLimitsTree();
  }, [getLimitsTree]);

  return {
    fetchLimitsTree,
    isLoading: isLoading || isFetching,
  };
};
