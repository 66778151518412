import { get } from 'lodash';

export const getErrorResponse = <ExpectedErrorType>(
  err: unknown,
  defaultErrorMessage = 'Unknown error',
): ExpectedErrorType | string => {
  if (err instanceof Error) {
    if (get(err, 'isAxiosError')) {
      return get(err, 'response.data', defaultErrorMessage);
    }

    return err.message;
  }

  return defaultErrorMessage;
};
