import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Notification } from 'react-ui-kit-exante';

import {
  useSaveAccountLimitsMutation,
  useSaveLimitsMutation,
  useUpdateGroupSettingsMutation,
} from '~/api';
import {
  resetChangedLimits,
  selectChangedLimits,
  selectFiltersAccount,
  selectFiltersGroup,
  selectFiltersLayer,
  selectFiltersQueryParams,
  selectFiltersRelatedGroup,
} from '~/store/limits';
import { FilterLayers } from '~/types/limits';

import { LimitMessages } from '../constants';

import { useLimitsTree } from './useLimitsTree';

export function useSaveData() {
  const dispatch = useDispatch();

  const [saveLimits] = useSaveLimitsMutation();
  const [saveAccountLimits] = useSaveAccountLimitsMutation();

  const changedLimits = useSelector(selectChangedLimits);
  const filterAccount = useSelector(selectFiltersAccount);
  const filterGroup = useSelector(selectFiltersGroup);
  const filterRelatedGroup = useSelector(selectFiltersRelatedGroup);
  const filtersLayer = useSelector(selectFiltersLayer);
  const queryParams = useSelector(selectFiltersQueryParams);

  const { fetchLimitsTree } = useLimitsTree();

  const accountsGroupWasChanged =
    filterGroup !== filterRelatedGroup &&
    filtersLayer === FilterLayers.Accounts;

  const [updateGroupSettings] = useUpdateGroupSettingsMutation();

  const saveNewGroupForAccount = useCallback(async () => {
    if (
      !accountsGroupWasChanged ||
      !filterAccount ||
      filterGroup === undefined
    ) {
      return;
    }

    const response = await updateGroupSettings({
      accountId: filterAccount,
      data: {
        limitsSetId: filterGroup,
      },
    });

    if (!('error' in response)) {
      Notification.success({
        title: LimitMessages.GROUP_SUCCESS,
      });
    }
  }, [
    accountsGroupWasChanged,
    filterAccount,
    filterGroup,
    updateGroupSettings,
  ]);

  const saveChangedLimits = useCallback(async () => {
    if (!queryParams) {
      return;
    }

    if (isEmpty(changedLimits.nodes) && isEmpty(changedLimits.instruments)) {
      return;
    }

    let result: Record<string, unknown> = { data: false, error: false };

    if (filtersLayer === FilterLayers.Default) {
      result = await saveLimits({ changedLimits });
    }

    if (filtersLayer === FilterLayers.Groups && filterGroup) {
      result = await saveLimits({ changedLimits, filterGroup });
    }

    if (filtersLayer === FilterLayers.Accounts && filterAccount) {
      result = await saveAccountLimits({
        changedLimits,
        filterAccount,
      });
    }

    if (result.data) {
      Notification.success({
        title: LimitMessages.LIMITS_SUCCESS,
      });
    }
  }, [
    changedLimits,
    filterAccount,
    filterGroup,
    filtersLayer,
    queryParams,
    saveAccountLimits,
    saveLimits,
  ]);

  // requests for save should be executed sequentially
  // because saving new group can rewrite changed limits
  const handleSaveData = useCallback(async () => {
    await saveNewGroupForAccount();

    await saveChangedLimits();

    const isOverrideFalse = Object.entries({
      ...changedLimits.nodes,
      ...changedLimits.instruments,
    }).some((item) => {
      const [, limit] = item;
      const { override } = limit;

      return typeof override === 'boolean' && !override;
    });

    if (isOverrideFalse) {
      fetchLimitsTree();
    }

    dispatch(resetChangedLimits());
  }, [
    changedLimits,
    dispatch,
    fetchLimitsTree,
    saveChangedLimits,
    saveNewGroupForAccount,
  ]);

  return handleSaveData;
}
