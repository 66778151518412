import { get } from 'lodash';

import { EMPTY_OBJECT } from '~/constants';

import {
  TRiskArrayFullNode,
  TRiskArrayNode,
  TRiskArrayTreeNode,
  TStrike,
} from './types';

const hasStrikes = (
  response: Partial<TRiskArrayTreeNode>,
): response is { strikes: TStrike[] } => {
  const strikesList = get(response, 'strikes', null);

  return Array.isArray(strikesList) && Boolean(strikesList.length);
};

type TCreateFullRiskArrayNodeFromStrike = (params: {
  strikes: TStrike[];
  fullData: TRiskArrayNode[];
  initialMargin: number;
  path: string;
  underlying: string;
  optionRight: string;
}) => Record<string, unknown>[];

const createFullRiskArrayNodeFromStrike: TCreateFullRiskArrayNodeFromStrike = ({
  strikes,
  fullData,
  initialMargin,
  path,
  underlying,
  optionRight,
}) => {
  strikes.sort(
    ({ strikePrice: a }, { strikePrice: b }) => Number(a) - Number(b),
  );

  return strikes.map((strike) => {
    const fullRiskArrayNode: Record<string, unknown> = { ...strike };
    const fullStrikeData = fullData.find(
      ({ symbolId }) => symbolId === strike.symbolId,
    );

    const displayName = `${optionRight}, ${strike.strikePrice} (${strike.symbolId})`;
    const name = `${optionRight}, ${strike.strikePrice} (${strike.symbolId})`;

    if (!fullStrikeData) {
      return {
        displayName,
        name,
        id: strike.symbolId,
        underlying,
        initialMargin,
        type: 'leaf',
      };
    }

    const { isAutoCalculated, usePortfolioMargin, delta } = fullStrikeData;

    fullRiskArrayNode.isAutoCalculated = isAutoCalculated;
    fullRiskArrayNode.usePortfolioMargin = usePortfolioMargin;
    fullRiskArrayNode.delta = delta;
    fullRiskArrayNode.path = path;

    if (Array.isArray(fullStrikeData?.riskarray)) {
      fullStrikeData.riskarray.forEach((riskArrayItem, index) => {
        fullRiskArrayNode[`risk-array-${index}`] = riskArrayItem;
      });
    }

    fullRiskArrayNode.displayName = displayName;
    fullRiskArrayNode.name = name;
    fullRiskArrayNode.id = strike.symbolId;
    fullRiskArrayNode.underlying = underlying;
    fullRiskArrayNode.initialMargin = initialMargin;
    fullRiskArrayNode.type = 'leaf';

    return fullRiskArrayNode;
  });
};

export const createFullRiskArrayNode = (
  arr: TRiskArrayTreeNode[],
  fullData: TRiskArrayNode[][],
): TRiskArrayFullNode[] => {
  const result = arr.map((item, i) => {
    if (hasStrikes(item)) {
      return {
        ...item,
        strikes: createFullRiskArrayNodeFromStrike({
          strikes: item.strikes,
          fullData: fullData[i],
          initialMargin: item.initialMargin,
          path: item.path,
          underlying: item.underlying,
          optionRight: item.optionRight ?? '',
        }),
      };
    }
    const fullRiskArrayNode: Record<string, unknown> = { ...item };
    const data = { ...(fullData[i][0] || EMPTY_OBJECT) };

    const { isAutoCalculated, usePortfolioMargin, delta } = data;

    fullRiskArrayNode.isAutoCalculated = isAutoCalculated;
    fullRiskArrayNode.usePortfolioMargin = usePortfolioMargin;
    fullRiskArrayNode.delta = delta;

    if (data && data.riskarray && Array.isArray(data.riskarray)) {
      data.riskarray.forEach((riskArrayItem, index) => {
        fullRiskArrayNode[`risk-array-${index}`] = riskArrayItem;
      });
    }

    return fullRiskArrayNode;
  });

  return result as TRiskArrayFullNode[];
};

export const mergeWithEmptyArrays = <T, U>(array1: T[], array2: U[]): U[] => {
  if (array2.length < array1.length) {
    const difference = array1.length - array2.length;
    for (let i = 0; i < difference; i += 1) {
      array2.push([] as unknown as U);
    }
  }

  array2.sort((a, b) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const indexA = array1.findIndex((item) => item.id === a[0]?.symbolId);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const indexB = array1.findIndex((item) => item.id === b[0]?.symbolId);
    return indexA - indexB;
  });

  return array2;
};
