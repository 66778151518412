import { IErrorInfo, TErrorMessage } from '~/types/errors';

export function formatErrorForKitNotification({
  statusCode,
  message,
}: IErrorInfo): string[] {
  const notification = [];

  if (statusCode) {
    notification.push(`Status code: ${statusCode}`);
  }

  if (message) {
    const preparedMessage = prepareMessage(message);

    notification.push(preparedMessage);
  }

  return notification;
}

export function prepareMessage(message: TErrorMessage): string {
  let formattedMessage = '';

  if (typeof message === 'object') {
    formattedMessage = JSON.stringify(message);
  }

  if (typeof message === 'string') {
    formattedMessage = message;
  }

  return formattedMessage;
}
