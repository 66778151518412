import { apiRequest } from '~/shared/utils';

import { FETCH_EXECUTION_SCHEMES } from './endpoints';
import { IExecutionSchema } from './types';

export class ExecutionSchemesRepository {
  public static fetchExecutionSchemes() {
    return apiRequest<Array<IExecutionSchema>>({
      url: FETCH_EXECUTION_SCHEMES,
    });
  }
}
