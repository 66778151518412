import { createApi } from '@reduxjs/toolkit/query/react';
import { Notification } from 'react-ui-kit-exante';

import { EMPTY_INSTRUMENTS_DATA } from '~/constants';
import { PATH_DELIMITER } from '~/constants/common';
import {
  DEFAULT_GROUP_ID,
  LimitMessages,
  SpecialNodes,
} from '~/pages/LimitsPage/constants';
import { baseQueryHandler, PROXY_CORE_PATH } from '~/shared/utils';
import { RowType } from '~/types/common';
import {
  ILimitsSetResponse,
  ILimitInstrumentResponse,
  FilterLayers,
  IChangedLimitsParams,
  IChangedLimitsAccountParams,
} from '~/types/limits';

import {
  FETCH_LIMITS_SETS,
  FETCH_LIMITS_TREE,
  getFetchLimitsInstruments,
  getPostLimitsEndpoint,
} from './endpoints';
import {
  convertLimitsStructureFromFlatToTree,
  IConvertLimitsStructureFromFlatToTreeReturn,
  prepareMode,
} from './helpers';
import {
  IGetInstrumentsParams,
  IGetInstrumentsReturn,
  ILimitPostBody,
  ISearchLimitsInstrumentsParams,
  ISearchLimitsInstrumentsReturn,
} from './types';

export const limitsApi = createApi({
  reducerPath: 'limitsApi',
  baseQuery: baseQueryHandler,
  tagTypes: [
    'LimitGroups',
    'LimitsInstruments',
    'LimitsSearchInstruments',
    'LimitsTree',
  ],
  endpoints: (builder) => ({
    getLimitsTree: builder.query<
      IConvertLimitsStructureFromFlatToTreeReturn,
      void
    >({
      queryFn: async (params, _, __, fetchWithBaseQuery) => {
        const { data, error } = await fetchWithBaseQuery({
          url: FETCH_LIMITS_TREE,
        });

        if (error || !data) {
          return { data: { tree: [], positionByIdInTree: {} } };
        }

        return {
          data: convertLimitsStructureFromFlatToTree(data.data),
        };
      },
      providesTags: ['LimitsTree'],
    }),
    getLimitsInstruments: builder.query<
      IGetInstrumentsReturn,
      IGetInstrumentsParams
    >({
      queryFn: async (params, _, __, fetchWithBaseQuery) => {
        const { path, limit, skip, queryParams } = params;

        const newParams = { path, limit, skip };
        const { layer, entity } = queryParams;

        const { data, error } = await fetchWithBaseQuery({
          url: getFetchLimitsInstruments(layer, entity),
          params: newParams,
        });

        if (error || !data) {
          return {
            data: { pagination: { total: 0 }, data: [], path: '' },
          };
        }

        return {
          data: {
            ...data,
            path,
          },
        };
      },
      providesTags: ['LimitsInstruments'],
    }),
    getLimitGroups: builder.query<ILimitsSetResponse[], void>({
      query: () => ({
        url: FETCH_LIMITS_SETS,
      }),
      providesTags: ['LimitGroups'],
    }),
    searchLimitsInstruments: builder.query<
      ISearchLimitsInstrumentsReturn,
      ISearchLimitsInstrumentsParams
    >({
      queryFn: async (params, { dispatch }, __, fetchWithBaseQuery) => {
        const { search, limit, skip, queryParams, instruments = [] } = params;

        if (!queryParams) {
          return EMPTY_INSTRUMENTS_DATA;
        }

        const { layer, entity } = queryParams;
        const newParams = { search, limit, skip };

        const { data, error } = await fetchWithBaseQuery({
          url: getFetchLimitsInstruments(layer, entity),
          params: newParams,
        });

        if (error || !data) {
          return EMPTY_INSTRUMENTS_DATA;
        }

        const { pagination, data: dataInstruments } = data;

        const newInstruments = [
          ...instruments,
          ...dataInstruments.map(
            ({ limit: item, ...instrument }: ILimitInstrumentResponse) => ({
              ...instrument,
              ...item,
              rowType: RowType.Instrument,
            }),
          ),
        ];

        if (pagination.total > skip + limit) {
          dispatch(
            limitsApi.endpoints.searchLimitsInstruments.initiate({
              search,
              limit,
              skip: skip + limit,
              queryParams,
              instruments: newInstruments,
            }),
          );
        }

        return {
          data: {
            instruments: newInstruments,
            isFinish: pagination.total <= skip + limit,
          },
        };
      },
      providesTags: ['LimitsSearchInstruments'],
    }),
    saveLimits: builder.mutation({
      queryFn: async (
        params: IChangedLimitsParams,
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        const { nodes: changedNodes, instruments: changedInstruments } =
          params.changedLimits;

        const { error } = await fetchWithBaseQuery({
          url: getPostLimitsEndpoint(FilterLayers.Default),
          method: 'POST',
          data: Object.entries({
            ...changedNodes,
            ...changedInstruments,
          }).map((item) => {
            const [path, limits] = item;
            const filterGroup = params.filterGroup || DEFAULT_GROUP_ID;
            const [nodeName] = path.split(PATH_DELIMITER);
            const { mode, negativeLim, positiveLim, id, override } = limits;

            const body: ILimitPostBody = {
              manual: typeof override === 'boolean' ? override : true,
              mode: prepareMode(mode),
              negativeLim,
              positiveLim,
              setId: filterGroup,
            };

            if (nodeName === SpecialNodes.OPTION) {
              body.optionGroupId = id;
              return body;
            }
            if (nodeName === SpecialNodes.CURRENCY) {
              body.currency = id;
              return body;
            }

            body.symbolId = id;

            return body;
          }),
        });

        if (error) {
          return { error };
        }

        return { data: true };
      },
    }),
    saveAccountLimits: builder.mutation({
      queryFn: async (
        params: IChangedLimitsAccountParams,
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        const { nodes: changedNodes, instruments: changedInstruments } =
          params.changedLimits;

        const { error } = await fetchWithBaseQuery({
          url: getPostLimitsEndpoint(
            FilterLayers.Accounts,
            params.filterAccount,
          ),
          method: 'POST',
          data: Object.entries({
            ...changedNodes,
            ...changedInstruments,
          }).map((item) => {
            const [path, limits] = item;
            const [nodeName] = path.split(PATH_DELIMITER);
            const { mode, negativeLim, positiveLim, id, override } = limits;

            const body: ILimitPostBody = {
              manual: override,
              mode: prepareMode(mode),
              negativeLim,
              positiveLim,
            };

            if (nodeName === SpecialNodes.OPTION) {
              body.optionGroupId = id;
              return body;
            }
            if (nodeName === SpecialNodes.CURRENCY) {
              body.currency = id;
              return body;
            }

            body.symbolId = id;

            return body;
          }),
        });

        if (error) {
          return { data: false };
        }

        return { data: true };
      },
    }),
    addGroupLimits: builder.mutation({
      query: (name: string) => ({
        url: `${PROXY_CORE_PATH}v2.0/limits_set`,
        method: 'POST',
        data: {
          name,
        },
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: LimitMessages.ADD_GROUP_LIMITS_SUCCESS,
          });
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: ['LimitGroups'],
    }),
    deleteGroupLimits: builder.mutation({
      query: (value: number) => ({
        url: `${PROXY_CORE_PATH}v2.0/limits_set/${value}`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
          Notification.success({
            title: LimitMessages.DELETE_GROUP_LIMITS_SUCCESS,
          });
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: ['LimitGroups'],
    }),
  }),
});

export const {
  useAddGroupLimitsMutation,
  useDeleteGroupLimitsMutation,
  useGetLimitGroupsQuery,
  useLazyGetLimitsInstrumentsQuery,
  useLazyGetLimitsTreeQuery,
  useLazySearchLimitsInstrumentsQuery,
  useSaveAccountLimitsMutation,
  useSaveLimitsMutation,
} = limitsApi;
