import { useState, useEffect, useMemo, useRef, useLayoutEffect } from 'react';
import { FieldArrayWithId } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import {
  FormInputContainer,
  FormSwitch,
  FormSelectContainer,
} from '~/containers/form';
import { usePrevious } from '~/hooks';

import { INTERVAL_OPTIONS, TYPE_OPTIONS, VALUE_OPTIONS } from '../../constants';
import { IFormNonTradingCommission } from '../../types';

import {
  StyledFormItem,
  StyledFormItemFields,
  StyledFormRow,
  StyledDeleteButton,
  StyledRestoreButton,
  StyledToggleButton,
} from './FormItem.styled';

interface IFormItemProps {
  field: FieldArrayWithId<
    { commissions: IFormNonTradingCommission[] },
    'commissions',
    'id'
  >;
  index: number;
  commissionsCount: number;
  commissionsForDelete: Set<number>;
  watched: any;
  restoreCommission: (index: number) => void;
  deleteCommission: (index: number) => void;
  setValue: any;
}

export const FormItem = ({
  field,
  index,
  commissionsCount,
  commissionsForDelete,
  watched,
  restoreCommission,
  deleteCommission,
  setValue,
}: IFormItemProps) => {
  const { state } = useLocation();
  const [commissionMinMax, setCommissionMinMax] = useState({
    min: '',
    max: '',
    commission: '',
  });
  const usePreviousIsFree = usePrevious(watched[index].isFree);

  const isFlashing = useMemo(
    () => state?.commissionName === field.name,
    [field.name, state?.commissionName],
  );
  const wasFlashing = useRef(false);

  useEffect(() => {
    if (usePreviousIsFree !== watched[index].isFree && watched[index].isFree) {
      setCommissionMinMax({
        min: watched[index].min,
        max: watched[index].max,
        commission: watched[index].commission,
      });

      setValue(`commissions.${index}.min`, '');
      setValue(`commissions.${index}.max`, '');
      setValue(`commissions.${index}.commission`, '');
    } else if (
      usePreviousIsFree !== watched[index].isFree &&
      !watched[index].isFree
    ) {
      setValue(`commissions.${index}.min`, commissionMinMax.min);
      setValue(`commissions.${index}.max`, commissionMinMax.max);
      setValue(`commissions.${index}.commission`, commissionMinMax.commission);

      setCommissionMinMax({
        min: '',
        max: '',
        commission: '',
      });
    }
  }, [watched[index].isFree]);

  useEffect(() => {
    setTimeout(() => {
      wasFlashing.current = true;
    }, 2000);
  }, []);

  return (
    <StyledFormItem
      id={field.name}
      data-is-new={index > commissionsCount - 1}
      data-flashing={isFlashing && !wasFlashing.current}
    >
      <input type="hidden" name={`commissions.${index}.commissionId`} />

      <input type="hidden" name={`commissions.${index}.nonFilledName`} />

      <StyledFormItemFields data-is-deleted={commissionsForDelete.has(index)}>
        <StyledFormRow>
          <FormInputContainer
            label="Commission Name"
            name={`commissions.${index}.name`}
          />
        </StyledFormRow>

        <StyledFormRow>
          <FormSwitch name={`commissions.${index}.isFree`} label="Free" />
        </StyledFormRow>

        <StyledFormRow>
          <FormSelectContainer
            label="Value"
            name={`commissions.${index}.value`}
            options={VALUE_OPTIONS}
          />

          <FormSelectContainer
            label="Type"
            name={`commissions.${index}.type`}
            options={TYPE_OPTIONS}
          />

          {watched[index].type === 'regular' ? (
            <StyledToggleButton
              alwaysSelected
              exclusive
              fullWidth
              name={`commissions.${index}.interval`}
              options={INTERVAL_OPTIONS}
            />
          ) : null}
        </StyledFormRow>

        <StyledFormRow>
          <FormInputContainer
            label="Commission"
            name={`commissions.${index}.commission`}
            readonly={watched[index].isFree}
            type="number"
          />

          <FormInputContainer
            label="Min"
            name={`commissions.${index}.min`}
            readonly={watched[index].isFree}
            type="number"
          />

          <FormInputContainer
            label="Max"
            name={`commissions.${index}.max`}
            readonly={watched[index].isFree}
            type="number"
          />
        </StyledFormRow>
      </StyledFormItemFields>

      {commissionsForDelete.has(index) ? (
        <StyledRestoreButton onClick={() => restoreCommission(index)} />
      ) : (
        <StyledDeleteButton onClick={() => deleteCommission(index)} />
      )}
    </StyledFormItem>
  );
};
