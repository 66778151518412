import { get } from 'lodash';

import { errorMessages, knownStatuses } from '~/constants/knownErrors';

import { isAxiosError } from './isAxiosError';

export const isKnownError = (e: unknown) => {
  return isAxiosError(e) && knownStatuses.some((t) => t === e.response?.status);
};

export const getKnownErrorMessage = (e: unknown) => {
  if (isAxiosError(e)) {
    const status = e.response?.status;
    const errMessage = get(errorMessages, status || '', null);

    if (errMessage) {
      return errMessage;
    }
  }

  return 'Unknown error';
};
