import { MutableRefObject } from 'react';
import { IAutocompleteAsyncFetchDataResult } from 'react-ui-kit-exante';

import {
  DEFAULT_PAGINATION_RESPONSE,
  EMPTY_ARRAY,
  FETCH_DATA_SIZE,
} from '~/constants';
import {
  check403Error,
  createServiceResponse,
  getArrayDifference,
  IOptionsArguments,
  sendUiKitErrorNotification,
  throwAbortError,
} from '~/shared/utils';
import { IServiceResponse, TParams } from '~/types/api';
import {
  IClientsState,
  IClientUser,
  ICoreClient,
  TClientWithIdOnly,
} from '~/types/clients';

import {
  clientsMapper,
  transformFormClientUsersToOriginal,
} from './clients.mapper';
import { ClientsRepository } from './clients.repository';
import { ICreateClientPayload } from './types';

export class ClientsService {
  public async resolveClients(
    params: TParams,
    options?: IOptionsArguments,
  ): Promise<IClientsState> {
    try {
      const { data } = await ClientsRepository.fetchClients(params, options);

      return {
        clients: clientsMapper(data.data),
        pagination: data.pagination,
        summary: data.summary,
      };
    } catch (error) {
      const is403Error = check403Error(error);

      if (!is403Error) {
        sendUiKitErrorNotification(error);
      }

      return { clients: [], pagination: DEFAULT_PAGINATION_RESPONSE };
    }
  }

  public async resolveClientIds(params: TParams): Promise<TClientWithIdOnly[]> {
    try {
      const { data } = await ClientsRepository.fetchClients({
        ...params,
        idsOnly: true,
      });

      return data.data;
    } catch (error) {
      const is403Error = check403Error(error);

      if (!is403Error) {
        sendUiKitErrorNotification(error);
      }

      return EMPTY_ARRAY;
    }
  }

  public async resolveClient(id: string): Promise<ICoreClient | null> {
    try {
      const { data } = await ClientsRepository.fetchClient(id);

      return data;
    } catch (error) {
      const is403Error = check403Error(error);

      if (!is403Error) {
        sendUiKitErrorNotification(error);
      }

      return null;
    }
  }

  public async updateFormClient(
    id: string,
    client: ICoreClient,
  ): Promise<IServiceResponse<ICoreClient | null>> {
    try {
      const {
        counterpartyId,
        owner,
        tradeCommissionChargeAccountId,
        branding,
      } = client;

      const { data } = await ClientsRepository.putClient(id, {
        ...client,
        owner: typeof owner !== 'string' ? owner?.value : owner,
        counterpartyId: counterpartyId === 'null' ? null : counterpartyId,
        branding: branding === 'null' ? null : branding,
        tradeCommissionChargeAccountId:
          tradeCommissionChargeAccountId === 'null'
            ? null
            : tradeCommissionChargeAccountId,
      });

      return createServiceResponse({
        data,
        messages: {
          success: 'Client is successfully updated.',
        },
      });
    } catch (error) {
      return createServiceResponse({
        data: null,
        messages: { error: 'Client updating failed.' },
        error,
      });
    }
  }

  public autoCompleteSearchClients = async (
    skip: number,
    search: string,
    options: IOptionsArguments,
  ): Promise<IAutocompleteAsyncFetchDataResult> => {
    try {
      const limit = FETCH_DATA_SIZE;

      const { data } = await ClientsRepository.fetchClients(
        {
          skip,
          limit,
          search,
        },
        options,
      );

      const clients = clientsMapper(data.data);
      const { pagination } = data;

      return {
        fetchData:
          skip + limit < pagination.total
            ? this.autoCompleteSearchClients.bind(this, skip + limit)
            : null,
        options: clients.map(({ id, name }) => ({
          label: name.trim() ? `${id} – ${name.trim()}` : id,
          value: id,
        })),
      };
    } catch (error) {
      throwAbortError(error);
      return {
        fetchData: null,
        options: [],
      };
    }
  };

  public createClient = async (createClientPayload: ICreateClientPayload) => {
    try {
      const { data } = await ClientsRepository.createClient(
        createClientPayload,
      );

      return data;
    } catch (error) {
      sendUiKitErrorNotification(error);

      return null;
    }
  };

  public async resolveCoreClientUsers(
    clientId: string,
  ): Promise<IClientUser | null> {
    try {
      const { data } = await ClientsRepository.getClientUsers(clientId);

      return data;
    } catch (error) {
      const is403Error = check403Error(error);

      if (!is403Error) {
        sendUiKitErrorNotification(error);
      }

      return null;
    }
  }

  public async updateClientUsers(
    clientUsers: IClientUser[],
    previousClientUsers: MutableRefObject<IClientUser[]>,
  ): Promise<IServiceResponse<IClientUser[]>> {
    try {
      const messages: { success: string[]; error: string[] } = {
        success: [],
        error: [],
      };
      const extractedPreviousClientUsers = transformFormClientUsersToOriginal(
        previousClientUsers.current,
      );
      const extractedClientUsers: IClientUser[] =
        transformFormClientUsersToOriginal(clientUsers);

      const { deletedElements, editedElements, createdElements } =
        getArrayDifference(
          extractedPreviousClientUsers,
          extractedClientUsers,
          'userId',
        );

      const deletedPromises = Promise.allSettled(
        deletedElements.map((item) => ClientsRepository.deleteClientUser(item)),
      ).then((results) =>
        results.forEach((result, index) => {
          const currentClientUser = deletedElements[index].userId;

          if (result.status === 'fulfilled') {
            messages.success.push(
              `Client user with id ${currentClientUser} successfully deleted.`,
            );
          }

          if (result.status === 'rejected') {
            messages.error.push(
              `Client user with id ${currentClientUser} deleting failed ${result.reason.messages}`,
            );
          }
        }),
      );

      const postClientData = editedElements.concat(createdElements);
      const updatePromises = postClientData.length
        ? ClientsRepository.postClientUser(postClientData).then(() =>
            messages.success.push('Client users are successfully updated.'),
          )
        : null;

      await Promise.all([deletedPromises, updatePromises]);

      /**
       * It is ok, because it is React Ref
       */
      // eslint-disable-next-line no-param-reassign
      previousClientUsers.current = clientUsers;

      return createServiceResponse({
        data: clientUsers,
        messages,
      });
    } catch (error) {
      return createServiceResponse({
        data: clientUsers,
        error,
        messages: { error: 'Client users saving failed.' },
      });
    }
  }
}
