import { configureStore } from '@reduxjs/toolkit';
import type { Middleware, AnyAction, Dispatch } from '@reduxjs/toolkit';

import {
  accessRightsApi,
  accountPermissionsApi,
  accountsApi,
  auditLogApi,
  commissionsApi,
  expiredInstrumentsApi,
  globalSummaryTasksApi,
  interCommodityApi,
  interestRatesApi,
  limitsApi,
  nonTradingCommissionsApi,
  notificationsApi,
  overnightsApi,
  riskArraysApi,
  symbolPermissionsApi,
  symbolsApi,
  tradesApi,
  transactionsApi,
  transferApi,
  typesApi,
  underlyingApi,
  usersApi,
} from '~/api';
import { commissionGroupsApi } from '~/api/commissionGroups';

import { cardsManagementApi, regularCommissionsApi } from './account';
import { bookmarkApi } from './bookmark';
import { clientApi, clientSummaryApi, clientUsersApi } from './client';
import { mirroringRuleApi } from './mirroringRule';
// eslint-disable-next-line import/no-cycle
import { rootReducer } from './rootReducer';
import { servicesApi } from './user';

const apis = [
  accessRightsApi,
  accountPermissionsApi,
  accountsApi,
  auditLogApi,
  bookmarkApi,
  cardsManagementApi,
  clientApi,
  clientSummaryApi,
  clientUsersApi,
  commissionGroupsApi,
  commissionsApi,
  expiredInstrumentsApi,
  globalSummaryTasksApi,
  interCommodityApi,
  interestRatesApi,
  limitsApi,
  mirroringRuleApi,
  nonTradingCommissionsApi,
  notificationsApi,
  overnightsApi,
  regularCommissionsApi,
  riskArraysApi,
  servicesApi,
  symbolPermissionsApi,
  symbolsApi,
  tradesApi,
  transactionsApi,
  transferApi,
  typesApi,
  underlyingApi,
  usersApi,
];

type MiddlewareFn = Middleware<
  Record<string, unknown>,
  any,
  Dispatch<AnyAction>
>;

export const createStore = (middlewares: MiddlewareFn[] = []) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredPaths: ['nonTradingCommissions.commissionNames'],
        },
      }).concat([...apis.map(({ middleware }) => middleware), ...middlewares]);
    },
    devTools: process.env.NODE_ENV !== 'production',
  });
};
