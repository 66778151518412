import { get, has } from 'lodash';

import { check403Error } from '../errors';

import { apiRequest, IRequestArguments } from './apiRequest';
import { getErrorResponse } from './getErrorResponse';
import {
  getKnownErrorMessage,
  isKnownError,
} from './handleDefaultNetworkErrors';
import { isAxiosError } from './isAxiosError';
import { IOptionsArguments } from './types';

interface IBaseQueryHandlerArguments extends IRequestArguments {
  requestOptions?: IOptionsArguments;
  onError?: (error: Error) => void;
  onSuccess?: (data: unknown) => void;
  ignoreForbiddenError?: boolean;
}

export async function baseQueryHandler({
  method = 'GET',
  requestOptions,
  onError,
  onSuccess,
  ignoreForbiddenError,
  ...rest
}: IBaseQueryHandlerArguments) {
  try {
    const response = await apiRequest(
      {
        method,
        ...rest,
      },
      requestOptions,
    );

    if (onSuccess) {
      onSuccess(response.data);
    }

    return {
      data: response.data,
      meta: rest,
    };
  } catch (error) {
    if (ignoreForbiddenError || check403Error(error)) {
      return {
        data: null,
        meta: rest,
      };
    }
    if (onError && error instanceof Error) {
      onError(error);
    }

    return {
      error: handleError(error),
    };
  }
}

type ServerErrorType2 =
  | {
      status: number;
      message:
        | string
        | { message: string }
        | { message: string; description: string }
        | { message: string; description: string; error: string };
    }
  | {
      statusCode: number;
      message:
        | string
        | { message: string }
        | { message: string; description: string }
        | { message: string; description: string; error: string };
    };

const parseDescription = (k: string, v: unknown): string => {
  if (typeof v === 'string') {
    return `${k}: ${v}`;
  }
  if (typeof v === 'object' && v) {
    return `${k}: \n ${Object.entries(v)
      .map((p) => parseDescription(p[0], p[1]))
      .join('\n')}`;
  }

  return '';
};

function handleError(err: unknown): string {
  if (isAxiosError(err)) {
    if (isKnownError(err)) {
      const errMessage = getKnownErrorMessage(err);

      return errMessage;
    }
    const errorResponse = getErrorResponse(err);

    let res = '';

    if (has(errorResponse, 'status') || has(errorResponse, 'statusCode')) {
      const message = (errorResponse as ServerErrorType2).message;
      if (typeof message === 'string') {
        res += `${message}\n`;
      }
      if (typeof message === 'object' && message) {
        res += `${get(message, 'message')}\n`;
      }
      if (Array.isArray(message)) {
        res += `${message.join(', ')}`;
      }

      if (has(message, 'description')) {
        const description = get(message, 'description');
        if (typeof description === 'string') {
          res += `${description}`;
        } else if (typeof description === 'object' && description) {
          res += `${Object.entries(description)
            .map(([k, v]) => parseDescription(k, v))
            .join('\n')}`;
        }
      }
      return res;
    }
    if (has(errorResponse, 'message') && !has(errorResponse, 'description')) {
      return get(errorResponse, 'message', 'Unknown server error');
    }
    if (has(errorResponse, 'message') && has(errorResponse, 'description')) {
      res += get(errorResponse, 'message');

      if (has(errorResponse, 'description')) {
        const description = get(errorResponse, 'description');
        if (typeof description === 'string') {
          res += `. ${description}`;
        } else if (typeof description === 'object' && description !== null) {
          res += `${Object.entries(description)
            .map(([k, v]) => `${k}: ${v}`)
            .join('\n')}`;
        }
      }
      return res;
    }

    return `Unknown server error`;
  }
  return String(err);
}
