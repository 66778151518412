import { omit, cloneDeep } from 'lodash';
import { useCallback, useContext } from 'react';
import { Notification } from 'react-ui-kit-exante';

import {
  useAddUserTokenMutation,
  useChangeUserTokenMutation,
  useDeleteUserTokenMutation,
  useUpdateUserAuthFlowMutation,
} from '~/api';
import { TTokenType, IUserToken } from '~/types/users';

import { ActionTypes, PasswordManagementContext } from '../constants';
import { findDifferencesTokens, findDifferenceAuthFlow } from '../helpers';

export function useSave(userId: number) {
  const [state, dispatch] = useContext(PasswordManagementContext);
  const [updateAuthFlow] = useUpdateUserAuthFlowMutation();

  const [addUserToken] = useAddUserTokenMutation();
  const [changeUserToken] = useChangeUserTokenMutation();
  const [deleteUserToken] = useDeleteUserTokenMutation();

  let authFlowResult: { status: number } | null;
  let firstStep: number | undefined;
  const secondSteps: number[] = [];

  const saveData = useCallback(async () => {
    const { authFlow: initialAuthFlow, ...initialTokens } = cloneDeep(
      state.initial,
    );
    const { authFlow: dataAuthFlow, ...dataTokens } = cloneDeep(state.data);

    const { added, removed, changed } = findDifferencesTokens(
      initialTokens,
      dataTokens,
    );

    const authFlowWasChanged = findDifferenceAuthFlow(
      initialAuthFlow,
      dataAuthFlow,
    );

    const errors: unknown[] = [];

    if (added.length) {
      const addedResult = await Promise.all(
        added.map((addedToken) => {
          return addUserToken({
            userId,
            addedToken,
          });
        }),
      );

      addedResult.forEach((result) => {
        if ('data' in result && result.data.type) {
          const indexForUpdate = dataTokens[result.data.type].findIndex(
            (token) => token.name === result.data.name,
          );
          dataTokens[result.data.type][indexForUpdate] = result.data;
        }

        if ('error' in result) {
          errors.push(result.error);
        }
      });
    }

    if (changed.length) {
      const changedResult = await Promise.all(
        changed.map((changedToken) => {
          return changeUserToken({
            userId,
            changedTokenId: changedToken.id,
            changedToken: omit(changedToken, ['id']) as Omit<IUserToken, 'id'>,
          });
        }),
      );

      changedResult.forEach((result) => {
        if ('data' in result && result.data.type) {
          const indexForUpdate = dataTokens[result.data.type].findIndex(
            (token) => token.name === result.data.name,
          );
          dataTokens[result.data.type][indexForUpdate] = result.data;
        }

        if ('error' in result) {
          errors.push(result.error);
        }
      });
    }

    if (authFlowWasChanged) {
      const passwordName = [...dataAuthFlow.firstStep][0];
      firstStep = dataTokens.password.find(
        (token) => token.name === passwordName,
      )?.id;

      // eslint-disable-next-line no-restricted-syntax
      for (const key in dataTokens) {
        if (key === 'password') {
          // eslint-disable-next-line no-continue
          continue;
        }

        dataTokens[key as TTokenType].forEach((token) => {
          if (dataAuthFlow.secondSteps.has(token.name)) {
            secondSteps.push(token.id);
          }
        });
      }

      if (firstStep) {
        const response = await updateAuthFlow({
          userId,
          data: {
            firstStep,
            secondSteps,
          },
        });

        if ('data' in response) {
          authFlowResult = response.data;
        }

        if (authFlowResult?.status !== 201) {
          errors.push(authFlowResult);
        }
      }
    }

    if (removed.length) {
      const removedResult = await Promise.all(
        removed.map((removedToken) => {
          return deleteUserToken({
            userId,
            deletedTokenId: removedToken.id,
          });
        }),
      );

      removedResult.forEach((result) => {
        if ('error' in result) {
          errors.push(result.error);
        }
      });
    }

    if (removed.length || changed.length || added.length) {
      dispatch({
        type: ActionTypes.UPDATE_INITIAL_TOKENS,
        payload: dataTokens,
      });
    }

    if (authFlowWasChanged && authFlowResult?.status === 201 && firstStep) {
      dispatch({
        type: ActionTypes.UPDATE_INITIAL_AUTH,
        payload: {
          firstStep,
          secondSteps,
        },
      });
    }

    if (!errors.length) {
      dispatch({
        type: ActionTypes.UPDATE_FINISH,
        payload: null,
      });

      Notification.success({
        title: 'User tokens successfully saved',
      });
    }
  }, [addUserToken, changeUserToken, deleteUserToken, dispatch, state, userId]);

  return saveData;
}
