import cn from 'classnames';
import { ReactNode, HTMLProps, forwardRef } from 'react';
import { CenteredLoader } from 'react-ui-kit-exante';

import WrapperLoaderStyles from './WrapperLoader.module.css';

interface IWrapperLoader {
  children: ReactNode;
  className?: string;
  isLoading: boolean;
}

export const WrapperLoader = forwardRef<
  HTMLDivElement,
  IWrapperLoader & HTMLProps<HTMLDivElement>
>(
  (
    { children, className: externalClassName = '', isLoading, ...props },
    ref,
  ) => {
    return (
      <div
        className={cn(WrapperLoaderStyles.WrapperLoader, externalClassName)}
        ref={ref}
        {...props}
      >
        {children}

        {isLoading && (
          <div className={WrapperLoaderStyles.Background}>
            <CenteredLoader />
          </div>
        )}
      </div>
    );
  },
);

WrapperLoader.displayName = 'WrapperLoader';
