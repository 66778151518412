import { ReactNode } from 'react';

import FiltersWrapperStyles from './FiltersWrapper.module.css';

interface IWrapperProps {
  children: ReactNode;
}

export const FiltersWrapper = ({ children }: IWrapperProps) => {
  return <div className={FiltersWrapperStyles.Wrapper}>{children}</div>;
};
