import {
  check403Error,
  isAxiosError,
  sendUiKitErrorNotification,
} from '~/shared/utils';
import { UnauthorizedError } from '~/shared/utils/errors/UnauthorizedError';
import { IRequestArguments } from '~/types/api';

import { SessionRepository } from './session.repository';

const UNAUTHORIZED_STATUS_CODE = 401;
const NO_TOKEN_STATUS_CODE = 403;
const INVALID_TOKE_STATUS_CODE = 404;

const authErrorCodes = [
  UNAUTHORIZED_STATUS_CODE,
  NO_TOKEN_STATUS_CODE,
  INVALID_TOKE_STATUS_CODE,
];

export class SessionService {
  public async registerSession({ onError, onSuccess }: IRequestArguments) {
    try {
      await SessionRepository.registerSession();

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      const is403Error = check403Error(error);

      if (!is403Error) {
        sendUiKitErrorNotification(error);
      }

      if (onError) {
        const isServerError = isAxiosError(error);

        if (isServerError) {
          const status = Number(error.response?.status);
          const isAuthError = authErrorCodes.includes(status);

          if (isAuthError) {
            onError(new UnauthorizedError());
            return;
          }
        }

        onError(error as Error);
      }
    }
  }
}
