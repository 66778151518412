import { ISortBy } from 'react-ui-kit-exante';

import {
  CREATION_DATE_FIELDS,
  LAST_LOGIN_DATE_FIELDS,
  BIRTH_DATE_FIELDS,
} from '~/constants';
import { prepareSortingForApi, createEmptyObject } from '~/shared/utils';
import {
  prepareDateRangeStringForAPI,
  setStartEndForDateRangeString,
} from '~/shared/utils/dates';
import { formatDateRange } from '~/shared/utils/formatDateRange';
import { IUser } from '~/types/users';

export const expectedFields: (keyof IUser)[] = [
  'username',
  'userType',
  'residence',
  'primaryPhone',
  'preferableLanguage',
  'nameSurname',
  'middleName',
  'lastLoginTime',
  'lastLoginService',
  'id',
  'email',
  'creationDate',
  'company',
  'branding',
  'archived',
  'extraData',
  'originatorIds',
  'notifications',
  'sex',
  'preferableCurrency',
  'lei',
  'jurisdiction',
  'birthDate',
  'firstName',
  'lastName',
  'passport',
  'companyClassification',
  'brandingPermission',
];

export const USERS_PARAMS_TRANSFORMER = {
  sorting: {
    parser: (sorting: ISortBy[]) => prepareSortingForApi(sorting),
  },
  creationDate: {
    parser: (values?: [string, string]) => {
      const formattedDateRange = formatDateRange(values);

      return prepareDateRangeStringForAPI(
        CREATION_DATE_FIELDS,
        setStartEndForDateRangeString(formattedDateRange),
      );
    },
  },
  lastLoginTime: {
    parser: (values?: [string, string]) => {
      return prepareDateRangeStringForAPI(
        LAST_LOGIN_DATE_FIELDS,
        setStartEndForDateRangeString(values),
      );
    },
  },
  birthDate: {
    parser: (values?: [string, string]) => {
      return prepareDateRangeStringForAPI(
        BIRTH_DATE_FIELDS,
        setStartEndForDateRangeString(values),
      );
    },
  },
  page: {
    parser: createEmptyObject,
  },
};
