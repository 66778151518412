import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CenteredLoader, IconButton, Table } from 'react-ui-kit-exante';

import { usePrevious, useLogHandleTime } from '~/hooks';
import { LEGAL_ENTITY_UPDATE_PATH, LEGAL_ENTITY_ADD_PATH } from '~/routes';
import { getTableId, replaceRouteVariable } from '~/shared/utils';
import { ILegalEntityDetail } from '~/types/legalEntities';

import { COLUMNS } from './constants';
import { useLegalEntities } from './hooks';

export const LegalEntitiesContainer = () => {
  const navigate = useNavigate();
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(
    'legal-entities-list',
  );

  const { tableData, isLoading } = useLegalEntities(setStartHandleTime);
  const prevTableData = usePrevious(tableData);

  const goToAddLegalEntity = useCallback(() => {
    navigate(LEGAL_ENTITY_ADD_PATH, {
      state: { previousPath: window.location.href },
    });
  }, [navigate]);

  const handleRowClick = useCallback(
    ({ name }: ILegalEntityDetail) => {
      navigate(replaceRouteVariable(LEGAL_ENTITY_UPDATE_PATH, String(name)), {
        state: {
          previousPath: window.location.href,
        },
      });
    },
    [navigate],
  );

  const additionalActions = useMemo(
    () => [
      {
        component: (
          <IconButton
            iconName="AddIcon"
            iconColor="action"
            label="Add legal entity"
            iconSize={24}
            onClick={goToAddLegalEntity}
          />
        ),
      },
    ],
    [goToAddLegalEntity],
  );

  useEffect(() => {
    if (tableData && !isEqual(prevTableData, tableData)) {
      logHandleTime();
    }
  }, [tableData, prevTableData, logHandleTime]);

  if (isLoading) {
    return <CenteredLoader />;
  }

  if (!tableData?.length) {
    return null;
  }

  return (
    <Table
      additionalActions={additionalActions}
      columns={COLUMNS}
      data={tableData}
      handleRowClick={handleRowClick}
      hasFilters
      isPinnedHeader
      saveColumnOrder
      saveViewParamsAfterLeave
      showTableInfo
      tableId={getTableId('LegalEntities')}
      title="Legal Entities"
    />
  );
};
