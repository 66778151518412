import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Notification } from 'react-ui-kit-exante';

import { useLazyGetLimitsInstrumentsQuery } from '~/api';
import {
  expandedRowsSet,
  selectFiltersLayer,
  selectFiltersQueryParams,
} from '~/store/limits';
import { FilterLayers } from '~/types/limits';

import { TLimitsCellState } from '../../types';

import { closeRowAnsSubRows, getAndInsertInstrumentsToNode } from './helpers';

export function useHandleCellClick() {
  const [getLimitsInstruments, isLoading] = useLazyGetLimitsInstrumentsQuery();
  const filtersQueryParams = useSelector(selectFiltersQueryParams);
  const currentLayer = useSelector(selectFiltersLayer);
  const dispatch = useDispatch();

  const handleCellClick = useCallback(
    ({ column, row, tableState: { expanded } }: TLimitsCellState) => {
      const subRowsLength = row.subRows.length;

      if (!filtersQueryParams && currentLayer === FilterLayers.Groups) {
        Notification.warning({
          title: 'You need to choose a group',
        });

        return;
      }
      if (!filtersQueryParams && currentLayer === FilterLayers.Accounts) {
        Notification.warning({
          title: 'You need to choose an account',
        });

        return;
      }
      if (column.id === 'name' && row.original.rowType === 'node') {
        if (row.isExpanded) {
          closeRowAnsSubRows(row, dispatch);

          return;
        }

        if (subRowsLength) {
          row.toggleRowExpanded();

          dispatch(expandedRowsSet([expanded, row.id, true]));
        }

        if (!subRowsLength) {
          getAndInsertInstrumentsToNode<typeof getLimitsInstruments>({
            dispatch,
            getInstruments: getLimitsInstruments,
            path: row.original.path,
            row,
            skip: 0,
            queryParams: filtersQueryParams,
          });
        }
      }
    },
    [getLimitsInstruments, dispatch, filtersQueryParams, currentLayer],
  );

  return { handleCellClick, isLoading: isLoading.isFetching };
}
