type ApiType = 'BO' | 'BO_SWAGGER' | 'WFE' | 'AUDIT_LOG';

const isInsideRun = () => window.runUIhistoryContainer && window.WEB_BO_UI;

const DEFAULT_DOMAINS = {
  AUDIT_LOG: 'auditlog-stage.exante.eu',
  BO: 'node-back-stage.exante.eu',
  BO_SWAGGER: 'backoffice-stage.exante.eu',
  WFE: 'wfe-stage.exante.eu',
};

const getFallbackErrorMessage = (
  title: string,
  variableName: string,
  envVariableName: string,
) => {
  return `[${title} Warning] WEB-BO application was run inside the RUN-UI, but ${variableName} is empty, so the ${envVariableName} from the environment variables is used as a fallback`;
};

export const getApiDomain = (
  apiType: ApiType,
  fallback: string = DEFAULT_DOMAINS[apiType],
) => {
  if (isInsideRun()) {
    const url = window.WEB_BO_UI?.api_urls?.[apiType];

    if (url) {
      return url;
    }

    console.warn(
      getFallbackErrorMessage(
        `getBase${apiType}Url`,
        `window.WEB_BO_UI.api_urls.${apiType}`,
        `process.env.REACT_APP_API_${apiType}`,
      ),
    );
    return process.env[`REACT_APP_API_${apiType}`] || fallback;
  }

  return process.env[`REACT_APP_API_${apiType}`] || fallback;
};
