import { SerializedError } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { pick } from 'lodash';

import { check403Error, sendUiKitErrorNotification } from '~/shared/utils';
import { IUsersState } from '~/types/users';

import {
  IEditedUserPermission,
  INewUserPermission,
  TUsersPermissionResponse,
} from './types';
import { UsersPermissionRepository } from './usersPermission.repository';

export class UsersPermissionService {
  public async resolveUsersPermission(accountId: string) {
    try {
      const { data } = await UsersPermissionRepository.fetchUsersPermission(
        accountId,
      );

      return data;
    } catch (error) {
      if (error instanceof Error) {
        const is403Error = check403Error(error);

        if (!is403Error) {
          sendUiKitErrorNotification(error);
        }
      }

      return null;
    }
  }

  private async editUsers(
    editedUsersPermission: IEditedUserPermission[],
    getUsers: (params: { userName: string }) => Promise<
      | {
          data: IUsersState;
        }
      | { error?: unknown }
    >,
  ) {
    const getUserDataRequests = editedUsersPermission.map(
      async (accountUser) => {
        const response = await getUsers({ userName: accountUser.accountId });

        if ('data' in response) {
          const [userData] = response.data.users;
          return {
            ...accountUser,
            username: accountUser.userId,
            userId: userData.id,
          };
        }
        throw Error(String(response.error));
      },
    );

    const enrichedUsersData = await Promise.all(getUserDataRequests);

    const updateUserRequests = enrichedUsersData.reduce<
      Promise<AxiosResponse<TUsersPermissionResponse[], unknown>>[]
    >((acc, item) => {
      const dataForRequest = pick(item, [
        'accountId',
        'overrideAccountStatus',
        'status',
        'id',
      ]);

      if (dataForRequest.id) {
        acc.push(
          UsersPermissionRepository.updateUserPermission(dataForRequest),
        );
      }

      return acc;
    }, []);

    await Promise.all(updateUserRequests);

    return editedUsersPermission;
  }

  private async addUsers(newUsersPermission: INewUserPermission[]) {
    const preparedNewItems = newUsersPermission.map((item) =>
      pick(item, ['accountId', 'overrideAccountStatus', 'status', 'userId']),
    );

    if (preparedNewItems.length) {
      await UsersPermissionRepository.addUserPermission(preparedNewItems);
      return this.resolveUsersPermission(newUsersPermission[0].accountId);
    }

    return [];
  }

  public async updateUsersPermission({
    editedUsersPermission,
    newUsersPermission,
    getUsers,
  }: {
    editedUsersPermission: IEditedUserPermission[];
    newUsersPermission: INewUserPermission[];
    getUsers: (params: { userName: string }) => Promise<
      | {
          data: IUsersState;
        }
      | { error?: unknown }
    >;
  }) {
    try {
      const [updatedEditedItems = [], newItems = []] = await Promise.all([
        this.editUsers(editedUsersPermission, getUsers),
        this.addUsers(newUsersPermission),
      ]);
      return {
        newItems,
        editedUsersPermission: updatedEditedItems,
      };
    } catch (error) {
      if (error instanceof Error) {
        sendUiKitErrorNotification(error);
      }

      return null;
    }
  }

  public async removeUserAccount(userAccountId: string) {
    try {
      await UsersPermissionRepository.removeUserPermission(userAccountId);
      return true;
    } catch (error) {
      if (error instanceof Error) {
        sendUiKitErrorNotification(error);
      }

      return false;
    }
  }
}
