import { useCallback, useEffect } from 'react';
import { useData } from 'react-ui-kit-exante';

import { ADD_MANUAL_TRADE_EMPTY_SCHEMA } from '~/constants';
import { executionSchemesService } from '~/resources';

export function useExecutionSchemesOptions() {
  const getExecutionSchemes = useCallback(async () => {
    const result = await executionSchemesService.resolveExecutionSchemes();

    return [
      ADD_MANUAL_TRADE_EMPTY_SCHEMA,
      ...result.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    ];
  }, []);

  const {
    fetchData: fetchExecutionSchemes,
    isLoading: isExecutionSchemesLoading,
    data: executionSchemesOptions,
  } = useData({
    onFetch: getExecutionSchemes,
  });

  useEffect(() => {
    fetchExecutionSchemes();
  }, [fetchExecutionSchemes]);

  return {
    executionSchemesOptions,
    isExecutionSchemesLoading,
  };
}
