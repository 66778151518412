import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

import { useLazyGetTaskIdQuery, useGetTaskStatusQuery } from '~/api';
import {
  GET_TASK_ID_DATE_FORMAT,
  Statuses,
} from '~/api/globalSummaryTasks/constants';

import { POLLING_INTERVAL } from '../constants';

type TUseRefetchGlobalSummaryProps = {
  refetch: () => Promise<void>;
  date: number | Date;
};

export const useRefetchGlobalSummary = ({
  refetch,
  date,
}: TUseRefetchGlobalSummaryProps) => {
  const [loading, setLoading] = useState(false);
  const [fetchTaskId, { data: { taskId = '' } = {} } = {}] =
    useLazyGetTaskIdQuery();

  const formattedDate = format(date, GET_TASK_ID_DATE_FORMAT);

  const { data: { status = '', error = '' } = {} } = useGetTaskStatusQuery(
    { taskId, date: formattedDate },
    {
      pollingInterval: loading ? POLLING_INTERVAL : undefined,
      skip: !taskId,
    },
  );

  const start = () => {
    setLoading(true);
    fetchTaskId({ date: formattedDate });
  };

  const refetchGlobalSummary = useCallback(async () => {
    await refetch();
    setLoading(false);
  }, [refetch]);

  useEffect(() => {
    const successfullyCompleted = status === Statuses.Done;

    if (successfullyCompleted) {
      refetchGlobalSummary();
    } else if (error) {
      setLoading(false);
    }
  }, [error, refetchGlobalSummary, status]);

  return {
    start,
    loading,
  };
};
