/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */

import { ChangeEvent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IColumn, IRowExpand, Notification } from 'react-ui-kit-exante';

import { useLazyGetLimitsInstrumentsQuery } from '~/api';
import { EN_DASH } from '~/constants';
import { LimitMessages } from '~/pages/LimitsPage/constants';
import { getAndInsertInstrumentsToNode } from '~/pages/LimitsPage/hooks/useHandleCellClick/helpers';
import {
  selectFiltersLayer,
  selectFiltersQueryParams,
  updateInstrumentValue,
  updateNodeValue,
} from '~/store/limits';
import { RowType } from '~/types/common';
import { FilterLayers, ILimitTree } from '~/types/limits';

import { StyledInput, StyledButton } from './ValueCellStyled';

interface IValueCell {
  column: IColumn<ILimitTree>;
  value: null | string | number;
  row: IRowExpand<ILimitTree>;
}

export const ValueCell = ({ column, value, row }: IValueCell) => {
  const isNodeCell = row.original.rowType === RowType.Node;
  const isCanBeEdit = row.depth !== 0;

  const dispatch = useDispatch();
  const filtersLayer = useSelector(selectFiltersLayer);
  const queryParams = useSelector(selectFiltersQueryParams);
  const [getInstruments] = useLazyGetLimitsInstrumentsQuery();

  const [isEdit, setIsEdit] = useState(false);
  const [inputValue, setInputValue] = useState<string | number>(() => {
    if (value === null) {
      return isNodeCell ? EN_DASH : 0;
    }

    return value;
  });

  const onClickHandler = useCallback(() => {
    if (!isCanBeEdit) {
      Notification.warning({
        title: LimitMessages.ROOT_NODE,
      });

      return;
    }

    if (!row.values.override && FilterLayers.Default !== filtersLayer) {
      Notification.warning({
        title: LimitMessages.NON_OVERRIDE_LIMIT,
      });

      return;
    }

    setIsEdit(true);
  }, [filtersLayer, isCanBeEdit, row]);

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    },
    [setInputValue],
  );

  const onBlurHandler = useCallback(async () => {
    const actionForUpdateValue = isNodeCell
      ? updateNodeValue
      : updateInstrumentValue;
    const subRowsIsNode = row.subRows[0]?.original.rowType === RowType.Node;

    // TODO try to use Promise.all
    if (subRowsIsNode) {
      for (const subRow of row.subRows) {
        if (!subRow.subRows.length) {
          await getAndInsertInstrumentsToNode({
            dispatch,
            getInstruments,
            path: subRow.original.path,
            queryParams,
            row: subRow,
            skip: 0,
          });
        }

        dispatch(
          actionForUpdateValue({
            path: subRow.original.path,
            value: Number(inputValue),
            column: column.id,
          }),
        );
      }
    }

    if (isNodeCell && !row.subRows.length) {
      await getAndInsertInstrumentsToNode({
        dispatch,
        getInstruments,
        path: row.original.path,
        queryParams,
        row,
        skip: 0,
      });
    }

    dispatch(
      actionForUpdateValue({
        path: row.original.path,
        value: Number(inputValue),
        column: column.id,
      }),
    );

    setIsEdit(false);
  }, [
    column,
    dispatch,
    getInstruments,
    inputValue,
    isNodeCell,
    queryParams,
    row,
    setIsEdit,
  ]);

  if (isEdit) {
    return (
      <StyledInput
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        type="number"
        value={inputValue}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
      />
    );
  }

  return (
    <StyledButton onClick={onClickHandler}>
      {value === null || Number.isNaN(value) ? EN_DASH : String(value)}
    </StyledButton>
  );
};
