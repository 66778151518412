import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import {
  useGetNonTradingCommissionsByGroupQuery,
  useGetNonTradingCommissionGroupsByIdQuery,
  useGetNonTradingCommissionsQuery,
} from '~/api';
import { WrapperLoader } from '~/shared/components/WrapperLoader';
import { selectCommissionNames } from '~/store/nonTradingCommissions/slice';

import { Form } from './components';
import { IFormNonTradingCommission } from './types';

export const NonTradingCommissionsEntryScreenPage = () => {
  const { id } = useParams();
  const { state } = useLocation();

  const { data: [group] = [], isLoading: isGroupLoading } =
    useGetNonTradingCommissionGroupsByIdQuery(id!);
  const {
    data: commissions = [],
    isLoading: isCommissionsByGroupIdLoading,
    isFetching: isCommissionsByGroupIdFetching,
  } = useGetNonTradingCommissionsByGroupQuery(id!);
  const { isLoading: isCommissionsLoading, isFetching: isCommissionsFetching } =
    useGetNonTradingCommissionsQuery();

  const commissionNames = new Set(useSelector(selectCommissionNames));

  const formData: IFormNonTradingCommission[] = [];

  commissions.forEach((commission) => {
    formData.push({
      // we cannot use 'id' because this field name is reserved by react-hook-form
      commissionId: commission.id,
      nonFilledName: '',
      name: commission.name,
      isFree: commission.value.fixed === '0.0',
      value:
        typeof commission.value.fixed !== 'undefined' ? 'fixed' : 'percent',
      type: commission.type,
      interval: commission.interval?.type,
      commission: commission.value.percent || commission.value.fixed,
      min: commission.value.min,
      max: commission.value.max,
    });

    commissionNames.delete(commission.name);
  });

  commissionNames.forEach((name) => {
    formData.push({
      nonFilledName: name,
      name,
      isFree: false,
      value: '',
      type: '',
      commission: '',
      min: '',
      max: '',
    });
  });

  useEffect(() => {
    if (
      !isGroupLoading &&
      !isCommissionsLoading &&
      !isCommissionsByGroupIdLoading &&
      state?.commissionName
    ) {
      document
        .getElementById(state.commissionName)
        ?.scrollIntoView({ block: 'center', behavior: 'auto' });
    }
  }, [
    isGroupLoading,
    isCommissionsLoading,
    isCommissionsByGroupIdLoading,
    state?.commissionName,
  ]);

  if (isGroupLoading || isCommissionsLoading || isCommissionsByGroupIdLoading) {
    return <div>Loading...</div>;
  }

  return (
    <WrapperLoader
      isLoading={isCommissionsByGroupIdFetching || isCommissionsFetching}
    >
      <Form
        formData={formData}
        title={group.name + (group.currency ? ` (${group.currency})` : '')}
        isCommissionsByGroupIdFetching={isCommissionsByGroupIdFetching}
        isCommissionsFetching={isCommissionsFetching}
      />
    </WrapperLoader>
  );
};
