import {
  check403Error,
  sendUiKitErrorNotification,
  transformVariantsToSelectOptions,
} from '~/shared/utils';
import { IRequestArguments } from '~/types/api';
import { IOption } from '~/types/form';

import { ExanteCounterpartyRepository } from './exanteCounterparty.repository';

export class ExanteCounterpartyService {
  public async resolveExanteCounterPartyOptions(
    args?: IRequestArguments,
  ): Promise<IOption[]> {
    try {
      const { data } =
        await ExanteCounterpartyRepository.fetchExanteCounterparty();

      return transformVariantsToSelectOptions(data.values);
    } catch (error) {
      const is403Error = check403Error(error);

      if (!is403Error) {
        sendUiKitErrorNotification(error);
      }

      if (args?.onError) {
        args.onError(error as Error);
      }

      return [];
    }
  }
}
