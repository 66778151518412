import { isEqual, get } from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLazySearchLimitsInstrumentsQuery } from '~/api';
import { MAX_REQUEST_LIMIT } from '~/constants';
import { usePrevious } from '~/hooks';
import {
  resetTable,
  selectFiltersQueryParams,
  selectSearchIsActive,
} from '~/store/limits';

export function useSearch() {
  const dispatch = useDispatch();

  const [searchInstruments] = useLazySearchLimitsInstrumentsQuery();

  const filtersQueryParams = useSelector(selectFiltersQueryParams);
  const searchIsActive = useSelector(selectSearchIsActive);

  const prevFiltersQueryParams = usePrevious(filtersQueryParams);

  const getIsActiveSearch = useCallback(() => {
    if (
      get(prevFiltersQueryParams, 'layer') !== get(filtersQueryParams, 'layer')
    ) {
      return false;
    }

    return (
      searchIsActive && !isEqual(filtersQueryParams, prevFiltersQueryParams)
    );
  }, [filtersQueryParams, prevFiltersQueryParams, searchIsActive]);

  const handleResetTable = useCallback(
    () => dispatch(resetTable()),
    [dispatch],
  );

  const handleSearchSubmit = (value: string) => {
    searchInstruments({
      search: value,
      limit: MAX_REQUEST_LIMIT,
      skip: 0,
      queryParams: filtersQueryParams,
    });
  };

  return {
    getIsActiveSearch,
    onReset: handleResetTable,
    onSearch: handleSearchSubmit,
  };
}
