import { useMemo, useCallback, useState } from 'react';
import { Table, IconButton } from 'react-ui-kit-exante';

import {
  useGetNonTradingCommissionGroupsQuery,
  useGetNonTradingCommissionsQuery,
} from '~/api';
import { getTableId } from '~/shared/utils';

import { StyledTooltip } from './NonTradingCommissionsPage.styled';
import { AddGroup } from './components';
import { getColumns } from './helpers';
import { useTableData } from './hooks';

export const NonTradingCommissionsPage = () => {
  const { data: groups, isLoading: isGroupsLoading } =
    useGetNonTradingCommissionGroupsQuery();
  const { isLoading: isCommissionsLoading } =
    useGetNonTradingCommissionsQuery();

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const columns = useMemo(() => getColumns(groups), [groups]);
  const tableData = useTableData();

  const openTooltip = useCallback(() => {
    setTooltipIsOpen(true);
  }, []);

  const closeTooltip = useCallback(() => {
    setTooltipIsOpen(false);
  }, []);

  const additionalActions = useMemo(
    () => [
      {
        component: (
          <StyledTooltip
            disableFocusListener
            disableHoverListener
            disableTouchListener
            onClose={closeTooltip}
            open={tooltipIsOpen}
            title={<AddGroup closeTooltip={closeTooltip} />}
            placement="bottom-end"
          >
            <IconButton
              iconName="AddIcon"
              iconColor="action"
              label="Add Group"
              iconSize={24}
              onClick={openTooltip}
            />
          </StyledTooltip>
        ),
      },
    ],
    [tooltipIsOpen, closeTooltip, openTooltip],
  );

  return (
    <Table
      additionalActions={additionalActions}
      isLoading={isGroupsLoading || isCommissionsLoading}
      columns={columns}
      data={tableData}
      defaultSortBy={[]}
      tableId={getTableId('NonTradingCommissions')}
      title="Non-trading commissions"
    />
  );
};
