import { createApi } from '@reduxjs/toolkit/query/react';
import { isEqual, omitBy, isUndefined, get } from 'lodash';
import { MutableRefObject } from 'react';

import {
  PROXY_CORE_PATH,
  baseQueryHandler,
  createServiceResponse,
  getArrayDifference,
  paramsTransformer,
} from '~/shared/utils';
import { IDataWithPagination, IServiceResponse, TParams } from '~/types/api';
import { IOption } from '~/types/form';
import {
  ICoreFormUser,
  ICoreUser,
  ICreateUserPayload,
  IFormMasterUser,
  IFormSubUser,
  IFormUserClient,
  IGeneralPermissions,
  IMasterUser,
  ISubUser,
  IUser,
  IUserAuthflow,
  IUserClient,
  IUserGroupSettings,
  IUserToken,
  IUsersState,
} from '~/types/users';

import {
  CREATE_USER,
  FETCH_MASTER_USERS,
  FETCH_SUB_USERS,
  FETCH_USERS,
  FETCH_USER_BRANDINGS,
  FETCH_USER_CLIENTS,
  FETCH_USER_CURRENT_LEGAL_ENTITIES,
  getUserAuthflowEndpoint,
  getUserByIdAPI,
  getUserPermissionsEndpoint,
  getUserPermissionsSetsEndpoint,
  getUserTokensEndpoint,
} from './endpoints';
import { usersMapper } from './helpers';
import { USERS_PARAMS_TRANSFORMER } from './users.api.constants';
import {
  coreFormUserMapper,
  transformFormMasterUsersToOriginal,
  transformFormSubUsersToOriginal,
  transformFormUserClientsToOriginal,
  transformFormUserToCoreUser,
} from './users.api.mappers';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['UserBrandings', 'User', 'UserClients', 'SubUser', 'MasterUsers'],
  endpoints: (builder) => ({
    searchByUsers: builder.query<
      {
        total: number;
        canceled?: boolean;
        nextSkip: number | null;
        options: Array<IOption>;
      },
      {
        skip: number;
        search: string;
        limit?: number;
        archived?: boolean;
      }
    >({
      queryFn: async (
        { skip, search, limit = 100, ...restParams },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        const response = await fetchWithBaseQuery({
          url: FETCH_USERS,
          params: omitBy(
            { limit, skip, username: search, ...restParams },
            isUndefined,
          ),
          requestOptions: {},
        });

        if ('error' in response) {
          return {
            data: {
              total: 0,
              nextSkip: null,
              options: [],
              canceled: true,
            },
          };
        }

        // list of users will include only "id" and "archived" fields
        // if user don't have permission to view list of users
        if (
          response.data?.data[0] &&
          Object.keys(response.data.data[0]).length === 2 &&
          isEqual(Object.keys(response.data.data[0]), ['id', 'archived'])
        ) {
          throw new Error("You don't have permission to view list of users");
        }

        const users = usersMapper(response.data.data);
        const { pagination } = response.data;

        return {
          data: {
            total: pagination.total,
            nextSkip: skip + limit < pagination.total ? skip + limit : null,
            options: users.map(({ username }) => ({
              label: username,
              value: username,
            })),
          },
        };
      },
    }),
    addUserToken: builder.mutation<
      IUserToken,
      { userId: number; addedToken: IUserToken }
    >({
      async queryFn({ userId, addedToken }, _, __, fetchWithBaseQuery) {
        const result = await fetchWithBaseQuery({
          url: getUserTokensEndpoint(userId),
          method: 'POST',
          data: addedToken,
        });

        return result;
      },
    }),
    changeUserToken: builder.mutation<
      IUserToken,
      {
        userId: number;
        changedTokenId: number;
        changedToken: Omit<IUserToken, 'id'>;
      }
    >({
      async queryFn(
        { userId, changedTokenId, changedToken },
        _,
        __,
        fetchWithBaseQuery,
      ) {
        const result = await fetchWithBaseQuery({
          url: getUserTokensEndpoint(userId, changedTokenId),
          method: 'PUT',
          data: changedToken,
        });

        return result;
      },
    }),
    deleteUserToken: builder.mutation({
      async queryFn({ userId, deletedTokenId }, _, __, fetchWithBaseQuery) {
        const result = await fetchWithBaseQuery({
          url: getUserTokensEndpoint(userId, deletedTokenId),
          method: 'DELETE',
        });

        return result;
      },
    }),
    getUserBrandings: builder.query<string[], void>({
      query: () => ({
        url: FETCH_USER_BRANDINGS,
      }),
      providesTags: ['UserBrandings'],
    }),
    getUsers: builder.query<IUsersState, TParams>({
      query: (params) => ({
        url: FETCH_USERS,
        params: paramsTransformer({
          params,
          mapper: USERS_PARAMS_TRANSFORMER,
        }),
        ignoreForbiddenError: true,
      }),
      transformResponse: (response: IDataWithPagination<IUser[]>) => {
        return {
          users: usersMapper(response.data),
          pagination: response.pagination,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.users.map(({ id }) => ({ type: 'User' as const, id })),
              'User',
            ]
          : ['User'],
    }),
    getCoreUser: builder.query<
      ICoreFormUser,
      { id: number | string; params?: TParams }
    >({
      query: ({ id, params = {} }) => ({
        url: getUserByIdAPI(id),
        ignoreForbiddenError: true,
        params,
      }),
      transformResponse: (response: ICoreUser) => {
        return coreFormUserMapper(response);
      },
      providesTags: (response) => {
        return [{ type: 'User', id: response?.id }];
      },
    }),
    updateUser: builder.mutation<
      IServiceResponse<ICoreFormUser>,
      { id: number; data: ICoreFormUser }
    >({
      query: ({ id, data }) => ({
        url: getUserByIdAPI(id),
        method: 'PUT',
        data: transformFormUserToCoreUser(data).info,
      }),
      transformResponse: (response) => {
        return createServiceResponse({
          data: coreFormUserMapper(response),
          messages: { success: 'User is successfully updated.' },
        });
      },
      invalidatesTags: (_result, _error, args) => {
        return [{ type: 'User', id: args.id }];
      },
    }),
    updateGeneralPermissions: builder.mutation<
      IServiceResponse<IGeneralPermissions>,
      { userId: number; generalPermissions: IGeneralPermissions }
    >({
      query: ({ userId, generalPermissions }) => ({
        url: getUserByIdAPI(userId),
        method: 'PUT',
        data: generalPermissions,
      }),
      transformResponse: (response) => {
        return createServiceResponse({
          data: {
            legalEntities: response.info.legalEntities,
            accountTypes: response.info.accountTypes,
            brandingPermission: response.info.brandingPermission,
          },
          messages: { success: 'User is successfully updated.' },
        });
      },
    }),
    createUser: builder.mutation<ICoreUser, { data: ICreateUserPayload }>({
      query: ({ data }) => ({
        url: CREATE_USER,
        method: 'POST',
        data: {
          ...data,
          branding: data.branding === '' ? null : data.branding,
        },
      }),
    }),
    getCoreUserClients: builder.query<IFormUserClient[], { userName: string }>({
      query: ({ userName }) => ({
        url: FETCH_USER_CLIENTS,
        params: { userId: userName },
        ignoreForbiddenError: true,
      }),
    }),
    getUserClients: builder.query<IFormUserClient[], { userName: string }>({
      queryFn: async ({ userName }, _, __, fetchWithBaseQuery) => {
        const userClients = await fetchWithBaseQuery({
          url: FETCH_USER_CLIENTS,
          params: { userId: userName },
          ignoreForbiddenError: true,
        });

        if (userClients.error) {
          return { error: userClients.error };
        }

        const responses = await Promise.all(
          userClients.data.map(({ clientId }: IUserClient) =>
            fetchWithBaseQuery({
              url: `${PROXY_CORE_PATH}v2.0/clients/${clientId}`,
              ignoreForbiddenError: true,
            }),
          ),
        ).then((response) =>
          response.map<IFormUserClient | null>((coreClient, index) => {
            if (!coreClient) {
              return null;
            }

            const name = coreClient.data.name.trim();
            const currentUserClient: IUserClient = get(userClients.data, index);

            return {
              ...currentUserClient,
              clientId: {
                value: currentUserClient.clientId,
                label: name
                  ? `${currentUserClient.clientId} – ${name}`
                  : currentUserClient.clientId,
              },
            };
          }),
        );

        const filteredData: IFormUserClient[] = [];

        responses.forEach((t) => {
          if (t) {
            filteredData.push(t);
          }
        });

        return { data: filteredData };
      },
      providesTags: (_, __, args) => [
        { type: 'UserClients' as const, id: args.userName },
        'UserClients',
      ],
    }),

    updateUserClients: builder.mutation<
      IServiceResponse<IFormUserClient[]>,
      {
        userClients: IFormUserClient[];
        previousUserClients: MutableRefObject<IFormUserClient[]>;
      }
    >({
      queryFn: async (
        { userClients, previousUserClients },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        const messages: { success: string[]; error: string[] } = {
          success: [],
          error: [],
        };
        const extractedPreviousUserClients = transformFormUserClientsToOriginal(
          previousUserClients.current,
        );
        const extractedUserClients: IUserClient[] =
          transformFormUserClientsToOriginal(userClients);

        const { deletedElements, editedElements, createdElements } =
          getArrayDifference(
            extractedPreviousUserClients,
            extractedUserClients,
            'clientId',
          );

        const deletedPromises = Promise.allSettled(
          deletedElements.map((item) =>
            fetchWithBaseQuery({
              url: FETCH_USER_CLIENTS,
              method: 'DELETE',
              params: { userId: item.userId, clientId: item.clientId },
            }),
          ),
        ).then((responses) => {
          responses.forEach((res, index) => {
            const currentUserClient = deletedElements[index].clientId;

            if (res.status === 'fulfilled') {
              messages.success.push(
                `User client with id ${currentUserClient} successfully deleted.`,
              );
            }

            if (res.status === 'rejected') {
              messages.error.push(
                `User client with id ${currentUserClient} deleting failed ${res.reason.messages}`,
              );
            }
          });
        });

        const postUserData = editedElements.concat(createdElements);

        const updatePromises = postUserData.length
          ? fetchWithBaseQuery({
              url: FETCH_USER_CLIENTS,
              method: 'POST',
              data: postUserData,
            }).then((res) => {
              if (!('error' in res)) {
                messages.success.push('User clients are successfully updated.');
              }
            })
          : null;

        await Promise.all([deletedPromises, updatePromises]);

        // it is ok because it is react ref
        // eslint-disable-next-line no-param-reassign
        previousUserClients.current = userClients;

        return {
          data: createServiceResponse({
            data: userClients,
            messages,
          }),
        };
      },

      invalidatesTags: (_, __, args) => [
        { type: 'UserClients' as const, id: args.userClients[0].userId },
        'UserClients',
      ],
    }),
    getSubUsers: builder.query<ISubUser[], { userName: string }>({
      query: ({ userName }) => ({
        url: FETCH_SUB_USERS,
        params: { users: userName },
        ignoreForbiddenError: true,
      }),
      providesTags: (_, __, args) => [
        { type: 'SubUser' as const, id: args.userName },
        'SubUser',
      ],
    }),
    updateSubUsers: builder.mutation<
      IServiceResponse<IFormSubUser[]>,
      {
        userName: string;
        subUsers: IFormSubUser[];
        previousSubUsers: MutableRefObject<IFormSubUser[]>;
      }
    >({
      queryFn: async (
        { userName, subUsers, previousSubUsers },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        const messages: { success: string[]; error: string[] } = {
          success: [],
          error: [],
        };
        const extractedPreviousSubUsers = transformFormSubUsersToOriginal(
          previousSubUsers.current,
          userName,
        );
        const extractedSubUsers: ISubUser[] =
          transformFormSubUsersToOriginal(subUsers);

        const { deletedElements, editedElements, createdElements } =
          getArrayDifference(
            extractedPreviousSubUsers,
            extractedSubUsers,
            'subUser',
          );

        const deletedPromises = Promise.allSettled(
          deletedElements.map((item) =>
            fetchWithBaseQuery({
              url: FETCH_SUB_USERS,
              method: 'DELETE',
              params: { subUsers: item.subUser },
            }),
          ),
        ).then((responses) => {
          responses.forEach((res, index) => {
            const currentItem = deletedElements[index].subUser;

            if (res.status === 'fulfilled') {
              messages.success.push(
                `Sub user with id ${currentItem} successfully deleted.`,
              );
            }

            if (res.status === 'rejected') {
              messages.error.push(
                `Sub user with id ${currentItem} deleting failed ${res.reason.messages}`,
              );
            }
          });
        });

        const updatePromises = fetchWithBaseQuery({
          url: FETCH_SUB_USERS,
          method: 'POST',
          data: editedElements.concat(createdElements),
        }).then(() =>
          messages.success.push(`Sub users are successfully updated.`),
        );

        await Promise.all([deletedPromises, updatePromises]);

        // it is ok because it is react ref
        // eslint-disable-next-line no-param-reassign
        previousSubUsers.current = subUsers;

        return {
          data: createServiceResponse({
            data: subUsers,
            messages,
          }),
        };
      },
    }),
    getMasterUsers: builder.query<IMasterUser[], { userName: string }>({
      query: ({ userName }) => ({
        url: FETCH_MASTER_USERS,
        params: { subUsers: userName },
        ignoreForbiddenError: true,
      }),
    }),
    getUserGroupSettings: builder.query<
      IUserGroupSettings,
      { userName: string }
    >({
      query: ({ userName }) => ({
        url: getUserPermissionsSetsEndpoint(),
        ignoreForbiddenError: true,
        params: { userId: userName },
      }),
      transformResponse: (response) => {
        return {
          permissionsSetId: response[0]?.permissionsSetId || null,
        };
      },
      providesTags: (_, __, args) => [
        { type: 'MasterUsers' as const, id: args.userName },
        'MasterUsers',
      ],
    }),
    updateMasterUsers: builder.mutation<
      IServiceResponse<IFormMasterUser[]>,
      {
        userName: string;
        masterUsers: IFormMasterUser[];
        previousMasterUsers: MutableRefObject<IFormMasterUser[]>;
      }
    >({
      queryFn: async (
        { userName, masterUsers, previousMasterUsers },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        const messages: { success: string[]; error: string[] } = {
          success: [],
          error: [],
        };

        const extractedPreviousMasterUsers = transformFormMasterUsersToOriginal(
          previousMasterUsers.current,
          userName,
        );
        const extractedMasterUsers: IMasterUser[] =
          transformFormMasterUsersToOriginal(masterUsers);

        const { deletedElements, editedElements, createdElements } =
          getArrayDifference(
            extractedPreviousMasterUsers,
            extractedMasterUsers,
            'user',
          );

        const deletePromises = Promise.allSettled(
          deletedElements.map((item) =>
            fetchWithBaseQuery({
              url: FETCH_MASTER_USERS,
              method: 'DELETE',
              params: { users: item.user },
            }),
          ),
        ).then((results) =>
          results.forEach((result, index) => {
            const currentItem = deletedElements[index].user;

            if (result.status === 'fulfilled') {
              messages.success.push(
                `Master user with id ${currentItem} successfully deleted.`,
              );
            }

            if (result.status === 'rejected') {
              messages.error.push(
                `Master user with id ${currentItem} deleting failed ${result.reason.messages}`,
              );
            }
          }),
        );

        const updatePromises = fetchWithBaseQuery({
          url: FETCH_MASTER_USERS,
          method: 'POST',
          data: editedElements.concat(createdElements).map((masterUser) => ({
            subUser: masterUser.subUser,
            user: masterUser.user,
          })),
        }).then(() =>
          messages.success.push(`Master users are successfully updated.`),
        );

        await Promise.all([deletePromises, updatePromises]);

        // it is ok because it is react ref
        // eslint-disable-next-line no-param-reassign
        previousMasterUsers.current = masterUsers;

        return {
          data: createServiceResponse({
            data: masterUsers,
            messages,
          }),
        };
      },
      invalidatesTags: (_, __, args) => [
        { type: 'MasterUsers' as const, id: args.userName },
        'MasterUsers',
      ],
    }),
    updateUserGroupSettings: builder.mutation<
      IServiceResponse<{
        permissionsSetId: number;
      }>,
      {
        userName: string;
        permissionsSetId: number | null;
      }
    >({
      queryFn: async (
        { userName, permissionsSetId },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        let result = null;
        const { data: permissionsSetsResponse } = await fetchWithBaseQuery({
          params: { userId: userName },
          url: getUserPermissionsSetsEndpoint(),
        });

        if (permissionsSetsResponse.length && permissionsSetId === null) {
          await fetchWithBaseQuery({
            url: getUserPermissionsSetsEndpoint(
              get(permissionsSetsResponse, '0.id'),
            ),
            method: 'DELETE',
          });
        } else if (permissionsSetsResponse && permissionsSetId) {
          const { data: updatedPermissionsSetsResponse } =
            await fetchWithBaseQuery({
              url: getUserPermissionsSetsEndpoint(
                get(permissionsSetsResponse, '0.id'),
              ),
              method: 'POST',
              data: {
                permissionsSetId,
                userId: userName,
              },
            });

          if (updatedPermissionsSetsResponse) {
            result = updatedPermissionsSetsResponse.permissionsSetId;
          }
        }

        return {
          data: createServiceResponse({
            data: {
              permissionsSetId: result,
            },
            messages: { success: 'Group settings are successfully updated' },
          }),
        };
      },
    }),
    getUserOverrides: builder.query<
      { permissions: boolean } | null,
      { id: string }
    >({
      query: ({ id }) => ({
        url: getUserPermissionsEndpoint(id),
        ignoreForbiddenError: true,
      }),
      transformResponse: (response) => {
        return {
          permissions: Boolean(response.length),
        };
      },
    }),
    getUserLegalEntities: builder.query<unknown[], void>({
      query: () => ({
        url: FETCH_USER_CURRENT_LEGAL_ENTITIES,
      }),
    }),
    getUserAuthFlow: builder.query<IUserAuthflow, { userId: number }>({
      query: ({ userId }) => ({
        url: getUserAuthflowEndpoint(userId),
      }),
    }),
    updateUserAuthFlow: builder.mutation<
      { status: number } | null,
      { userId: number; data: IUserAuthflow }
    >({
      query: ({ userId, data }) => ({
        url: getUserAuthflowEndpoint(userId),
        method: 'POST',

        data,
      }),
    }),
  }),
});

export const {
  useAddUserTokenMutation,
  useChangeUserTokenMutation,
  useCreateUserMutation,
  useDeleteUserTokenMutation,
  useGetCoreUserQuery,
  useGetMasterUsersQuery,
  useGetSubUsersQuery,
  useGetUserAuthFlowQuery,
  useGetUserBrandingsQuery,
  useGetUserClientsQuery,
  useGetUserGroupSettingsQuery,
  useGetUserLegalEntitiesQuery,
  useGetUserOverridesQuery,
  useLazyGetCoreUserClientsQuery,
  useLazyGetUserGroupSettingsQuery,
  useLazyGetUsersQuery,
  useLazySearchByUsersQuery,
  useUpdateGeneralPermissionsMutation,
  useUpdateMasterUsersMutation,
  useUpdateSubUsersMutation,
  useUpdateUserAuthFlowMutation,
  useUpdateUserClientsMutation,
  useUpdateUserGroupSettingsMutation,
  useUpdateUserMutation,
} = usersApi;
