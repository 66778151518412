import { createApi } from '@reduxjs/toolkit/query/react';
import { isError } from 'lodash';

import { baseQueryHandler } from '~/shared/utils';

import { symbolPermissionEndpoints } from './endpoints';
import { TSymbolPermissionsGroup } from './types';

type TOnError = (err: unknown) => unknown;

export const symbolPermissionsApi = createApi({
  reducerPath: 'symbolPermissionsApi',
  baseQuery: baseQueryHandler,
  tagTypes: ['SymbolPermissionsGroups'],
  endpoints: (builder) => ({
    getSymbolPermissionsGroups: builder.query<TSymbolPermissionsGroup[], void>({
      query: () => ({
        url: symbolPermissionEndpoints.group.base,
        ignoreForbiddenError: true,
      }),
      providesTags: ['SymbolPermissionsGroups'],
    }),
    addSymbolPermissionsGroups: builder.mutation<
      TSymbolPermissionsGroup,
      { name: string; onError?: TOnError }
    >({
      query: ({ name, onError }) => ({
        url: symbolPermissionEndpoints.group.base,
        method: 'POST',
        data: { name },
        ...(onError ? { onError } : {}),
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: newGroup } = await queryFulfilled;
          dispatch(
            symbolPermissionsApi.util.updateQueryData(
              'getSymbolPermissionsGroups',
              undefined,
              (draft) => {
                draft.push(newGroup);
              },
            ),
          );
        } catch (e) {
          // todo
        }
      },
      transformErrorResponse: (e) => {
        if (isError(e)) {
          return e.message;
        }
        return Error(String(e));
      },
    }),
    deleteSymbolPermissionsGroups: builder.mutation<
      unknown,
      { id: number; onError?: TOnError }
    >({
      query: ({ id, onError }) => ({
        url: `${symbolPermissionEndpoints.group.base}/${id}`,
        method: 'DELETE',
        ...(onError ? { onError } : {}),
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          symbolPermissionsApi.util.updateQueryData(
            'getSymbolPermissionsGroups',
            undefined,
            (draft) => {
              return draft.filter((t) => t.id !== id);
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      transformErrorResponse: (e) => {
        if (isError(e)) {
          return e.message;
        }
        return Error(String(e));
      },
    }),
  }),
});

export const {
  useAddSymbolPermissionsGroupsMutation,
  useDeleteSymbolPermissionsGroupsMutation,
  useGetSymbolPermissionsGroupsQuery,
} = symbolPermissionsApi;
