import { omit } from 'lodash';
import { ReactNode, useCallback, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTableData, Table } from 'react-ui-kit-exante';

import { useLazyGetNotificationsQuery } from '~/api';
import { DEFAULT_PAGINATION_RESPONSE } from '~/constants';
import { CLIENTS_PATH } from '~/routes';
import { calculateCountOfPages, getTableId } from '~/shared/utils';
import { TParams } from '~/types/api';

import { UNNECESSARY_PARAMS_FOR_NOTIFICATION_TABLE } from './constants';
import { getColumns, channelOptions, getAdditionalFilters } from './helpers';

export const NotificationsTable = ({
  selectTable,
}: {
  selectTable: ReactNode;
}) => {
  const location = useLocation();
  const [fetchNotifications] = useLazyGetNotificationsQuery();

  const tableId = getTableId('AccountActivityNotifications');
  const { id: clientOrUserId } = useParams();

  const getNotifications = useCallback(
    async ({ params }: { params: TParams }) => {
      const clientOrUserParam = location?.pathname?.includes(CLIENTS_PATH)
        ? { clientId: clientOrUserId }
        : { userId: clientOrUserId };

      const defaultState = {
        data: [],
        pagination: DEFAULT_PAGINATION_RESPONSE,
      };

      const response = await fetchNotifications({
        ...omit(params, UNNECESSARY_PARAMS_FOR_NOTIFICATION_TABLE),
        ...clientOrUserParam,
      });

      if (!response.data) {
        return defaultState;
      }

      return response.data;
    },
    [clientOrUserId, fetchNotifications, location?.pathname],
  );

  const tableDataArgs = useMemo(
    () => ({
      tableId,
      data: { onFetch: getNotifications },
      saveViewParamsAfterLeave: true,
    }),
    [getNotifications, tableId],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    skip,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters: filtersTable,
  } = useTableData(tableDataArgs);

  const total = data?.pagination?.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [skip, limit, page, pageCount, setLimit, setPage, total],
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
      }),
    [removeFilter, setFilter],
  );

  const filteringProps = useMemo(
    () => ({
      additionalFilters,
      removeAllFilters: resetFilters,
      filters: filtersTable,
      manualFilters: true,
    }),
    [resetFilters, filtersTable],
  );

  const columns = getColumns({
    onFilter: setFilter,
    onRemove: removeFilter,
    channelOptions,
  });

  return (
    <Table
      columns={columns}
      data={data?.data || []}
      defaultSortBy={[]}
      filteringProps={filteringProps}
      filtersExpanded
      hasFilters
      hasPagination
      isFlexLayout
      isLoading={isLoading}
      pageSize={20}
      saveViewParamsAfterLeave
      serverPaginationProps={serverPaginationProps}
      showTableInfo
      tableId={tableId}
      title={selectTable}
    />
  );
};
