import { IStateLimits, FilterLayers } from '~/types/limits';

export const DEFAULT_CHANGED_LIMITS = {
  nodes: {},
  instruments: {},
};

export const INITIAL_STATE: IStateLimits = {
  changedLimits: DEFAULT_CHANGED_LIMITS,
  table: {
    defaultTree: [],
    defaultPositionByIdInTree: {},
    expandedRows: {},
    positionByIdInTree: {},
    tree: [],
  },
  search: {
    isActive: false,
    tree: [],
  },
  filters: {
    select: {
      layer: FilterLayers.Default,
      account: null,
      group: undefined,
    },
    queryParams: {
      layer: FilterLayers.Default,
    },
    relatedGroup: null,
  },
};

export const FIELDS_FOR_POST_DEFAULT_TREE = [
  'negativeLim',
  'positiveLim',
  'mode',
];
