import { omit } from 'lodash';

import { check403Error, sendUiKitErrorNotification } from '~/shared/utils';
import { IGlobalSummary, IPosition } from '~/types/accounts';

import { GlobalSummaryRepository } from './globalSummary.repository';
import {
  IHistoricalGlobalSummaryParameters,
  IHistoricalGlobalSummaryPayload,
  IGlobalSummaryParameters,
} from './types';

export class GlobalSummaryService {
  public async getGlobalSummary(
    params: IGlobalSummaryParameters,
  ): Promise<IGlobalSummary | null> {
    try {
      const { data } = await GlobalSummaryRepository.fetchGlobalSummary({
        ...params,
        filtersParams: omit(params.filtersParams, 'bookmark'),
      });

      return data;
    } catch (error) {
      const is403Error = check403Error(error);

      if (!is403Error) {
        sendUiKitErrorNotification(error);
      }

      return null;
    }
  }

  public async exportGlobalSummary(
    params: any,
  ): Promise<IPosition[] | null | undefined> {
    try {
      const { data } = await GlobalSummaryRepository.fetchGlobalSummary(params);

      return data?.data;
    } catch (error) {
      const is403Error = check403Error(error);

      if (!is403Error) {
        sendUiKitErrorNotification(error);
      }

      return null;
    }
  }

  public async getHistoricalGlobalSummary(
    params: IHistoricalGlobalSummaryParameters,
    payload: IHistoricalGlobalSummaryPayload,
  ): Promise<IGlobalSummary | null> {
    try {
      const { data } =
        await GlobalSummaryRepository.fetchHistoricalGlobalSummary(
          params,
          payload,
        );

      return data;
    } catch (error) {
      const is403Error = check403Error(error);

      if (!is403Error) {
        sendUiKitErrorNotification(error);
      }

      return null;
    }
  }
}
