import { Notification } from 'react-ui-kit-exante';

import { EMPTY_ARRAY } from '~/constants';
import {
  check403Error,
  createServiceResponse,
  sendUiKitErrorNotification,
} from '~/shared/utils';
import { IServiceResponse, TParams } from '~/types/api';
import {
  IMirroringRule,
  IMirroringRulesState,
  TMirroringRulePayload,
} from '~/types/mirroringRules';

import { mirroringRulesFilter } from './mirroringRules.filter';
import {
  excludeTagsKeys,
  mirroringRulesMappers,
  transformFormTargetToOriginal,
} from './mirroringRules.mappers';
import { MirroringRulesRepository } from './mirroringRules.repository';

export class MirroringRulesService {
  public async resolveMirroringRules(
    params: TParams,
  ): Promise<IMirroringRulesState> {
    try {
      const { data } = await MirroringRulesRepository.fetchMirroringRules(
        params,
      );
      let filteredData = [...data];

      if (params.accountTypes || params.accountPurposes) {
        filteredData = mirroringRulesFilter({
          data,
          accountTypes: params.accountTypes,
          accountPurposes: params.accountPurposes,
        });
      }

      return {
        mirroringRules: mirroringRulesMappers(filteredData),
      };
    } catch (error) {
      const is403Error = check403Error(error);

      if (!is403Error) {
        sendUiKitErrorNotification(error);
      }

      return { mirroringRules: [] };
    }
  }

  public async resolveMirroringRule(
    id: number,
    params: TParams = {},
  ): Promise<IMirroringRule | null> {
    try {
      const { data } = await MirroringRulesRepository.fetchMirroringRule(
        id,
        params,
      );

      return data;
    } catch (error) {
      const is403Error = check403Error(error);

      if (!is403Error) {
        sendUiKitErrorNotification(error);
      }

      return null;
    }
  }

  public async updateMirroringRule(
    id: number,
    mirroringRule: IMirroringRule,
  ): Promise<IServiceResponse<IMirroringRule | null>> {
    try {
      const {
        targetAccount,
        startDateTime,
        endDateTime,
        ...restMirroringRule
      } = mirroringRule;

      const { data } = await MirroringRulesRepository.updateMirroringRule(id, {
        ...restMirroringRule,
        targetAccount: transformFormTargetToOriginal(targetAccount),
        tags: excludeTagsKeys(mirroringRule),
        startDateTime: new Date(startDateTime),
        ...(endDateTime && {
          endDateTime: new Date(endDateTime),
        }),
      });

      return createServiceResponse({
        data,
        messages: { success: 'Mirroring Rule successfully updated.' },
      });
    } catch (error) {
      return createServiceResponse({
        data: null,
        messages: { error: 'Mirroring Rule updating failed.' },
        error,
      });
    }
  }

  public async createMirroringRule(mirroringRule: TMirroringRulePayload) {
    try {
      const { endDateTime, startDateTime, targetAccount } = mirroringRule;
      const payload: TMirroringRulePayload = {
        ...mirroringRule,
        targetAccount: transformFormTargetToOriginal(targetAccount),
        tags: excludeTagsKeys(mirroringRule),
        startDateTime: new Date(startDateTime),
      };

      if (endDateTime) {
        payload.endDateTime = new Date(endDateTime);
      }

      const { data } = await MirroringRulesRepository.createMirroringRule(
        payload,
      );
      const [resultData] = data;

      return createServiceResponse({
        data: resultData,
        messages: { success: 'Mirroring Rule successfully created.' },
      });
    } catch (error) {
      return createServiceResponse({
        data: null,
        messages: { error: 'Mirroring Rule creating failed.' },
        error,
      });
    }
  }

  public async removeMirroringRule(id: number) {
    try {
      await MirroringRulesRepository.deleteMirroringRule(id);

      Notification.success({
        title: 'Mirroring Rule successfully deleted.',
      });
    } catch (error) {
      Notification.error({
        title: 'Mirroring Rule deleting failed.',
      });
    }
  }

  public async resolveMirroringRuleIds(): Promise<
    Pick<IMirroringRule, 'id'>[] | []
  > {
    try {
      const { data } = await MirroringRulesRepository.fetchMirroringRules({});

      return data.map((item: IMirroringRule) => ({
        id: item.id,
      }));
    } catch (error) {
      const is403Error = check403Error(error);

      if (!is403Error) {
        sendUiKitErrorNotification(error);
      }

      return EMPTY_ARRAY;
    }
  }
}
