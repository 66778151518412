import { Middleware, isRejectedWithValue } from '@reduxjs/toolkit';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Notification } from 'react-ui-kit-exante';

import { ApplicationContainer } from '~/containers/ApplicationContainer';
import { migrate } from '~/shared/utils/migration';
import { createStore } from '~/store';
import { ComposedThemeProvider } from '~/theme/ComposedThemeProvider';

export const rtkQueryErrorHandler: Middleware = () => (next) => (action) => {
  /*
    TODO: we have a curved and disparate notification display scheme, we need to implement a normal error notification scheme that takes all the features of the project
   */
  if (action.type === 'tradesApi/executeMutation/rejected') {
    return next(action);
  }

  if (action.type === 'transactionsApi/executeMutation/rejected') {
    return next(action);
  }

  if (isRejectedWithValue(action)) {
    Notification.error(
      'Error',
      typeof action.payload === 'string'
        ? action.payload
        : action.payload.error || `Unexpected error`,
    );
  }

  return next(action);
};

export const initApp = (node?: Element) => {
  const rootNode = node || document.getElementById('root');

  if (!rootNode) {
    throw new Error('Root element was not found');
  }

  const root = createRoot(rootNode);
  const store = createStore([rtkQueryErrorHandler]);

  migrate();

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <ComposedThemeProvider>
          <ApplicationContainer />
        </ComposedThemeProvider>
      </Provider>
    </React.StrictMode>,
  );
};
