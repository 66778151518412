import { get, has } from 'lodash';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Notification } from 'react-ui-kit-exante';

import { useAddGroupLimitsMutation, useDeleteGroupLimitsMutation } from '~/api';
import {
  filtersSetGroup,
  resetTable,
  selectFiltersGroup,
} from '~/store/limits';

export function useGroupActions() {
  const dispatch = useDispatch();

  const filterGroup = useSelector(selectFiltersGroup);

  const [addGroupLimit] = useAddGroupLimitsMutation();
  const [deleteGroupLimit] = useDeleteGroupLimitsMutation();

  const handleAddGroup = useCallback(
    async (groupName: string) => {
      const response = await addGroupLimit(groupName);

      if (has(response, 'data')) {
        Notification.success({
          title: 'Group was successfully added',
        });

        const newGroupId = get(response, 'data.id');
        dispatch(
          filtersSetGroup({
            value: newGroupId,
            shouldResetDownloadedPaths: true,
          }),
        );
      }
    },
    [addGroupLimit, dispatch],
  );

  const handleDeleteGroup = useCallback(async () => {
    if (!filterGroup) {
      return;
    }

    const response = await deleteGroupLimit(filterGroup);

    if (!has(response, 'error')) {
      Notification.success({
        title: 'Group was successfully deleted',
      });
      dispatch(
        filtersSetGroup({
          value: null,
        }),
      );

      dispatch(resetTable());
    }
  }, [deleteGroupLimit, dispatch, filterGroup]);

  return { handleAddGroup, handleDeleteGroup };
}
