import { Loader, IconButton } from 'react-ui-kit-exante';

interface IDeleteButtonProps {
  loading?: boolean;
  onClick?: () => void;
  iconSize?: number;
}

export const DeleteButton = ({
  loading = false,
  onClick = () => {},
  iconSize = 24,
  ...rest
}: IDeleteButtonProps) => {
  return (
    <span {...rest}>
      {loading ? (
        <Loader size="m" />
      ) : (
        <IconButton
          iconSize={iconSize}
          iconColor="action"
          iconName="DeleteIcon"
          label=""
          onClick={onClick}
        />
      )}
    </span>
  );
};
