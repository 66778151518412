import { IColumn } from 'react-ui-kit-exante';

import { NameCell } from '~/shared/components';

import { OverrideCell } from './components/OverrideCell';
import { UpperCupCell } from './components/UpperCupCell';
import { ValueCell } from './components/ValueCell';

export const useColumns = (): IColumn<Record<string, unknown>>[] => {
  const columns = [
    {
      Cell: NameCell,
      Header: 'Name',
      accessor: 'displayName',
      disableSortBy: true,
      id: 'name',
      width: 1000,
    },
    {
      id: 'override',
      Cell: OverrideCell,
      Header: 'Override',
      accessor: 'override',
      disableSortBy: true,
    },
    {
      id: 'negativeLim',
      Cell: ValueCell,
      Header: 'Negative',
      accessor: 'negativeLim',
      disableSortBy: true,
      maxWidth: 100,
    },
    {
      id: 'positiveLim',
      Cell: ValueCell,
      Header: 'Positive',
      accessor: 'positiveLim',
      disableSortBy: true,
      maxWidth: 100,
    },
    {
      id: 'mode',
      Cell: UpperCupCell,
      Header: 'Upper cup',
      accessor: 'mode',
      disableSortBy: true,
      maxWidth: 100,
    },
  ];

  return columns;
};
