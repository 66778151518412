import { isEqual } from 'lodash';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Bookmarks,
  IOnFetchArguments,
  IconButton,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { useLazyGetUsersQuery } from '~/api';
import { DEFAULT_PAGINATION_RESPONSE, NO_DATA_HEIGHT } from '~/constants';
import { EXCLUDED_PARAMS } from '~/containers/UsersContainer/params';
import {
  useSyncTableViewParams,
  useLogHandleTime,
  usePrevious,
  useBrandingList,
} from '~/hooks';
import { IBookmarkResponseProps } from '~/hooks/useBookmark/types';
import { getUserPageRoute, USER_ADD_PATH } from '~/routes';
import { WithBookmarks } from '~/shared/components/WithBookmarks';
import { calculateCountOfPages, getTableId } from '~/shared/utils';
import { IUser, IUsersState } from '~/types/users';

import {
  DISPLAYED_COLUMN_KEYS,
  getColumns,
  getDefaultFilters,
} from './filters';
import { DEFAULT_SORTING, getDefaultSorting } from './sorting';

export const Users: FC<IBookmarkResponseProps> = ({
  selectedBookmark,
  handleSaveBookmark,
  handleSaveAsNewBookmark,
  handleShareBookmark,
  handleDeleteBookmark,
}) => {
  const navigate = useNavigate();
  const tableId = getTableId('Users');
  const { setStartHandleTime, logHandleTime } = useLogHandleTime('users-list');
  const { brandingList, brandingListWithoutAll } = useBrandingList();
  const [getUsers] = useLazyGetUsersQuery();

  const tableArgs = useMemo(
    () => ({
      data: {
        onFetch: async ({ params }: IOnFetchArguments) => {
          setStartHandleTime();
          const response = await getUsers(params);

          if (response.error || !response.data) {
            return { users: [], pagination: DEFAULT_PAGINATION_RESPONSE };
          }
          return response.data;
        },
      },
      sorting: { getDefaultSorting },
      filters: { getDefaultFilters },
      saveViewParamsAfterLeave: true,
      excludedParams: EXCLUDED_PARAMS,
      tableId,
    }),
    [getUsers, setStartHandleTime, tableId],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    setSorting,
    filters,
    params,
  } = useTableData<IUsersState>(tableArgs);

  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );
  const previousDataUsers = usePrevious(data?.users);

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        brandingList,
        brandingListWithoutAll,
      }),
    [brandingListWithoutAll, setFilter, removeFilter, brandingList],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
      manualFilters: true,
    }),
    [filters, resetFilters],
  );
  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  useSyncTableViewParams({ pageCount, setPage, tableId });

  const handleRowClick = useCallback(
    ({ id }: IUser, index: number) =>
      navigate(getUserPageRoute(id), {
        state: {
          previousPath: window.location.href,
          requestParams: params,
          cursor: index,
        },
      }),
    [navigate, params],
  );

  const handleClickNewUser = useCallback(() => {
    navigate(USER_ADD_PATH);
  }, [navigate]);

  const additionalActions = useMemo(
    () => [
      {
        component: (
          <IconButton
            iconName="AddIcon"
            iconColor="action"
            label="Add user"
            iconSize={24}
            onClick={handleClickNewUser}
          />
        ),
      },
    ],
    [handleClickNewUser],
  );

  useEffect(() => {
    if (data?.users && !isEqual(previousDataUsers, data?.users)) {
      logHandleTime();
    }
  }, [logHandleTime, data, previousDataUsers]);

  const bookmarkComponent = useMemo(
    () => (
      <Bookmarks
        initialValues={selectedBookmark}
        onSave={(name) => handleSaveBookmark(name, filters)}
        onSaveAsNew={(name) => handleSaveAsNewBookmark(name, filters)}
        onShare={handleShareBookmark}
        onDelete={handleDeleteBookmark}
      />
    ),
    [
      filters,
      handleSaveBookmark,
      handleSaveAsNewBookmark,
      handleShareBookmark,
      handleDeleteBookmark,
      selectedBookmark,
    ],
  );

  const displayedColumnKeys = useMemo(
    () =>
      selectedBookmark.columns.length
        ? selectedBookmark.columns
        : DISPLAYED_COLUMN_KEYS,
    [selectedBookmark.columns],
  );

  return (
    <Table
      additionalActions={additionalActions}
      columns={columns}
      data={data?.users || []}
      defaultSortBy={DEFAULT_SORTING}
      displayedColumnKeys={displayedColumnKeys}
      filteringProps={filteringProps}
      filtersExpanded
      filtersRightPanelComponent={bookmarkComponent}
      handleRowClick={handleRowClick}
      hasFilters
      hasPagination
      isFlexLayout
      isLoading={isLoading}
      isPinnedHeader
      manualSortBy
      noDataHeight={NO_DATA_HEIGHT}
      onSort={setSorting}
      saveColumnOrder
      saveViewParamsAfterLeave
      serverPaginationProps={serverPaginationProps}
      showScrollbar
      showTableInfo
      tableId={tableId}
      title="Users"
    />
  );
};

export const UsersContainer = () => {
  const tableId = getTableId('Users');

  return <WithBookmarks component={Users} pageName="Users" tableId={tableId} />;
};
