import { apiRequest } from '~/shared/utils';
import { ILegalEntityFormData } from '~/types/legalEntities';

import {
  FETCH_LEGAL_ENTITY_DETAILS_ENDPOINT,
  getLegalEntityEndpoint,
  POST_LEGAL_ENTITY_ENDPOINT,
} from './endpoints';

export class LegalEntitiesRepository {
  public static fetchLegalEntityDetail(legalEntity: string) {
    return apiRequest({
      url: getLegalEntityEndpoint(legalEntity),
    });
  }

  public static fetchLegalEntityDetails() {
    return apiRequest({
      url: FETCH_LEGAL_ENTITY_DETAILS_ENDPOINT,
    });
  }

  public static postLegalEntity(formData: ILegalEntityFormData) {
    return apiRequest({
      method: 'POST',
      url: POST_LEGAL_ENTITY_ENDPOINT,
      data: formData,
    });
  }

  public static updateLegalEntity(
    legalEntity: string,
    formData: ILegalEntityFormData,
  ) {
    return apiRequest({
      method: 'POST',
      url: `${POST_LEGAL_ENTITY_ENDPOINT}/${legalEntity}`,
      data: formData,
    });
  }

  public static deleteLegalEntity(legalEntity: string) {
    return apiRequest({
      method: 'DELETE',
      url: `${POST_LEGAL_ENTITY_ENDPOINT}/${legalEntity}`,
    });
  }
}
